const Target = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="166" height="148" viewBox="0 0 166 148">
            <g fill="none" fill-rule="evenodd">
                <g stroke-width="5">
                    <g>
                        <g>
                            <g transform="translate(-278 -2934) translate(138 2027) translate(72 817) translate(70.5 93)">
                                <path id="aimP1" stroke="#90A2AB" stroke-linecap="square"
                                      d="M9 142.5L152 142.5M0 142.5L1 142.5M159.5 142.5L160.5 142.5M65.5 127L95.5 127M43.39 140.972L55.409 93.82"/>
                                <path id="aimP2" stroke="#90A2AB" stroke-linecap="square" d="M90.63 141.406L101.259 100.5"
                                      transform="matrix(-1 0 0 1 192.26 0)"/>
                                <path id="aimP3" stroke="#90A2AB" stroke-linecap="square" d="M58.935 140.795L69.613 98.901"/>
                                <path id="aimP4" stroke="#90A2AB" stroke-linecap="square" d="M104.5 141.151L115.77 96"
                                      transform="matrix(-1 0 0 1 220.27 0)"/>
                                <circle id="aimC1" cx="80.5" cy="50" r="30" stroke="#90A2AB"/>
                                <g stroke="#F44174">
                                    <path
                                        id="aimP5"
                                        d="M20.04 6H5.623C2.529 6 .02 8.51.02 11.604c0 3.095 2.509 5.605 5.604 5.605h8.5c3.256 0 5.896 2.64 5.896 5.895 0 3.256-2.64 5.896-5.896 5.896H0h0"
                                        transform="translate(70.5 32)"/>
                                    <path
                                        id="aimP6"
                                        stroke-linecap="square" d="M9.52 5.5L9.52.5M9.52 34.5L9.52 29.5"
                                          transform="translate(70.5 32)"/>
                                </g>
                                <circle id="aimC2" cx="80.5" cy="50" r="50" stroke="#90A2AB"/>
                                <path
                                    id="aimP7"
                                    stroke="#90A2AB"
                                      d="M72.87 89.273c1.758.34 3.556.564 5.387.665m4.486 0c1.813-.1 3.594-.321 5.335-.655m24.604-63.043c-1.469-1.986-3.117-3.831-4.92-5.512m-3.786-3.118C97.383 12.823 89.271 10 80.5 10c-2.881 0-5.691.305-8.4.883m-9.567 74.864c1.6.807 3.263 1.508 4.978 2.097"/>
                                <path id="aimP8" stroke="#90A2AB" stroke-linecap="square" d="M68.5 116L92.5 116"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Target;