import React, {Component} from "react";
import { NavLink, Link } from 'react-router-dom';
import clsx from "clsx";
import {gsap, Power2} from "gsap";

//Images
import user from "../images/icons/user.svg";

export class Pravi extends Component{

	constructor(props) {

		super(props);

		this.state = {
			burgerActive: false,
			menuActive: false,
		}

		this.handleMenu = this.handleMenu.bind(this);

	}

	handleMenu() {

		const myThis = this;

		myThis.setState({burgerActive: !myThis.state.burgerActive});

		if(!this.state.burgerActive){

			this.openMenu();

		} else {

			this.closeMenu();

		}

	}

	openMenu() {

		const myThis = this;
		const tl = gsap.timeline();
		const menuItems = document.querySelectorAll('#menuNav li');

		myThis.setState({menuActive: true});

		tl.fromTo('#logoBlock', {y: 0}, {y: -200, duration: .5, ease: Power2.easeInOut}, 0);
		tl.fromTo('#menuBg', {xPercent: -100}, {xPercent: 0, duration: 1, ease: Power2.easeOut}, 0);
		menuItems.forEach(function (item, index){
			tl.fromTo(item, {xPercent: -150}, {xPercent: 0, duration: .5}, index/10);
		});
		tl.fromTo('#menuAuth li:first-child', {y: 100}, {y: 0, duration: .25}, .15)
		tl.fromTo('#menuAuth .divider', {y: 100}, {y: 0, duration: .25}, .2)
		tl.fromTo('#menuAuth li:last-child', {y: 100}, {y: 0, duration: .25}, .25)

	}

	closeMenu() {

		const myThis = this;
		const tl = gsap.timeline();
		const menuItems = document.querySelectorAll('#menuNav li');

		let reversedMenu = [].slice.call(menuItems, 0).reverse();

		reversedMenu.forEach(function (item, index){
			tl.fromTo(item, {xPercent: 0}, {xPercent: -150, duration: .75}, index/10);
		});
		tl.fromTo('#menuAuth li:first-child', {y: 0}, {y: 100, duration: .6}, .4)
		tl.fromTo('#menuAuth .divider', {y: 0}, {y: 100, duration: .6}, .3)
		tl.fromTo('#menuAuth li:last-child', {y: 0}, {y: 100, duration: .6}, .2)
		tl.fromTo('#menuBg', {xPercent: 0}, {xPercent: -100, duration: 1, ease: Power2.easeInOut, onComplete: function (){
				myThis.setState({menuActive: false});
			}}, 0);
		tl.fromTo('#logoBlock', {y: -200}, {y: 0, duration: .25, ease: Power2.easeInOut}, .5);

	}

	componentDidMount() {

		const myThis = this;

		document.querySelectorAll('#menuWrapper a').forEach(function (link){

			link.addEventListener('click',function (e) {

				e.preventDefault();

				gsap.fromTo('#logoBlock', {y: -200}, {y: 0, duration: .25, ease: Power2.easeInOut}, .5);

				myThis.setState({burgerActive: false});

				myThis.closeMenu();

				const goTo = e.target.parentElement.getAttribute('href', 2);

				myThis.props.history.push(goTo);

			});

		});

		if(this.props.isMobile){

			document.querySelectorAll('#authIcon a').forEach(function (link){

				link.addEventListener('click',function (e) {

					e.preventDefault();

					gsap.fromTo('#logoBlock', {y: -200}, {y: 0, duration: .25, ease: Power2.easeInOut}, .5);

					myThis.setState({burgerActive: false});

					myThis.closeMenu();

					const goTo = e.target.parentElement.getAttribute('href', 2);

					myThis.props.history.push(goTo);

				});

			});

		}

	}

	render() {

		const burgerStatus = this.state.burgerActive ? 'active' : '';
		const menuStatus = this.state.menuActive ? 'active' : '';

		return(
			<>
				<header>
					<div className={"container"}>
						<div id={"logoBlock"} className="hidden-xs">
							<div id={"logoContainer"}>
								<NavLink to="/">
									<svg className="logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="225" height="74" viewBox="0 0 225 74">
										<defs>
											<filter id="m59yrx2kdb" width="205.4%" height="205.4%" x="-52.7%" y="-52.7%" filterUnits="objectBoundingBox">
												<feGaussianBlur in="SourceGraphic" stdDeviation="13"/>
											</filter>
											<filter id="6ijbdmluxd" width="205.4%" height="205.4%" x="-52.7%" y="-52.7%" filterUnits="objectBoundingBox">
												<feGaussianBlur in="SourceGraphic" stdDeviation="13"/>
											</filter>
											<path id="l34e269mma" d="M45.921 57.949l4.327.897 4.66 11.366c-2.142 1.142-4.412 2.08-6.779 2.788l-4.66-11.374 2.452-3.677zm-16.881-.01l2.426 3.694-4.742 11.328c-2.364-.717-4.63-1.662-6.767-2.813l4.749-11.345 4.334-.864zm32.557-14.353l11.361 4.706c-.719 2.363-1.667 4.626-2.82 6.764l-11.346-4.7-.88-4.33 3.685-2.44zm-48.206-.028l3.677 2.452-.897 4.327-11.357 4.655c-1.15-2.138-2.093-4.404-2.807-6.769l11.384-4.665zM70.206 20.04c1.144 2.14 2.083 4.41 2.794 6.775l-11.348 4.7-3.685-2.439.88-4.33zm-65.404-.044l11.35 4.75.865 4.335-3.695 2.426L2 26.767c.713-2.365 1.655-4.632 2.802-6.77zm21.92-17.989l4.704 11.359-2.44 3.685-4.33-.88L19.954 4.82c2.138-1.15 4.403-2.095 6.768-2.813zM48.24 2c2.364.715 4.63 1.66 6.768 2.808L50.305 16.16l-4.33.882-2.44-3.686z"/>
										</defs>
										<g fill="none" fillRule="evenodd">
											<g>
												<g>
													<g id="logoCircle">
														<mask id="1tdbygf4zc" fill="#fff">
															<use xlinkHref="#l34e269mma"/>
														</mask>
														<use fill="#A9F300" xlinkHref="#l34e269mma"/>
														<path fill="#000" d="M36.988 52.359l3.657 2.467V73.82c-1.2.117-2.414.179-3.645.179-1.238 0-2.462-.062-3.67-.181V54.826l3.658-2.467zm15.156-5.295L65.581 60.5c-1.554 1.886-3.29 3.616-5.182 5.162L46.973 52.236l.842-4.33 4.33-.842zm-30.302-.014l4.33.842.842 4.33L13.587 65.65c-1.892-1.546-3.627-3.277-5.18-5.164L21.842 47.05zm51.986-13.63c.114 1.178.172 2.372.172 3.58 0 1.261-.063 2.507-.186 3.735H54.825l-2.466-3.658 2.466-3.657zm-54.653-.004l2.466 3.657-2.466 3.659H.185C.064 39.505 0 38.26 0 37c0-1.21.06-2.404.173-3.584h19.002zM13.503 8.418l13.435 13.435-.842 4.33-4.33.843L8.342 13.6c1.545-1.893 3.275-3.629 5.162-5.182zm46.94-.044c1.889 1.55 3.623 3.282 5.174 5.171L52.178 26.983l-4.33-.842-.842-4.33zM37 0c1.23 0 2.444.06 3.642.179v18.995l-3.657 2.467-3.658-2.467V.181C34.536.061 35.76 0 37 0z" filter="url(#m59yrx2kdb)" mask="url(#1tdbygf4zc)"/>
														<path fill="#000" d="M36.988 52.359l3.657 2.467V73.82c-1.2.117-2.414.179-3.645.179-1.238 0-2.462-.062-3.67-.181V54.826l3.658-2.467zm15.156-5.295L65.581 60.5c-1.554 1.886-3.29 3.616-5.182 5.162L46.973 52.236l.842-4.33 4.33-.842zm-30.302-.014l4.33.842.842 4.33L13.587 65.65c-1.892-1.546-3.627-3.277-5.18-5.164L21.842 47.05zm51.986-13.63c.114 1.178.172 2.372.172 3.58 0 1.261-.063 2.507-.186 3.735H54.825l-2.466-3.658 2.466-3.657zm-54.653-.004l2.466 3.657-2.466 3.659H.185C.064 39.505 0 38.26 0 37c0-1.21.06-2.404.173-3.584h19.002zM13.503 8.418l13.435 13.435-.842 4.33-4.33.843L8.342 13.6c1.545-1.893 3.275-3.629 5.162-5.182zm46.94-.044c1.889 1.55 3.623 3.282 5.174 5.171L52.178 26.983l-4.33-.842-.842-4.33zM37 0c1.23 0 2.444.06 3.642.179v18.995l-3.657 2.467-3.658-2.467V.181C34.536.061 35.76 0 37 0z" filter="url(#6ijbdmluxd)" mask="url(#1tdbygf4zc)"/>
														<path fill="#A9F300" d="M36.988 52.359l3.657 2.467V73.82c-1.2.117-2.414.179-3.645.179-1.238 0-2.462-.062-3.67-.181V54.826l3.658-2.467zm15.156-5.295L65.581 60.5c-1.554 1.886-3.29 3.616-5.182 5.162L46.973 52.236l.842-4.33 4.33-.842zm-30.302-.014l4.33.842.842 4.33L13.587 65.65c-1.892-1.546-3.627-3.277-5.18-5.164L21.842 47.05zm51.986-13.63c.114 1.178.172 2.372.172 3.58 0 1.261-.063 2.507-.186 3.735H54.825l-2.466-3.658 2.466-3.657zm-54.653-.004l2.466 3.657-2.466 3.659H.185C.064 39.505 0 38.26 0 37c0-1.21.06-2.404.173-3.584h19.002zM13.503 8.418l13.435 13.435-.842 4.33-4.33.843L8.342 13.6c1.545-1.893 3.275-3.629 5.162-5.182zm46.94-.044c1.889 1.55 3.623 3.282 5.174 5.171L52.178 26.983l-4.33-.842-.842-4.33zM37 0c1.23 0 2.444.06 3.642.179v18.995l-3.657 2.467-3.658-2.467V.181C34.536.061 35.76 0 37 0z"/>
													</g>
													<g id="logoText">
														<path fill="#FFF" d="M154.937 26.466c5.81 0 8.713 4.856 8.713 10.645 0 5.786-2.903 10.603-8.713 10.603-4.974 0-6.884-2.59-7.919-5.303h-1.59l1.191 3.644v6.92L140.254 55V26.869h6.365v1.256l-1.192 3.642h1.591c1.035-2.712 2.945-5.301 7.919-5.301zM107.14 21v5.87h6.605v5.666h-6.605v9.513l7.402-.406v5.667l-10.385.404c-2.19.08-3.383-1.213-3.383-3.44V32.536H96v-5.667h4.775v-3.845L107.141 21zm67.249 5.466c6.803 0 9.589 3.44 9.589 7.406h-6.366c0-1.458-1.075-2.551-3.262-2.551-1.514 0-2.35.366-2.35 1.175 0 .972 1.99 1.578 5.174 2.63 3.9 1.296 7.48 2.308 7.48 6.558 0 3.439-2.427 6.03-9.35 6.03-7.401 0-10.584-3.035-10.584-7.812h6.366c0 1.58 1.074 2.956 4.417 2.956 1.71 0 2.786-.406 2.786-1.376 0-1.053-1.115-1.336-4.338-2.429-.22-.074-.443-.147-.668-.22l-.451-.145-.682-.222-.455-.152c-3.103-1.05-6.06-2.425-6.06-6.102 0-3.642 3.223-5.746 8.754-5.746zm40.119 0c4.847 0 9.316 2.369 10.491 6.932l-6.075 1.932c-.238-1.822-1.87-3.198-4.416-3.198-3.024 0-4.616 1.82-4.616 4.938 0 3.157 1.592 4.978 4.616 4.978 2.545 0 4.178-1.376 4.416-3.197L225 40.783c-1.175 4.563-5.645 6.932-10.491 6.932-6.407 0-10.982-3.805-10.982-10.645 0-6.801 4.575-10.605 10.982-10.605zm-87.791 0c6.287 0 11.3 3.48 11.3 10.603 0 7.165-5.013 10.645-11.3 10.645-6.328 0-11.341-3.48-11.341-10.645 0-7.123 5.013-10.603 11.34-10.603zm63.261.08l13.17.323v5.668l-10.226-.244v15.018h-6.366V29.946c0-2.226 1.233-3.44 3.422-3.4zm-63.261 5.586c-3.024 0-4.974 1.539-4.974 4.937 0 3.441 1.95 4.98 4.974 4.98 2.984 0 4.933-1.539 4.933-4.98 0-3.398-1.949-4.937-4.933-4.937zm25.234 0c-3.423 0-5.333 1.497-5.333 4.98 0 3.48 1.91 4.937 5.333 4.937 3.422 0 5.333-1.458 5.333-4.938 0-3.482-1.91-4.979-5.333-4.979z" />
													</g>
												</g>
											</g>
										</g>
									</svg>
								</NavLink>
							</div>
						</div>
						<div id={"authBlock"} className="hidden-sm hidden-md hidden-lg">
							<div id="authIcon" className="hidden visible-xs">
								<a href="https://dsp.topsrc.com/login">
									<img src={user} alt={'Login'} />
								</a>
								{/*<NavLink to="/login">
									<img src={user} alt={'Login'} />
								</NavLink>*/}
							</div>
							<div className="menu uppercase">
								<ul>
									{/*<li><NavLink to="/login">Login</NavLink></li>*/}
									<li><a href="https://dsp.topsrc.com/login">Login</a></li>
									<span>/</span>
									{/*<li><Link to="/registration">Register</Link></li>*/}
									<li><a href="https://dsp.topsrc.com/registration">Register</a></li>
								</ul>
							</div>
						</div>
						<div id={"menuBlock"}>
							<button id='burger' className={clsx(burgerStatus, 'hoverable')} onClick={this.handleMenu}>
								<span className="burger-line"></span>
								<span className="burger-line"></span>
								<span className="burger-line"></span>
							</button>
							<nav className="hidden-xs hidden-sm hidden-md hidden-lg">
								<li><NavLink to="/dsp">DSP</NavLink></li>
								<li><NavLink to="/ssp">SSP</NavLink></li>
								<li><NavLink to="/tech">Tech</NavLink></li>
								<li><NavLink to="/meet_us">Meet Us</NavLink></li>
							</nav>
						</div>
					</div>
				</header>
				<div id='menuWrapper' className={menuStatus}>
					<div id='menuBg'></div>
					<div id='menuContainer'>
						<div id={"menu"}>
							<nav id="menuAuth">
								<li><a href="https://dsp.topsrc.com/login"><span>Login</span></a></li>
								{/*<li><NavLink to="/login"><span>Login</span></NavLink></li>*/}
								<span className="divider">/</span>
								{/*<li><NavLink to="/registration"><span>Register</span></NavLink></li>*/}
								<li><a href="https://dsp.topsrc.com/registration"><span>Register</span></a></li>
							</nav>
							<nav id="menuNav">
								<li><NavLink to="/dsp"><span className="text">DSP</span> <span className="arrow"></span></NavLink></li>
								<li><NavLink to="/ssp"><span className="text">SSP</span> <span className="arrow"></span></NavLink></li>
								<li><NavLink to="/tech"><span className="text">Tech</span> <span className="arrow"></span></NavLink></li>
								<li><NavLink to="/meet_us"><span className="text">Meet Us</span> <span className="arrow"></span></NavLink></li>
							</nav>
						</div>
					</div>
				</div>
				<div id='vLogo' className='hidden visible-xs'>
					<NavLink to="/">
						<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="135" height="137" viewBox="0 0 135 137">
							<defs>
								<filter id="snenusfs8b" width="192.5%" height="192.5%" x="-46.3%" y="-46.3%" filterUnits="objectBoundingBox">
									<feGaussianBlur in="SourceGraphic" stdDeviation="13"/>
								</filter>
								<filter id="ukrg9b5cfd" width="192.5%" height="192.5%" x="-46.3%" y="-46.3%" filterUnits="objectBoundingBox">
									<feGaussianBlur in="SourceGraphic" stdDeviation="13"/>
								</filter>
								<path id="cuzwqzka9a" d="M51.738 65.427l4.921 1.02 5.298 12.925c-2.435 1.3-5.016 2.365-7.708 3.17l-5.3-12.934 2.79-4.181zm-19.197-.012l2.76 4.201L29.907 82.5c-2.69-.815-5.265-1.89-7.696-3.2l5.4-12.9 4.93-.984zm37.023-16.322l12.92 5.352c-.818 2.688-1.896 5.26-3.207 7.692l-12.903-5.344-1-4.925 4.19-2.775zm-54.819-.032l4.182 2.79-1.02 4.92-12.915 5.293c-1.307-2.432-2.38-5.008-3.192-7.697l12.945-5.306zm64.61-26.744c1.3 2.434 2.369 5.014 3.177 7.704l-12.905 5.345-4.191-2.773 1.001-4.925zm-74.376-.05l12.906 5.402.984 4.929-4.202 2.759-12.875-5.39c.81-2.69 1.882-5.267 3.187-7.7zM29.905 1.809l5.35 12.918-2.774 4.19-4.925-1L22.21 5.007c2.431-1.307 5.007-2.382 7.696-3.199zm24.47-.008c2.689.814 5.264 1.888 7.697 3.194l-5.348 12.91-4.925 1.002-2.774-4.19z"/>
							</defs>
							<g fill="none" fillRule="evenodd">
								<g>
									<g>
										<g transform="translate(-120 -90) translate(120 90) translate(25.328)">
											<mask id="546nyww1pc" fill="#fff">
												<use xlinkHref="#cuzwqzka9a"/>
											</mask>
											<use fill="#A9F300" xlinkHref="#cuzwqzka9a"/>
											<path fill="#000" d="M42.14 59.652l4.166 2.81v21.641c-1.367.134-2.75.204-4.153.204-1.411 0-2.805-.07-4.18-.206V62.462l4.167-2.81zm17.266-6.032l15.308 15.307c-1.77 2.149-3.748 4.12-5.904 5.88L53.514 59.513l.96-4.933 4.932-.96zm-34.522-.016l4.934.96.958 4.932L15.48 74.793c-2.156-1.762-4.133-3.733-5.902-5.884l15.306-15.305zm59.226-15.53c.13 1.343.196 2.703.196 4.08 0 1.436-.072 2.856-.212 4.255H62.461l-2.81-4.168 2.81-4.166zm-62.265-.003l2.81 4.166-2.81 4.168H.212C.072 45.007 0 43.589 0 42.154c0-1.378.067-2.74.197-4.083h21.648zM15.383 9.59L30.69 24.897l-.96 4.934-4.932.96L9.502 15.493c1.76-2.156 3.732-4.134 5.881-5.904zm53.478-.05c2.152 1.766 4.127 3.74 5.894 5.892l-15.31 15.31-4.933-.96-.96-4.933zM42.153 0c1.4 0 2.784.069 4.15.204v21.641l-4.167 2.81-4.168-2.81V.206C39.346.07 40.741 0 42.153 0z" filter="url(#snenusfs8b)" mask="url(#546nyww1pc)"/>
											<path fill="#000" d="M42.14 59.652l4.166 2.81v21.641c-1.367.134-2.75.204-4.153.204-1.411 0-2.805-.07-4.18-.206V62.462l4.167-2.81zm17.266-6.032l15.308 15.307c-1.77 2.149-3.748 4.12-5.904 5.88L53.514 59.513l.96-4.933 4.932-.96zm-34.522-.016l4.934.96.958 4.932L15.48 74.793c-2.156-1.762-4.133-3.733-5.902-5.884l15.306-15.305zm59.226-15.53c.13 1.343.196 2.703.196 4.08 0 1.436-.072 2.856-.212 4.255H62.461l-2.81-4.168 2.81-4.166zm-62.265-.003l2.81 4.166-2.81 4.168H.212C.072 45.007 0 43.589 0 42.154c0-1.378.067-2.74.197-4.083h21.648zM15.383 9.59L30.69 24.897l-.96 4.934-4.932.96L9.502 15.493c1.76-2.156 3.732-4.134 5.881-5.904zm53.478-.05c2.152 1.766 4.127 3.74 5.894 5.892l-15.31 15.31-4.933-.96-.96-4.933zM42.153 0c1.4 0 2.784.069 4.15.204v21.641l-4.167 2.81-4.168-2.81V.206C39.346.07 40.741 0 42.153 0z" filter="url(#ukrg9b5cfd)" mask="url(#546nyww1pc)"/>
											<path fill="#A9F300" d="M42.14 59.652l4.166 2.81v21.641c-1.367.134-2.75.204-4.153.204-1.411 0-2.805-.07-4.18-.206V62.462l4.167-2.81zm17.266-6.032l15.308 15.307c-1.77 2.149-3.748 4.12-5.904 5.88L53.514 59.513l.96-4.933 4.932-.96zm-34.522-.016l4.934.96.958 4.932L15.48 74.793c-2.156-1.762-4.133-3.733-5.902-5.884l15.306-15.305zm59.226-15.53c.13 1.343.196 2.703.196 4.08 0 1.436-.072 2.856-.212 4.255H62.461l-2.81-4.168 2.81-4.166zm-62.265-.003l2.81 4.166-2.81 4.168H.212C.072 45.007 0 43.589 0 42.154c0-1.378.067-2.74.197-4.083h21.648zM15.383 9.59L30.69 24.897l-.96 4.934-4.932.96L9.502 15.493c1.76-2.156 3.732-4.134 5.881-5.904zm53.478-.05c2.152 1.766 4.127 3.74 5.894 5.892l-15.31 15.31-4.933-.96-.96-4.933zM42.153 0c1.4 0 2.784.069 4.15.204v21.641l-4.167 2.81-4.168-2.81V.206C39.346.07 40.741 0 42.153 0z"/>
										</g>
										<path fill="#FFF" d="M61.785 107.568c6.047 0 9.07 5.009 9.07 10.979 0 5.97-3.023 10.938-9.07 10.938-5.176 0-7.163-2.672-8.24-5.47h-1.657l1.242 3.759v7.138L46.504 137v-29.016h6.626v1.295l-1.242 3.757h1.657c1.077-2.797 3.064-5.468 8.24-5.468zM11.992 102v6.18h6.916v5.966h-6.916v10.015l7.748-.427v5.967l-10.871.426c-2.292.084-3.54-1.278-3.54-3.623v-12.358h-5v-5.966h5v-4.049L11.992 102zm20.173 5.289c6.445 0 11.583 3.618 11.583 11.025 0 7.448-5.138 11.067-11.583 11.067-6.485 0-11.624-3.62-11.624-11.067 0-7.407 5.139-11.025 11.624-11.025zm49.447 0c7.154 0 10.083 3.576 10.083 7.7h-6.693c0-1.516-1.13-2.652-3.43-2.652-1.59 0-2.469.38-2.469 1.222 0 1.009 2.091 1.64 5.438 2.735l.25.082.499.163c3.799 1.243 7.117 2.423 7.117 6.572 0 3.576-2.553 6.27-9.832 6.27-7.782 0-11.13-3.155-11.13-8.122h6.696c0 1.641 1.13 3.072 4.643 3.072 1.799 0 2.929-.422 2.929-1.43 0-.928-.844-1.281-3.17-2.061l-.52-.174-.87-.29c-.298-.1-.6-.196-.904-.293l-.458-.146-.46-.148c-3.455-1.119-6.923-2.488-6.923-6.525 0-3.789 3.389-5.975 9.204-5.975zm16.59.763l13.668.332v5.822l-10.613-.25v15.425h-6.604v-17.837c0-2.286 1.28-3.533 3.55-3.492zm25.153-.763c5.07 0 9.744 2.462 10.973 7.208l-6.353 2.009c-.25-1.895-1.956-3.327-4.62-3.327-3.162 0-4.827 1.895-4.827 5.136 0 3.282 1.665 5.174 4.827 5.174 2.664 0 4.37-1.43 4.62-3.323l6.353 2.008c-1.229 4.744-5.903 7.207-10.973 7.207-6.698 0-11.485-3.955-11.485-11.066 0-7.072 4.787-11.026 11.485-11.026zm-64.675 6.122c-3.562 0-5.55 1.545-5.55 5.136 0 3.59 1.988 5.093 5.55 5.093 3.56 0 5.55-1.502 5.55-5.093 0-3.59-1.99-5.136-5.55-5.136zm-26.515-.232c-3.099 0-5.097 1.601-5.097 5.135 0 3.578 1.998 5.176 5.097 5.176 3.06 0 5.057-1.598 5.057-5.176 0-3.534-1.997-5.135-5.057-5.135z" transform="translate(-120 -90) translate(120 90)"/>
									</g>
								</g>
							</g>
						</svg>
					</NavLink>
				</div>
			</>
		)

	}

}