const Ssps = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 144 144">
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path d="M0 0H144V144H0z" transform="translate(-996 -1462) translate(720 401) translate(27 160) translate(181 866) translate(68 35)"/>
                                    <g stroke-width="4" transform="translate(-996 -1462) translate(720 401) translate(27 160) translate(181 866) translate(68 35) translate(12 30)">
                                        <path id={'sspsP1'} stroke="#90A2AB" d="M73.448 25.348v8.667c0 8.468-6.716 15.333-15 15.333-8.284 0-15-6.865-15-15.333v-8.667h0"/>
                                        <path id={'sspsP2'} stroke="#90A2AB" d="M69.023 50.264c9.639-2.17 16.425-7.36 16.425-13.416 0-8.008-11.864-14.5-26.5-14.5-14.635 0-26.5 6.492-26.5 14.5 0 6.087 6.854 11.297 16.568 13.448"/>
                                        <path id={'sspsP3'} stroke="#90A2AB" d="M23.824 95.348H9.726c-5.124 0-9.278-4.152-9.278-9.275 0-1.68.457-3.33 1.322-4.772l20.29-33.806c1.917-3.193 5.368-5.147 9.093-5.147h3.274m50.366 0h1.949c3.724 0 7.176 1.954 9.092 5.147l20.29 33.806c2.637 4.393 1.212 10.09-3.182 12.726-1.442.864-3.092 1.321-4.773 1.321H94.07h0"/>
                                        <circle id={'sspsc1'} cx="58.948" cy="75.848" r="6.5" stroke="#FF3CC7"/>
                                        <path id={'sspsP4'} stroke="#90A2AB" d="M32.448 36.348c2.294-5.524 5.183-13.974 8.667-25.35C43.793 4.549 50.06.349 57.008.349h2.555c6.947 0 13.215 4.2 15.893 10.65 4.331 11.288 7.662 19.738 9.992 25.35"/>
                                        <path id={'sspsP5'} stroke="#FF3CC7" d="M21.662 50.348h73.572c1.105 0 2 .896 2 2 0 .068-.003.135-.01.203l-4.881 48c-.104 1.02-.964 1.797-1.99 1.797h-63.81c-1.026 0-1.886-.776-1.99-1.797l-4.881-48c-.112-1.1.689-2.08 1.787-2.192.068-.007.135-.01.203-.01z"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Ssps;