import React, {Component} from "react";
import {gsap, Linear, Power4} from "gsap";
import clsx from "clsx";

//Images
import phone from "../images/iphone.png";

//Data
import MESSAGES from '../data/messages';

//Components
import Arrow from "./Arrow";
import PhoneLogo from "./PhoneLogo";

export class Preloader extends Component{

	constructor(props) {

		super(props);

		this.state = {
			inputText: '',
			inputError: false,
			messages: [],
			disableFormSubmit: true,
			preloading: true,
		}

		this.child = React.createRef();

	}

	componentDidMount() {

		const myThis = this,
			messageWriting = {text: '<span class=\'dot left\'></span> <span class=\'dot center\'></span> <span class=\'dot right\'></span>', class: 'messageLeft messageWriting'}

		setTimeout(function (){

			const isMobile = document.body.classList.contains('isMobile') ? true : false;

			myThis.setState({
				preloading : false
			});

			let tl = gsap.timeline();

			if(isMobile === true){
				window.scrollBy(0, 1);
				tl.fromTo('#phone', {opacity: 0}, {opacity: 1}, 0);
				tl.fromTo('#bigCircle', {scale: 0, opacity: 0}, {scale: 1, opacity: 1, onComplete: function (){

						myThis.props.loading(false);

					}}, .25);
			} else {
				tl.fromTo('#phone', {yPercent: -100}, {yPercent: 0}, 0);
				tl.fromTo('#bigCircle', {scale: 0, opacity: 0}, {scale: 1, opacity: 1, onComplete: function (){

						myThis.props.loading(false);

					}}, .25);
			}

			// tl.fromTo('#phoneLogo', {scale: 0}, {scale: 1.6}, .25);
			// tl.to('#bigCircle', {rotation:"360", ease:Linear.easeNone, repeat:-1, duration: 60}, .5);
			// tl.to('#phoneLogo #smallCircle', {rotation:"360", ease:Linear.easeNone, repeat:-1, duration: 60, transformOrigin:"50% 50%"}, .5);
			// tl.fromTo('#phoneLogo #logoTitle', {opacity: 0, y: 100}, {opacity: 1, y: 0, onComplete: function (){
			//
			// 		let delay = 1000;
			//
			// 		for(let i = 0; i < 2; i++){
			//
			// 			if(i == 1){
			// 				delay = 3000;
			// 			}
			//
			// 			setTimeout(function (){
			//
			// 				myThis.setState({messages: myThis.state.messages.concat(messageWriting)});
			//
			// 				let lastMessage = document.querySelector('#phoneMessages li:last-child'),
			// 					lastMessageHeight = lastMessage.clientHeight,
			// 					editDelay = 1000;
			//
			// 				if(i == 1){
			// 					editDelay = 2000;
			// 				}
			//
			// 				gsap.fromTo('#phoneMessages', {y: lastMessageHeight}, {y: 0, ease: Power4.easeOut});
			//
			// 				setTimeout(function (){
			//
			// 					let curMessages = myThis.state.messages;
			//
			// 					curMessages[i] = MESSAGES[i];
			// 					myThis.setState({
			// 						messages : curMessages,
			// 					});
			//
			// 					if(i == 1){
			//
			// 						myThis.setState({
			// 							disableFormSubmit: false
			// 						});
			//
			// 					}
			//
			// 				}, editDelay)
			//
			// 			}, delay);
			// 		}
			//
			// 	}}, .35);

		}, 2000)

	}

	handleSubmit = async (e) => {

		e.preventDefault();

		const myThis = this,
			errors = await this.validate(),
			messageWriting = {text: '<span class=\'dot left\'></span> <span class=\'dot center\'></span> <span class=\'dot right\'></span>', class: 'messageLeft messageWriting'};

		if(!this.state.disableFormSubmit){

			// Success
			if (!Object.keys(errors).length > 0) {

				let usersMessage = {
					text: myThis.state.inputText,
					class: 'messageRight',
				};

				myThis.setState({
					messages: myThis.state.messages.concat(usersMessage),
					disableFormSubmit: true,
				});

				// Remove
				document.querySelector('input[name=inputText]').value = '';

				let lastMessage = document.querySelector('#phoneMessages li:last-child'),
					lastMessageHeight = lastMessage.clientHeight;

				gsap.fromTo('#phoneMessages', {y: lastMessageHeight}, {y: 0, ease: Power4.easeOut});

				setTimeout(function (){

					let curMessages = myThis.state.messages;

					curMessages[2] = MESSAGES[2];
					myThis.setState({messages : curMessages});

					setTimeout(function (){

						myThis.setState({messages: myThis.state.messages.concat(messageWriting)});

						//Animate
						lastMessage = document.querySelector('#phoneMessages li:last-child');
						lastMessageHeight = lastMessage.clientHeight;
						gsap.fromTo('#phoneMessages', {y: lastMessageHeight}, {y: 0, ease: Power4.easeOut});

						setTimeout(function (){

							curMessages = myThis.state.messages;

							curMessages[3] = MESSAGES[3];
							myThis.setState({messages : curMessages});

							let tl = gsap.timeline();

							const isMobile = document.body.classList.contains('isMobile') ? true : false;

							tl.fromTo('#bigCircle', {scale: 1, opacity: 1}, {scale: 0, opacity: 0}, 2);
							tl.fromTo('#phoneLogo', {scale: 1.6, opacity: 1}, {scale: 0, opacity: 0}, 2);

							if(isMobile){
								tl.fromTo('#phone', {opacity: 1}, {opacity: 0, onComplete: function (){

										myThis.props.loading(false);

									}}, 2.5);
							} else {
								tl.fromTo('#phone', {yPercent: 0, opacity: 1}, {yPercent: -100, opacity: 0, onComplete: function (){

										myThis.props.loading(false);

									}}, 2.5);
							}

						}, 1500);

					}, 1500);

				}, 2000);

			}

		}

	}

	validate = async () => {

		const newErrors = {}

		if (!this.state.inputText) {
			newErrors.inputText = 'Required';
			this.setState({
				inputError: true,
			});
		} else {
			this.setState({
				inputError: false,
			});
		}

		return newErrors;

	}

	render() {

		const formInputTextRequiredClass = this.state.inputError ? ' required' : '';
		const showRipple = this.state.preloading ? 'show-ripple' : '';
		const showMobile = this.state.preloading ? '' : 'show-mobile';

			return(
			<div id='loading'>
				<div className={clsx('lds-ripple', showRipple)}><div></div><div></div></div>
				{/*<div className='container relative'>
					<div id='mobileWrapper' className={clsx(showMobile)}>
						<div id='bigCircle'>
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
						</div>
						<div id='phone'>
							<img src={phone} id={"phoneImage"} alt="iPhone" />
							<div id={'phoneContent'}>
								<div id={'phoneLogoWrapper'}>
									<PhoneLogo />
								</div>
								<div id={'phoneMessagesWrapper'}>
									<ul id={'phoneMessages'}>
										{this.state.messages.map(message => (
											<li className={ message.class } dangerouslySetInnerHTML={{__html: message.text}} />
										))}
									</ul>
								</div>
								<div id={'phoneFormArea'}>
									<form id={'phoneForm'} onSubmit={this.handleSubmit}>
										<input
											name={'inputText'}
											className={formInputTextRequiredClass}
											type="text"
											placeholder={'Type your message...'}
											value={this.state.text}
											autoComplete={'off'}
											onChange={(e) => (this.setState({inputText : e.target.value, inputError : false}))} />
										<button type={'submit'}></button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>*/}
			</div>
		)

	}

}