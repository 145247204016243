const MessageSuccess = () => {
  return (
    <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 56 56" width={56} height={56} xmlSpace="preserve">
        <path fill="none" d="M0,0h56v56H0V0z"/>
        <g transform="translate(-759 -170) translate(819 230)">
            <g>
                <path fill="#ADF200" d="M-32-55.3c-12.9,0-23.3,10.5-23.3,23.3S-44.9-8.7-32-8.7S-8.7-19.1-8.7-32 S-19.1-55.3-32-55.3z M-36.7-20.3L-48.3-32l3.3-3.3l8.4,8.4L-19-44.6l3.3,3.3L-36.7-20.3z"/>
            </g>
        </g>
    </svg>

  )
}

export default MessageSuccess;