const CplOffers = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 144 144">
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g transform="translate(-476 -3057) translate(0 2416) translate(27 160) translate(381 446) translate(68 35)">
                                    <path d="M0 0H144V144H0z"/>
                                    <path id="cplP1" fill="#FFF" stroke="#90A2AB" stroke-width="4" d="M31 104h70c1.105 0 2-.895 2-2v-4.026C103 78.106 86.435 62 66 62S29 78.106 29 97.974V102c0 1.105.895 2 2 2z"/>
                                    <g stroke="#90A2AB" stroke-width="4" transform="rotate(90 26.066 107.066)">
                                        <path id="cplP2" fill="#FFF" d="M.839 14.286L19.707.828c.694-.495 1.627-.495 2.322 0l18.868 13.458c.526.375.839.981.839 1.628V58c0 1.105-.896 2-2 2H2c-1.105 0-2-.895-2-2V15.914c0-.647.312-1.253.839-1.628z"/>
                                        <ellipse id="cplE1" cx="20.868" cy="17" rx="5.962" ry="6"/>
                                    </g>
                                    <rect id="cplR1" width="36" height="42" x="48" y="27" fill="#FFF" stroke="#90A2AB" stroke-width="4" rx="18"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CplOffers;