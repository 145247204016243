import React, {Component} from 'react';
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SmoothScrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";
import axios from 'axios';

import DocumentMeta from "react-document-meta";

//Components
import {Footer} from "../component/Footer";
import Arrow from '../component/Arrow';
import Asterisk from '../component/Asterisk';
import MessageSuccess from '../component/MessageSuccess';

export class Recovery extends Component {

    constructor(props) {

        super(props);

        this.state = {
            emailFocused: false,
            emailFilled: false,
            inputEmailValue: '',
            emailError: false,
            success: false,
            error: false,
        };

        // reference to the DOM node
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    loadPage(){

        let tl = gsap.timeline();

        tl.fromTo('#recoveryPage .formWrapper',{y: 120, opacity: 0}, {y: 0, opacity: 1, duration: 1}, 0);

    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validate = async () => {

        const newErrors = {}

        let validateEmailStatus = this.validateEmail(this.state.inputEmailValue);

        if(validateEmailStatus){

            this.setState({
                emailError: false,
            });

        } else {

            newErrors.email = 'Required';
            this.setState({
                emailError: true,
            });

        }

        return newErrors;

    }

    handleSubmit = async (e) => {

        e.preventDefault();

        const errors = await this.validate();

        // Success
        if (!Object.keys(errors).length > 0) {

            const emailValue = this.state.inputEmailValue

            const { data } = await axios.post(`https://n1867.epom.com/rest-api/auth/forgot.do?email=${emailValue}`);

            console.log(data);

            if (!data.success) {

                this.setState({
                    error: data.error
                })

            } else {

                this.setState({
                    error: false,
                    success: true
                })

                let tl = gsap.timeline(),
                    splitMessageTitle = new SplitText('.messageTitle span', {type: "words"}),
                    splitMessageText = new SplitText('.messageText span', {type: "lines"});

                tl.fromTo('.messageIcon svg',{opacity: 0, y: -120},{opacity: 1, y: 0, duration: 0.5}, 0);
                tl.from(splitMessageTitle.words,{duration: .5, y: 60, opacity: 0, stagger: 0.1}, 0);
                tl.from(splitMessageText.lines,{duration: .5, y: 120, opacity: 0, stagger: 0.2}, .5);

            }

        // Fail
        } else {

            //alert('Not Ok!');

        }

    }

    componentDidMount() {

        gsap.registerPlugin(ScrollTrigger);

        const myThis = this;

        if(!localStorage.getItem('wasLoaded')) {

            gsap.to('#recoveryPage .formWrapper', {opacity: 0});

            let startInterval = setInterval(function (){

                if(!myThis.props.loading){

                    gsap.to('#recoveryPage .formWrapper', {opacity: 1});

                    myThis.loadPage();

                    clearInterval(startInterval);

                }

            }, 100)

        } else {

            myThis.loadPage();

        }

        if(myThis.props.isMobile === false) {

            // Scrollbar
            SmoothScrollbar.use(OverscrollPlugin);

            let damping = 0.05;

            let scrollPositionX = 0,
                scrollPositionY = 0,
                vLogo = document.querySelector('#vLogo'),
                footer = document.querySelector('footer'),
                scrollContainer = document.querySelector('.fadePage div'),
                ScrollbarOptions = {
                    damping: damping,
                    thumbMinSize: 10,
                    renderByPixels: true,
                    alwaysShowTracks: false,
                    continuousScrolling: true,
                    plugins: {
                        overscroll: {
                            effect: 'glow',
                            glowColor: '#ffffff'
                        },
                    },
                };

            let scrollBarRoot = SmoothScrollbar.init(scrollContainer, ScrollbarOptions);

            scrollBarRoot.addListener(({offset}) => {
                scrollPositionX = offset.x;
                scrollPositionY = offset.y;
                vLogo.style.transform = 'translateY(-' + scrollPositionY + 'px)';
                footer.style.transform = myThis.props.isMobile ? 'unset' : 'translateY(' + scrollPositionY + 'px)';
            });

            scrollBarRoot.setPosition(0, 0);
            scrollBarRoot.track.xAxis.element.remove();

            ScrollTrigger.scrollerProxy(scrollContainer, {
                scrollTop(value) {
                    if (arguments.length) {
                        scrollBarRoot.scrollTop = value;
                    }
                    return scrollBarRoot.scrollTop;
                }
            });

            scrollBarRoot.addListener(ScrollTrigger.update);

            scrollBarRoot.scrollTo(0, 0);

            //EOF. Smooth Scrollbar

            //PRAVI

            let deltaY,
                currentScroll = 0,
                menuOpened = false;

            scrollBarRoot.addListener((s) => {

                deltaY = -1

                if (currentScroll < s.offset.y) {
                    deltaY = 1
                }

                currentScroll = s.offset.y;

                menuOpened = document.body.classList.contains('menuOpened') ? true : false;

                if (!menuOpened) {

                    if (currentScroll > 0) {

                        document.querySelector('header').classList.add('smalled');

                        if (deltaY < 0) {
                            document.querySelector('header').classList.remove('collapsed');
                        } else {
                            document.querySelector('header').classList.add('collapsed');
                        }

                    } else {
                        document.querySelector('header').classList.remove('collapsed', 'smalled');
                    }

                }

            });

            //EOF. PRAVI

        }

    }

    render() {

        const meta = {
            title: 'Password recovery',//data.content.title,
            description: '',
            canonical: '',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ''
                }
            }
        };

        const focusInput = (e) => {

            if(e.target.name == 'email'){

                this.setState({
                    emailFocused: true,
                })

            }

        };

        const unFocusInput = (e) => {

            if(e.target.name == 'email'){

                this.setState({
                    emailFocused: false,
                    emailFilled: this.state.inputEmailValue != '' ? true : false,
                })

            }

        };

        const formEmailFilledClass = this.state.emailFocused || this.state.emailFilled ? ' formFilled' : '',
            formEmailRequiredClass = this.state.emailError ? ' required' : '';

        return(
            <DocumentMeta {...meta}>
                <div id={'recoveryPage'} className={'page formPage fullHeight'}>
                    {!this.state.success ? (
                    <div className={'formWrapper'}>
                        <form id={'recoveryForm'} className={'form'} onSubmit={this.handleSubmit} method={'POST'}>
                            <h1>Forgot password</h1>
                            <div className={'formControl' + formEmailFilledClass + formEmailRequiredClass}>
                                <label className={'formLabel'}><span>Email</span></label>
                                <input name={'email'}
                                       type={'email'}
                                       value={this.state.inputEmailValue}
                                       onChange={(e) => (this.setState({'inputEmailValue' : e.target.value, 'emailError' : false}))}
                                       onFocus={focusInput}
                                       onBlur={unFocusInput} />
                                <Asterisk/>
                                <div className={'errorCorner'}></div>
                            </div>
                            <div className={'formControl'}>
                                <button className={'btn btnGray'} type={'submit'}>Send recovery link <Arrow/></button>
                            </div>
                        </form>
                    </div>
                    ) : (
                    <div className={'message'}>
                        <div className={'messageIcon'}><MessageSuccess/></div>
                        <p className={'messageTitle'}><span>A password reset link was sent.</span></p>
                        <p className={'messageText'}><span>Click the link in the email to create a new password</span></p>
                    </div>
                    )}
                </div>
                <div id={"footerOverlay"}></div>
                <Footer/>
            </DocumentMeta>
        )

    }

}
