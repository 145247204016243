const Noob = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 144 144">
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g transform="translate(-126 -5448) translate(0 4282) translate(27 160) translate(31 971) translate(68 35)">
                                    <path d="M0 0H144V144H0z"/>
                                    <path id={'noobP1'} stroke="#90A2AB" stroke-width="4" d="M81 76h7.222C95.832 76 102 82.089 102 89.6V93H40v-3.4C40 82.089 46.169 76 53.778 76H63M34.38 93h75.24c1.104 0 2 .895 2 2 0 .116-.01.23-.03.345l-6.3 36c-.168.957-.999 1.655-1.97 1.655H40.68c-.971 0-1.802-.698-1.97-1.655l-6.3-36c-.19-1.088.538-2.125 1.626-2.315.113-.02.229-.03.344-.03z"/>
                                    <circle id={'noobC1'} cx="72" cy="114" r="6" stroke="#90A2AB" stroke-width="4"/>
                                    <path id={'noobP2'} stroke="#90A2AB" stroke-width="4" d="M72 18c13.355 0 24.182 10.827 24.182 24.182 0 .984-.059 1.955-.173 2.908 2.143 1.014 3.627 3.198 3.627 5.728 0 3.498-2.835 6.334-6.333 6.334-.365 0-.722-.031-1.07-.09-2.06 9.275-10.336 16.21-20.233 16.21s-18.173-6.935-20.234-16.211c-.347.06-.704.09-1.069.09-3.498 0-6.333-2.835-6.333-6.333 0-2.53 1.483-4.713 3.628-5.728-.115-.953-.174-1.924-.174-2.908C47.818 28.827 58.645 18 72 18z"/>
                                    <path id={'noobP3'} stroke="#90A2AB" stroke-width="4" d="M64 71l-1 5c0 5.05 3.912 9 9 9s9-3.95 9-9l-1-5M66 63c1.647.841 4.023 1 6 1 1.68 0 4.547-.381 6-1"/>
                                    <circle id={'noobC2'} cx="62.939" cy="51.394" r="5.758" stroke="#90A2AB" stroke-width="4"/>
                                    <circle id={'noobC3'} cx="81.061" cy="51.394" r="5.758" stroke="#90A2AB" stroke-width="4"/>
                                    <path id={'noobP4'} stroke="#90A2AB" stroke-width="4" d="M74.303 51.394L69.697 51.394M96.182 44.485h-4.606c-9.63 0-19.094-3.009-26.485-8.06-4.16 5.24-10.163 7.656-17.273 8.06"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Noob;