const SelectArrow = () => {
  return (
    <svg className={'selectArrow'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <path d="M0 0L24 0 24 24 0 24z"/>
          <path fill="#000" fillRule="nonzero" d="M7.41 9L12 13.58 16.59 9 18 10.41 12 16.41 6 10.41z"/>
        </g>
      </g>
    </svg>
  )
}

export default SelectArrow;