import React, {Component} from 'react';
import { NavLink } from 'react-router-dom'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SmoothScrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";
import axios from 'axios';

import DocumentMeta from "react-document-meta";

//Components
import {Footer} from "../component/Footer";
import Arrow from '../component/Arrow';
import Asterisk from '../component/Asterisk';

export class Login extends Component {

    constructor(props) {

        super(props);

        this.state = {
            emailFocused: false,
            emailFilled: false,
            passwordFocused: false,
            passwordFilled: false,
            inputEmailValue: '',
            inputPasswordValue: '',
            emailError: false,
            passwordError: false,
            error: false,
        };

        // reference to the DOM node
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    loadPage(){

        let tl = gsap.timeline();

        tl.fromTo('#loginPage .formWrapper',{y: 120, opacity: 0}, {y: 0, opacity: 1, duration: 1}, 0);

    }

    validate = async () => {

        const newErrors = {}

        if (!this.state.inputEmailValue) {
            newErrors.email = 'Required';
            this.setState({
                emailError: true,
            });
        } else {
            this.setState({
                emailError: false,
            });
        }

        if (!this.state.inputPasswordValue) {
            newErrors.password = 'Required';
            this.setState({
                passwordError: true,
            });
        } else {
            this.setState({
                passwordError: false,
            });
        }

        return newErrors;

    }

    handleSubmit = async (e) => {

        e.preventDefault();

        const errors = await this.validate();

        if (!Object.keys(errors).length > 0) {

            const emailValue = this.state.inputEmailValue
            const passwordValue = this.state.inputPasswordValue

            await axios.post(`https://api.topsrc.com/auth/login`, {
                login: emailValue,
                password: encodeURI(passwordValue),
                rememberMe: true
            },{
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {

                if (response.data.status === 'AUTHENTICATED'){

                    this.setState({
                        error: false
                    });

                    document.cookie = 'userInfo=' + JSON.stringify(response.data) + '; domain=.topsrc.com;';

                    window.location.replace('https://dsp.topsrc.com/redirect');

                } else {

                    this.setState({
                        error: 'Login failure!'
                    })

                }

            }).catch((error) => {

                this.setState({
                    error: 'Your login or password is wrong!'
                })

            });

        }

    }

    componentDidMount() {

        gsap.registerPlugin(ScrollTrigger);

        const myThis = this;

        if(!localStorage.getItem('wasLoaded')) {

            gsap.to('#loginPage .formWrapper', {opacity: 0});

            let startInterval = setInterval(function (){

                if(!myThis.props.loading){

                    gsap.to('#loginPage .formWrapper', {opacity: 1});

                    myThis.loadPage();

                    clearInterval(startInterval);

                }

            }, 100)

        } else {

            myThis.loadPage();

        }

        if(myThis.props.isMobile === false){

            // Scrollbar
            SmoothScrollbar.use(OverscrollPlugin);

            let damping = 0.05;

            let scrollPositionX = 0,
                scrollPositionY = 0,
                vLogo = document.querySelector('#vLogo'),
                footer = document.querySelector('footer'),
                scrollContainer = document.querySelector('.fadePage div'),
                ScrollbarOptions = {
                    damping: damping,
                    thumbMinSize: 10,
                    renderByPixels: true,
                    alwaysShowTracks: false,
                    continuousScrolling: true,
                    plugins: {
                        overscroll: {
                            effect: 'glow',
                            glowColor: '#ffffff'
                        },
                    },
                };

            let scrollBarRoot = SmoothScrollbar.init(scrollContainer, ScrollbarOptions);

            scrollBarRoot.addListener(({ offset }) => {
                scrollPositionX = offset.x;
                scrollPositionY = offset.y;
                vLogo.style.transform = 'translateY(-' + scrollPositionY + 'px)';
                footer.style.transform = myThis.props.isMobile ? 'unset' : 'translateY(' + scrollPositionY + 'px)';
            });

            scrollBarRoot.setPosition(0,0);
            scrollBarRoot.track.xAxis.element.remove();

            ScrollTrigger.scrollerProxy(scrollContainer, {
                scrollTop(value) {
                    if (arguments.length) {
                        scrollBarRoot.scrollTop = value;
                    }
                    return scrollBarRoot.scrollTop;
                }
            });

            scrollBarRoot.addListener(ScrollTrigger.update);

            scrollBarRoot.scrollTo(0, 0);

            //EOF. Smooth Scrollbar

            //PRAVI

            let deltaY,
                currentScroll = 0,
                menuOpened = false;

            scrollBarRoot.addListener((s) => {

                deltaY = -1

                if(currentScroll < s.offset.y){
                    deltaY = 1
                }

                currentScroll = s.offset.y;

                menuOpened = document.body.classList.contains('menuOpened') ? true : false;

                if(!menuOpened){

                    if(currentScroll > 0){

                        document.querySelector('header').classList.add('smalled');

                        if (deltaY < 0){
                            document.querySelector('header').classList.remove('collapsed');
                        } else {
                            document.querySelector('header').classList.add('collapsed');
                        }

                    } else {
                        document.querySelector('header').classList.remove('collapsed', 'smalled');
                    }

                }

            });

            //EOF. PRAVI

        }

    }

    render() {

        const meta = {
            title: 'Login',//data.content.title,
            description: '',
            canonical: '',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ''
                }
            }
        };

        const focusInput = (e) => {

            if(e.target.name == 'email'){

                this.setState({
                    emailFocused: true,
                })

            } else if(e.target.name == 'password'){

                this.setState({
                    passwordFocused: true,
                })

            }

        };

        const unFocusInput = (e) => {

            if(e.target.name == 'email'){

                this.setState({
                    emailFocused: false,
                    emailFilled: this.state.inputEmailValue != '' ? true : false,
                })

            } else if(e.target.name == 'password'){

                this.setState({
                    passwordFocused: false,
                    passwordFilled: this.state.inputPasswordValue != '' ? true : false,
                })

            }

        };

        const formEmailFilledClass = this.state.emailFocused || this.state.emailFilled ? ' formFilled' : '',
            formEmailRequiredClass = this.state.emailError ? ' required' : '',
            formPasswordFilledClass = this.state.passwordFocused || this.state.passwordFilled ? ' formFilled' : '',
            formPasswordRequiredClass = this.state.passwordError ? ' required' : '';

        return(
            <DocumentMeta {...meta}>
                <div id={'loginPage'} className={'page formPage fullHeight'}>
                    <div className={'formWrapper'}>
                        <form id={'loginForm'} className={'form'} onSubmit={this.handleSubmit} method={'POST'}>
                            <h1>Login</h1>
                            <div className={'formControl' + formEmailFilledClass + formEmailRequiredClass}>
                                <label className={'formLabel'}><span>Email</span></label>
                                <input name={'email'}
                                       type={'email'}
                                       value={this.state.inputEmailValue}
                                       onChange={(e) => (this.setState({'inputEmailValue' : e.target.value, 'emailError' : false}))}
                                       onFocus={focusInput}
                                       onBlur={unFocusInput} />
                                <Asterisk/>
                                <div className={'errorCorner'}></div>
                            </div>
                            <div className={'formControl' + formPasswordFilledClass + formPasswordRequiredClass}>
                                <label className={'formLabel'}><span>Password</span></label>
                                <input name={'password'}
                                       type="password"
                                       value={this.state.inputPasswordValue}
                                       autoComplete={'false'}
                                       onChange={(e) => (this.setState({'inputPasswordValue' : e.target.value, 'passwordError' : false}))}
                                       onFocus={focusInput}
                                       onBlur={unFocusInput} />
                                <Asterisk/>
                                <NavLink id={'forgotPasswordLink'} to="/recovery">Forgot password</NavLink>
                                <div className={'errorCorner'}></div>
                            </div>
                            <div className={'formControl'}>
                                <label className='checkboxControl hoverable' htmlFor={'rememberMe'}>
                                    <span className={'checkText'}>Remember me</span>
                                    <span className="checkMark">
                                        <input id={'rememberMe'} type="checkbox" />
                                        <span className={'checkStatus'}></span>
                                    </span>
                                </label>
                            </div>

                            {this.state.error && <div className="error">{this.state.error}</div>}

                            <div className={'formControl'}>
                                <button className={'btn btnGray'} type={'submit'}>Sign in <Arrow/></button>
                            </div>
                        </form>
                        <NavLink className={'btn btnGray btnBorder'} to="/registration">Create account <Arrow/></NavLink>
                    </div>
                </div>
                <div id={"footerOverlay"}></div>
                <Footer/>
            </DocumentMeta>
        )

    }

}
