import React, {Component} from 'react';
import DocumentMeta from "react-document-meta";
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SmoothScrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";

//Images & Icons
import SkypeIcon from '../images/icons/skype-color.svg';
import TelegramIcon from '../images/icons/telegram-color.svg';
import EmailIcon from '../images/icons/email-color.svg';

//Data
import { EMAIL, SKYPE, TELEGRAM } from '../data/contacts';

//Components
import PageTitle from '../component/PageTitle';
import {Footer} from "../component/Footer";

const FOOTER_CONTACTS = [
    {
        name: 'Email',
        icon: EmailIcon,
        link: EMAIL,
    },
    {
        name: 'Skype',
        icon: SkypeIcon,
        link: SKYPE,
    },
    {
        name: 'Telegram',
        icon: TelegramIcon,
        link: TELEGRAM,
    },
];

export class Terms extends Component {

    constructor(props) {

        super(props);
        // reference to the DOM node

        this.loadPage = this.loadPage.bind(this);

    }

    loadPage(){

        let tl = gsap.timeline(),
            splitTitle = new SplitText('#pageTitle h1 span', {type: "chars"});

        tl.from(splitTitle.chars,{duration: 1, y: 120, opacity: 0, stagger: 0.1}, 0);

    }

    componentDidMount() {

        gsap.registerPlugin(ScrollTrigger);

        const myThis = this;

        if(!localStorage.getItem('wasLoaded')){

            gsap.to('#pageTitle h1', {opacity: 0});

            let startInterval = setInterval(function (){

                if(!myThis.props.loading){

                    gsap.to('#pageTitle h1', {opacity: 1});

                    myThis.loadPage();

                    clearInterval(startInterval);

                }

            }, 100)

        } else {

            myThis.loadPage();

        }

        if(myThis.props.isMobile === false) {

            // Scrollbar
            SmoothScrollbar.use(OverscrollPlugin);

            let damping = 0.05;

            let scrollPositionX = 0,
                scrollPositionY = 0,
                vLogo = document.querySelector('#vLogo'),
                footer = document.querySelector('footer'),
                scrollContainer = document.querySelector('.fadePage div'),
                ScrollbarOptions = {
                    damping: damping,
                    thumbMinSize: 10,
                    renderByPixels: true,
                    alwaysShowTracks: false,
                    continuousScrolling: true,
                    plugins: {
                        overscroll: {
                            effect: 'glow',
                            glowColor: '#ffffff'
                        },
                    },
                };

            let scrollBarRoot = SmoothScrollbar.init(scrollContainer, ScrollbarOptions);

            scrollBarRoot.addListener(({offset}) => {
                scrollPositionX = offset.x;
                scrollPositionY = offset.y;
                vLogo.style.transform = 'translateY(-' + scrollPositionY + 'px)';
                footer.style.transform = myThis.props.isMobile ? 'unset' : 'translateY(' + scrollPositionY + 'px)';
            });

            scrollBarRoot.setPosition(0, 0);
            scrollBarRoot.track.xAxis.element.remove();

            ScrollTrigger.scrollerProxy(scrollContainer, {
                scrollTop(value) {
                    if (arguments.length) {
                        scrollBarRoot.scrollTop = value;
                    }
                    return scrollBarRoot.scrollTop;
                }
            });

            scrollBarRoot.addListener(ScrollTrigger.update);

            scrollBarRoot.scrollTo(0, 0);

            //EOF. Smooth Scrollbar

            //PRAVI

            let deltaY,
                currentScroll = 0,
                menuOpened = false;

            scrollBarRoot.addListener((s) => {

                deltaY = -1

                if (currentScroll < s.offset.y) {
                    deltaY = 1
                }

                currentScroll = s.offset.y;

                menuOpened = document.body.classList.contains('menuOpened') ? true : false;

                if (!menuOpened) {

                    if (currentScroll > 0) {

                        document.querySelector('header').classList.add('smalled');

                        if (deltaY < 0) {
                            document.querySelector('header').classList.remove('collapsed');
                        } else {
                            document.querySelector('header').classList.add('collapsed');
                        }

                    } else {
                        document.querySelector('header').classList.remove('collapsed', 'smalled');
                    }

                }

            });

            //EOF. PRAVI

            // Scroll Title
            ScrollTrigger.create({
                trigger: "#titleOverlay",
                start: "0% 0%",
                end: 400,
                pin: '#pageTitle',
                scroller: scrollContainer,
            });

        }

    }

    render() {

        const meta = {
            title: 'Terms and conditions',//data.content.title,
            description: '',
            canonical: '',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ''
                }
            }
        };

        return(
            <DocumentMeta {...meta}>
                <div id={'titleOverlay'}></div>
                <PageTitle title="Terms" />
                <div id={'termsPage'} className={'page'}>
                    <div className="contentBlock whiteBg">
                        <div className={'container'}>

                            <h1>Terms &amp; Conditions</h1>
                            <p><b>TERMS AND CONDITIONS FOR PUBLISHER(S) AND ADVERTISER(S)</b></p>
                            <p>Effective on 12 December 2023</p>
                            <p><b>THIS IS A LEGAL AGREEMENT BETWEEN YOU (&ldquo;PUBLISHER&rdquo; OR &ldquo;ADVERTISER&rdquo;) AND TOP SOURCE MEDIA LIMITED (&ldquo;TOPSRC NETWORK&rdquo; OR &ldquo;TOPSRC PLATFORM&rdquo;) STATING THE TERMS AND CONDITIONS THAT GOVERN YOUR PARTICIPATION IN THE TOPSRC NETWORK. PLEASE READ THESE TOPSRC TERMS AND CONDITIONS (&ldquo;AGREEMENT&rdquo;) AND THE DATA PROCESSING AGREEMENT (&ldquo;DPA&rdquo;) BEFORE PRESSING THE &ldquo;SIGNUP&rdquo; BUTTON AT THE BOTTOM OF THE SIGNUP PAGE. BY PRESSING &ldquo;SIGNUP&rdquo; YOU ARE AGREEING TO BE BOUND BY THE TERMS AND CONDITIONS AND THE DATA PROCESSING AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS AND THE DATA PROCESSING AGREEMENT, YOU WILL BE UNABLE TO PARTICIPATE ON THE TOPSRC NETWORK.</b></p>

                            <h2>DEFINITIONS</h2>
                            <p>For the purposes of this Agreement, the parties agree that, when used capitalized herein, the following terms shall have the following meanings unless they are otherwise defined in this Agreement:</p>
                            <p><b>&ldquo;Advertiser(s)&rdquo;</b> means one or more customers of TopSrc which create the Advertising Material, and authorize TopSrc as its intermediary to include it on the Publisher&rsquo;s Website(s).</p>
                            <p><b>&ldquo;Advertising Material&rdquo;</b> means the text, graphics, logos, designs, trademarks and copyrights for any type of advertising including, but not limited to banners, text ads, pop-ups and pop-unders created by an advertiser.</p>
                            <p><b>&ldquo;Publisher(s)&rdquo;</b> means one or more customers of TopSrc responsible for the distribution of online Advertising Material on its Website(s).</p>
                            <p><b>&ldquo;Publisher&acute;s Website(s)&rdquo;</b> means the space, including without limitation, homepage, website or e-mail, where the Publisher(s) incorporate or embed the Advertising Material.</p>
                            <p><b>&ldquo;TopSrc Network&rdquo;</b> means the advertising network owned and operated by Top Source Media Limited</p>
                            <p><b>&ldquo;Ad Serving Platform&rdquo;</b> describes the technology and service that places advertisements on Websites, which includes an advertising software which has been licensed to TopSrc and which TopSrc hereby sublicenses to Publishers and Advertisers participating in the TopSrc Network subject to this Agreement.</p>
                            <p><b>&ldquo;Impressions&rdquo;</b> means the number of times Advertising Material is served to a person visiting the Publisher&rsquo;s Website(s).</p>
                            <p><b>&ldquo;eCPM&rdquo;</b> means effective cost per mil. eCPM is used on the TopSrc Network to calculate the relative cost of an advertising campaign and estimates the cost / revenue per 1000 views of the ad.</p>
                            <p><b>&ldquo;Publisher Earnings&rdquo;</b> means the total revenue generated by the Publisher using the Advertising Materials less TopSrc margin. Publisher earnings are based on the eCPM generated by the ad spot. TopSrc margin remains at the sole and absolute discretion of TopSrc.</p>
                            <p><b>&ldquo;Unique Click&rdquo;</b> means the number of times, as recorded by TopSrc is Ad Serving Platform a user visiting Publisher&rsquo;s Website(s), as identified by IP address, clicks on Advertising Material. A click on Advertising Material by a particular visitor shall only be counted as a Unique Click once every 24-hour period.</p>
                            <p><b>&ldquo;Administration Panel&rdquo;</b> means the interface provided by TopSrc to clients (Publishers, Advertisers and Third Parties) in order to buy and sell traffic on the TopSrc Network.</p>
                            <p><b>&ldquo;Insertion Order&rdquo;</b> is a written authorization to display advertisement on Publisher&acute;s Website(s).</p>

                            <h2>CLAUSES</h2>

                            <p><b>ONE.- OBJECT.</b></p>
                            <p>By virtue of this Agreement Advertiser(s) markets and advertises their goods and services using Advertising Material and Publisher(s) incorporates or embeds the Advertising Material into the Publisher&acute;s Website(s) through TopSrc Network using the Ad Serving Platform. On top of that, TopSrc provides guidance to help Advertiser(s) and Publisher(s).</p>
                            <p>TopSrc is a service provider and has no effective knowledge about the content in the Publisher&acute;s Website(s) and/or the Advertising Material created by the Advertiser(s), which is published in the Publisher&acute;s Website(s). Publisher and Advertiser are the only responsible parties for such content and will always hold TopSrc harmless for any responsibility, infringement, damage or loss in relation to such content. If any content is illegal or violates any law in general, and in particular, any intellectual property laws, please request the removal to TopSrc at  <a href="mailto:info@topsrc.com">info@topsrc.com</a>, so we can remove and prevent access to it.</p>
                            <p>Advertisers and Publishers shall always comply with TopSrc&rsquo;s policies, including, but not limited to, its&nbsp;Guidelines for Publishers and Advertisers, Content moderation procedures, and notice mechanism to remove all kinds of illegal content.&nbsp;</p>

                            <p><b>TWO.- PUBLISHER ELIGIBLE WEBSITE(S).</b></p>
                            <p>2.1. TopSrc reserves the right to approve or to deny the affiliation of a Publisher at any time. If not approved, Publisher shall not be entitled to the payment of the revenue displayed in its Administration Panel. It is the Publisher&rsquo;s obligation to make sure it complies, at all times, with TopSrcs&rsquo; eligibility rules. The following are examples of Publisher&acute;s Website(s) that are not eligible for participation on the TopSrc Network:</p>
                            <ul>
                                <li>Websites which contain material that infringes the rights of others (including but not limited to copyright and other intellectual property rights) or which promotes copyright piracy.</li>
                                <li>Websites with reference to illicit practices or shock human dignity: Content with pornographic character staging minors(miners); Contents making the apology of voluntary crimes to life, persons the integrity and sexual aggressions; Contents making the apology of war crimes, crimes against humanity; Contents provoking discrimination, hate or violence towards a person or towards a group of persons at the rate of their origin or of the group they belong to (an ethnic group, a nation, a race or a religion). Any description will be automatically recorded and indicated to proper authorities.</li>
                                <li>Websites that participate in or transmit inappropriate newsgroup postings or unsolicited e-mail (spam) as well as unsolicited communications by equivalent electronic means (i.e. sms, chat, social networks, etc.).</li>
                                <li>Websites promoting any type of illegal substance or activity (i.e., how to build a bomb, hacking, &lsquo;phreaking&rsquo;, etc.)</li>
                                <li>Websites with illegal, false or deceptive investment advice.</li>
                                <li>Websites that provide incentives of any nature to require or encourage users to click on ad banners (i.e., charity, sweepstakes, etc.).</li>
                                <li>Websites that are under construction or incomplete.</li>
                                <li>Websites with extremely limited audiences or viewership (less than 50 unique visitors per day).</li>
                                <li>Websites that contain any content violating Spanish laws in general, or the laws of any other country if applicable. In particular, those which violate Spanish intellectual property, personal data or privacy laws. Publishers Website(s) shall not violate any law, regulation, rule or custom or violate, infringe or misappropriate any person&rsquo;s or entity&rsquo;s rights, including without limitation any property or privacy rights, including intellectual property rights, such as copyrights, trademark rights or rights in name or likeness.</li>
                                <li>Websites generated through free blogging or free forum platforms.</li>
                                <li>Websites with more than 6 ads per page, sites with more than 2 pop-ups and / or pop-unders, sites with more than 1 exit pop-up (all ad networks or affiliate programs together).</li>
                                <li>Websites presenting no added value to the users.</li>
                            </ul>
                            <p>2.2. TopSrc does not control or monitor Publishers Website(s). However, a Website may be brought to its attention or may be checked for any reason. If TopSrc determines that Publisher&rsquo;s Website(s) includes any forbidden content, TopSrc may close Publisher&rsquo;s account without prior notification. In such a case, as a penalty for the breach of this Agreement Publisher shall not be entitled to the payment of any amounts remaining in its Administration Panel, regardless of any other or further liability incurred by the Publisher.</p>

                            <p><b>THREE.- SUB-PUBLISHERS.</b></p>
                            <p>Publishers who are operating a network with Sub-Publishers themselves hereby guarantee to communicate this Agreement to their Sub-Publishers and to monitor and enforce their compliance. Publishers will be held liable for the conduct of their Sub-Publishers.</p>

                            <p><b>FOUR.- MISUSE.</b></p>
                            <p>4.1. Any form of misuse, i.e. procuring business transactions by unfair methods or inadmissible means that violate applicable law or this Agreement, is prohibited.</p>
                            <p>4.2. In particular, Publishers are prohibited from attempting to obtain commissions by procuring business transactions themselves or through a third person using the Advertising Material, tracking links and/or other technical aids provided to them in the context of the TopSrc Network using one or several of the following methods:</p>
                            <p>4.2.1. Fraudulently pretending or faking business transactions, for example by entering third party data without authorization or by providing false or non-existing data when ordering goods or registering online;</p>
                            <p>4.2.2. Using Advertising Material that enables tracking although it is not displayed at all, not visibly or not in the manner and/or size stipulated by the Advertiser; or</p>
                            <p>4.2.3. Brand-Bidding.</p>
                            <p>4.3. Any form of misuse will lead to the blocking of the Publishers&rsquo; accounts immediately. In this case Publishers may raise an objection (i.e. via letter, fax, e-mail) within a month in order to provide a statement and evidence that the chosen form of advertising has been in accordance with this Agreement. If the Publisher cannot confute the breach of this Agreement, TopSrc will issue a notice of termination. In the event of termination, the Agreement will be wound up and liquidated pursuant to the stipulations of Clause 12 of this Agreement. As a penalty for the breach of this Agreement, the Publisher shall not be entitled to any remuneration, regardless of any other or further liability incurred by the Publisher.</p>
                            <p>4.4. Fraudulent Impressions. TopSrc&rsquo;s ad server will be the official counter for determining the number of Advertising Material delivered under and amounts payable under this Agreement. Any method to artificially and/or fraudulently inflates the volume of impressions or clicks is strictly forbidden. Counts of impressions or clicks will be decided solely on the basis of reports generated by TopSrc Advertising Network. Any method to artificially and/or fraudulently inflate the volume of impressions or clicks is strictly forbidden. These prohibited methods include but are not limited to: framing an ad-banner&rsquo;s click-through destination, auto-spawning of browsers, running &lsquo;spiders&rsquo; against the Publisher&rsquo;s own Website, automatic redirecting of users or any other technique of generating automatic or fraudulent (as determined by TopSrc, acting reasonably, or based on industry practices) click-through and/or impressions. Advertising Material may not be placed on a page which reloads automatically. Publisher may not require users to click on Advertising Material prior to entering a Website or any area therein or provide incentives of any nature to encourage or require users to click on Advertising Material. Publisher&rsquo;s clicks-throughs of any link other than TopSrc&rsquo;s Advertising Material, or use of any other means of artificially enhancing click results shall be a material breach of this Agreement, and upon such occurrence, TopSrc may terminate this Agreement without prior notification. Such termination is at the sole discretion of TopSrc and is not in lieu of any other remedy available at law or equity. TopSrc&rsquo;s ad server will be the official counter for determining the number of Advertising Material delivered under and amounts payable under this Agreement.</p>
                            <p>Publisher(s) will indemnify and hold TopSrc harmless of any liabilities, losses or damages of any nature which are directly or indirectly derived from their infringement of this Agreement.</p>

                            <p><b>FIVE.- PUBLISHER PAYMENT.</b></p>
                            <p>5.1 Payments. Publisher payments can be made weekly or monthly via Wire Transfer, Paxum, Payoneer, Cryptocurrencies, please note Wire Transfer and Cryptocurrencies (BTC, BCC, BTG, ETH, LTC) payments are only available monthly (Net 20). Payments are generated automatically by the TopSrc platform. In case of Cryptocurrencies payments, TopSrc won&rsquo;t be held accountable if an erroneous wallet address has been provided. To receive a payment, Publishers must reach the minimum payout limit set up in the TopSrc Administration Panel. TopSrc shall pay Publisher for Advertising Material actually delivered by Publisher to each of Publisher&rsquo;s Website(s) approved by TopSrc.</p>
                            <p>5.2 Invoicing. The Publisher expressly instructs TopSrc to generate and issue the Publisher&rsquo;s invoices on behalf of the Publisher. In that regard, prior to making any payment to a Publisher, TopSrc will generate automatically, through the TopSrc platform, the invoice on behalf of such Publisher, which he expressly accepts hereby in advance. Furthermore, the Publisher expressly acknowledges that the TopSrc&rsquo;s platform will generate the said invoices based on the data provided by the Publisher and therefore warrants that such data is accurate, fully and legally compliant, especially for invoicing and taxation purposes. Any Publisher residing in the European Union who has provided a VAT number expressly warrants that such VAT number is, in its own country, valid for the issuance of VAT-exempt invoices to TopSrc. Should a Publisher do not provide TopSrc with all the required documents proving its correct registration as a freelancer with the relevant tax authorities, he hereby instructs TopSrc to deduct and pay any VAT and/or withholding tax amount that might be due to the Spanish tax office. The Publisher expressly accepts to be solely liable for any error, direct or indirect loss or damage arising from the inaccuracy or non-compliance of such data or the breach of any of the aforementioned warranties and, accordingly, the Publisher will hold TopSrc totally harmless from any of the said errors, direct or indirect loss or damages. In case there is any claim, administrative proceeding from any authority, dispute or conflict, in any way due to the inaccuracy or non-compliance of such data provided by the Publisher, TopSrc is expressly authorized to retain any payments due to the Publisher until such incident has been resolved and also obtain direct compensation from those amounts retained in case TopSrc suffers any loss or damage.</p>
                            <p>5.3 Liability for Publisher&rsquo;s Revenue. Publisher understands and agrees that TopSrc acts solely as a third party for the Advertisers; and that TopSrc shall only be liable to Publisher for Publishers Revenue based on payments from Advertisers that it has received without restrictions that constitute immediately-available funds to TopSrc. Publisher agrees that (i) TopSrc shall have no liability or obligation to Publisher for payments due but unpaid from Advertisers; (ii) Publisher will only assert any claims therefore directly against the Advertisers; and (iii) Publisher shall hold TopSrc harmless and indemnify it from any claims or liability related to such unpaid amounts. TopSrc agrees to make every reasonable effort to bill, collect and clear payment from the Advertisers on a timely basis. TopSrc, reserves the absolute right not to make any payments if the Publisher violates any of the terms and conditions set forth herein.</p>
                            <p>5.4 Other Expenses. TopSrc assumes no responsibility for paying any income taxes, banking commissions or currency fees on behalf of Publisher. By participating in the TopSrc Network, Publisher assumes complete and sole responsibility for any taxes, banking commissions or currency fees owed as a consequence thereof.</p>
                            <p>5.5 TopSrc is entitled to withhold, stop or cancel any payments due to Publisher, or disclose any information regarding the Publisher, should it be required to do so by a court or administrative authority.</p>

                            <p><b>SIX.- PUBLISHERS REFERRAL PROGRAM.</b></p>
                            <p>6.1. TopSrc Referral Program (&ldquo;Program&rdquo;) has been created to reward Publishers for each referral they bring, which means, Publishers wanting to bring other Publishers to TopSrc will receive 5% of the revenue generated by each new approved Publisher, in the first three months of work. Publishers can find the Program link in their Administration Panel under &ldquo;Publishers&rdquo; &ndash;&nbsp; &ldquo;Referral Program&rdquo;.&nbsp;</p>
                            <p>6.2. By participating in the Program, the Publisher agrees to and is bound by the Program conditions. If Publishers do not wish to agree to and abide by the Program conditions in their entirety, they are not authorized to participate in the Program. TopSrc reserves the right to find ineligible any Publisher in the Program at its sole discretion.</p>
                            <p>6.3. The Program has the following conditions:</p>
                            <ul>
                                <li>The Publisher may not refer to its own accounts.</li>
                                <li>Ad Networks are not eligible for the Program.</li>
                                <li>Google Ads campaigns containing or targeting TopSrc brands and products are not allowed.</li>
                            </ul>
                            <p>6.4. TopSrc reserves the right to suspend any account or, if applicable, to disqualify any customers or prospective customers at any time from participation in the Program. TopSrc reserves the right to modify these Program conditions at any time, at its sole discretion, and without notice. TopSrc may also, in its sole discretion, change, cancel, suspend, or modify any aspect of the Program without notice.</p>

                            <p><b>SEVEN.- ADVERTISING MATERIAL.</b></p>
                            <p>7.1. TopSrc Javascript tag. Publisher(s) shall place the TopSrc Javascript tag on all appropriate pages within its website(s). Publisher(s) shall not alter, sell or disclose the TopSrc Javascript tag in any way without TopSrc prior written consent. The TopSrc Javascript tag for Advertising Material may not be used on a web page other than one located at an approved Website and may not be distributed or submitted to any newsgroup, e-mail distribution list, chat room, guest books, or other location that hasn&rsquo;t been approved by TopSrc.</p>
                            <p>7.2. Recording of Service Counts. TopSrc has the sole responsibility for calculation of statistics, including Impressions, click-through rate, revenues, eCPM. EST Time shall be the time period for traffic and tracking purposes. Statistics shall be available to Publisher online in the TopSrc Administration Panel. Publisher understands that TopSrc&rsquo;s online statistics may not be 100% accurate and that TopSrc may make adjustments to Publisher&rsquo;s online statistics. In the event that coding on Publisher&rsquo;s Website(s) generates substantial number of erroneous impression due to a technical problem such as server malfunction, coding alteration or a mistake in entering code, TopSrc reserves the right to withhold payment on all Impressions and clicks delivered by Publisher.</p>
                            <p>7.3. Volume of impressions. TopSrc cannot guarantee any volume of traffic. Impressions can differ from one day to another following the performances of the site targeted.</p>
                            <p>7.4. Ad Serving Platform. Statistics of impressions and revenues will be provided by TopSrc. Ad Serving Platform shall govern this Agreement.</p>

                            <p><b>EIGHT.- ADVERTISING BUDGET</b></p>
                            <p>8.1. Advertiser must prepay its advertising budget through credit card, wire transfer or Paxum. Advertiser shall pay all charges in U.S. Dollars or in Euros, according to the currency set up in its TopSrc Administration Panel.</p>
                            <p>8.2. Charges are exclusive of taxes. Customer is responsible for paying all taxes, government charges, and reasonable expenses and attorneys fees TopSrc incurs collecting unpaid amounts. Charges are solely based on TopSrc Ad Serving Platform measurements, unless otherwise agreed to in writing. Nothing in these Terms or an Insertion Order may obligate TopSrc to do credit to any party.</p>
                            <p>8.3. Advertiser acknowledges and agrees that any credit card and related billing and payment information that Advertiser provides to TopSrc may be shared with companies who work on TopSrc&rsquo;s behalf, such as payment processors and/or credit agencies, solely for the purposes of checking credit, effecting payment to TopSrc and servicing Advertiser&rsquo;s account.</p>
                            <p>8.4. TopSrc reserves the right to withhold deposit or charge Advertiser&rsquo;s account due to any breach of this Agreement by Advertiser.</p>

                            <p><b>NINE.- ADVERTISER REFUND POLICY.</b></p>
                            <p>TopSrc strives to offer the best service possible to its clients. Once an Advertiser makes an initial deposit in the TopSrc Ad Serving Platform, Advertiser has six (6) months from the last payment date to ask for a refund of the balance remaining on the account if he isn&rsquo;t satisfied with the TopSrc Network and have remained in compliance with this Agreement. As soon as an Advertiser makes a second deposit in the TopSrc Ad Serving Platform, it is hereby understood that a refund will only be issued for a balance greater than $200 and a processing fee of 10% will be deducted from the refund. Advertisers canceled / terminated by TopSrc for violating these Terms are not entitled to a refund.</p>

                            <p><b>TEN.- AD SIZES.</b></p>
                            <p>300&times;250 (pixels), 315&times;300, 338&times;235, 250&times;250, 468&times;60, 728&times;90, 945&times;100, 120&times;600, 160&times;600, 180&times;1030, 240&times;480, 200&times;150, Pop-unders (full page), Interstitials (full page), text ads (with 10&times;10 thumb).&nbsp;</p>

                            <p><b>ELEVEN.- PUBLICITY.</b></p>
                            <p>TopSrc is hereby authorized to use the trade names or trademarks of Publishers and Advertisers for the purposes of this Agreement without any further written approval from the party owning such name or trademark.</p>
                            <p><b>TWELVE.- REPRESENTATIONS AND WARRANTIES.</b></p>
                            <p>12.1. Publisher(s) represents and warrants to TopSrc that:</p>
                            <ul>
                                <li>All content, products, and services on the Publisher&acute;s Website(s) are legal to distribute, that it owns or has the legal right to use, and will not infringe, any and all copyrights, trademarks, patents or other proprietary rights;</li>
                                <li>The Publisher&acute;s Website(s) do not, and will not during the term of this Agreement, contain any material described in Section 4 of this Agreement;</li>
                                <li>The Publisher&acute;s Website(s) are free of any &ldquo;worm&rdquo;, &ldquo;virus&rdquo;, &ldquo;malware&rdquo; or other device that could impair or injure any person or entity;</li>
                                <li>It is generally familiar with the nature of the Internet and will comply with all rules and regulations that may apply; and it will conduct its business in compliance with all applicable laws, rules and regulations;</li>
                                <li>It has full legal power and authority under its organizational documents to enter into this Agreement and to perform the obligations contained herein; and the execution of this Agreement and the performance of its obligations by Publisher(s) will not conflict with or cause a breach or violation of any agreement, law, regulation or other obligation to which Publisher(s) is a party or subject to; and</li>
                                <li>The Publisher(s) must respect the prohibition of unsolicited advertisement (&ldquo;Spam&rdquo;) when sending Advertising Material via email or via any other equivalent electronic communications means. Therefore, the consent of each and every recipient is to be obtained prior to sending e-mails or electronic communications; should TopSrc so request, Publisher(s) must provide written evidence of such consent has been granted.</li>
                            </ul>
                            <p>12.2. Advertiser(s) represents and warrants to TopSrc that none of the advertising provided contains:</p>
                            <ul>
                            <li>Any material that consists of paraphilia or scatological activities;</li>
                            <li>Any material that contain children or minors in adult or sexual situations;</li>
                            <li>Any material that offers illegal products or services;</li>
                            <li>Promotion of incentives for online activity to surf websites, click on ads, or any activity that artificially enhances website or advertiser metrics;</li>
                            <li>Promotion of violence, racial intolerance, or advocacy against any individual, group, or organization;</li>
                            <li>Promotion of fake documents, copied material, or paper mills;</li>
                            <li>Any unauthorized use of third party trademarks that either creates a likelihood of confusion that consumers will believe the products or services originated from the trademark owner, or is likely to dilute the value of a known trademark;</li>
                            <li>Promotion of drugs or any related paraphernalia;</li>
                            <li>Sales or offers of certain weapons, alcohol, tobacco or any related paraphernalia. Advertisements for electronic cigarettes are permitted but cannot contain tobacco;</li>
                            <li>Promotion or any attempt to profit from human tragedy or suffering;</li>
                            <li>Promotion of illegal activities that infringes on the rights of others, including intellectual property rights;</li>
                            <li>Promotion of gambling or online betting that allows U.S. registrations. Any gaming advertisement must be pre-approved, meet a minimum monthly budget requirement, and block U.S. registrations using geo-location and other advanced risk controls. Removing U.S. from the registration field is not considered sufficient. If you would like to advertise a gaming service, please contact us at advertising[at]TopSrc.com and detail your services process for blocking U.S. registrations, and provide the expected monthly budget and planned geo-targeting;</li>
                            <li>Promotion of gambling websites where applicable regulations forbid such promotions. Any Advertiser wishing to promote gambling website hereby warrants that he is legally entitled to promote such gambling sites and that he is fully entitled, pursuant to any applicable regulations, to do so through TopSrc Network and in the particular countries he wishes to advertise. Advertiser also warrants that TopSrc will incur in no liabilities in allowing Advertiser to promote the gambling websites. . Advertiser declares that he is in compliance and aware that certain jurisdictions forbid the promotion of gambling websites and require that gambling operators hold a license valid in such jurisdiction prior to allowing users connected from IP addresses belonging to such jurisdictions. Pre-approval received from TopSrc shall not be construed as TopSrc having checked Advertiser&rsquo;s compliance with the present clause and will not affect Advertiser&rsquo;s liability pursuant to its failure to comply with the representations and warranties set forth herein. Furthermore, Advertiser undertakes to ensure compliance with the present clause at all times.</li>
                            <li>Any content that targets to children of age 18 and younger; and</li>
                            <li>Any material that does not respect particular advertising rules added in the Administration Panel for specific Publisher&acute;s Website(s).</li>
                            </ul>

                            <p>Advertiser will indemnify and hold TopSrc harmless of any liabilities, losses or damages of any nature which are directly or indirectly derived from Advertiser&rsquo;s infringements of this Agreement.</p>
                            <p><b>THIRTEEN.- TERMINATION; CANCELLATION.</b></p>
                            <p>13.1. TopSrc may at any time, in its sole discretion, immediately terminate this Agreement, or cancel any Ad(s) with or without cause. TopSrc will make commercially reasonable efforts to notify Advertiser(s), Publisher(s) and Third Parties via e-mail of any such termination or cancellation within a reasonable period of time.</p>
                            <p>Advertiser(s) may cancel any Ads and/or terminate this Agreement with or without cause at any time by deactivating a campaign in the Administration Panel.</p>
                            <p>Publisher(s) may cancel or terminate this Agreement by removing the TopSrc Javascript Tag from the Publisher&acute;s Website(s).</p>
                            <p>12.3. If either party does not fulfill a material obligation defined in this Agreement, the other party has the right to terminate this Agreement sixty (60) days following written notice to the party in breach, provided that such material breach remains uncured, without prejudice of the right to claim the damages caused to the non-breaching party.</p>

                            <p><b>FOURTEEN.- CONFIDENTIALITY.</b></p>
                            <p>14.1. You agree not to disclose TopSrc Confidential Information without TopSrc&rsquo;s prior written consent. &ldquo;Network Confidential Information&rdquo; includes without limitation: (i) all Network software, technology, programming, technical specifications, materials, guidelines and documentation You learn, develop or obtain that relate to the TopSrc Network; (ii) click-through rates or other statistics provided to You by TopSrc; and (iii) any other information designated in writing by TopSrc as &ldquo;confidential&rdquo; or any designation to the same effect. TopSrc Network Confidential Information does not include information that has become publicly known through no breach by You or TopSrc, or information that has been (i) independently developed without access to TopSrc Network Confidential Information, as evidenced in writing; (ii) rightfully received by You from a third party; or (iii) required to be disclosed by law or by a governmental authority.</p>
                            <p>14.2. Publisher acknowledges that TopSrc might be ordered by a Court or Administrative Authority to disclose information regarding the services being provided to the Publisher or to disclose Publisher&rsquo;s identity under certain circumstances and specially where Publisher&rsquo;s Website(s) contain(s) or link(s) to unauthorized copyrighted materials from third parties. TopSrc will be fully entitled to disclose such information upon receiving a request for disclosure from a Court or Administrative Authority which TopSrc reasonably deems as being competent to issue such a request.</p>

                            <p><b>FIFTEEN.- DATA PROTECTION.</b></p>
                            <p>15.1. Each party shall include conspicuously on its website(s), a privacy policy that describes how such party collects, uses, stores and discloses users&rsquo; personal data if any is collected, including without limitation e-mail addresses, and instructs users how to opt-out of such practices.</p>
                            <p>15.2. TopSrc has a Privacy Policy in its website which explains the use we make of personal data that you provide to us or that we gather from you and the measures we take to protect your privacy. The Privacy Policy also details how you may request that we amend or delete your personal data from our records as well as how to request that we cease all contact with you. Please read the Privacy Policy carefully as once you use our services you will be regarded as having read and accepted its terms.</p>
                            <p>15.3. In accordance with TopSrc&rsquo;s Privacy Policy, TopSrc may transfer your personal data to providers who require access to your personal data in order to provide the services that TopSrc has hired from them, and with whom TopSrc has subscribed confidentiality and data processing agreements that are necessary and mandatory by the privacy protection regulation. Some of the technology service providers hired by TopSrc are located in countries that do not have a data protection regulation equivalent to the European (&ldquo;Third Countries&rdquo;). These service providers have signed the confidentiality and data processing agreements required by the regulation, which apply the warranties and safeguards needed to preserve your privacy. For further information regarding warranties to your privacy, you may contact TopSrc at the electronic or postal addresses indicated in the Privacy Policy.</p>
                            <p>15.4. If TopSrc processes any personal data of end users in EEA (&ldquo;End Users&rdquo;) in connection with the provision of its services, the&nbsp;Data Processing Agreement&nbsp;(&ldquo;DPA&rdquo;) is applicable to such processing. In addition, TopSrc services may involve cross-border data exchanges with clients located in countries outside the EEA. In which case, when these countries do not benefit from an adequacy decision under the Article 45 of the GDPR, the parties agree to use a variety of measures to ensure that End Users personal data transferred to these countries receives adequate protection in accordance with the General Data Protection Regulation (&ldquo;GDPR&rdquo;), such as signing the Standard Contractual Clauses adopted by the European Commission.</p>
                            <p>15.5. Publisher&rsquo;s privacy policy available to End Users shall disclose that third party advertisers may place cookies on the browsers of visitors to Publisher&rsquo;s Website(s). In accordance to EU Directive 2002/58/EC as amended by Directive 2009/136/EC, Publishers must provide End Users with clear and comprehensive information regarding any devices (such as cookies or local shared objects) in use at their websites for storing information in the User&rsquo;s terminal equipment or retrieving already stored information from the said terminal equipment.&nbsp;</p>
                            <p>Publishers must also implement an opt-in system which ensures that the prior and informed consent is obtained from End Users in the European Union before any such devices are used or installed in the End Users&rsquo; terminal equipment. Therefore, Publishers must&nbsp;allow End Users to express their choice by a clear positive act as well as to modify it with the same simplicity.</p>
                            <p>TopSrc makes the cookies information available to the Publisher in its Privacy Policy, shall the Publisher be obliged to disclose detailed information on Publisher Website(s) regarding the cookies being placed by TopSrc on the browsers of visitors to Publisher&rsquo;s Website(s). For instance, a link to the TopSrc&rsquo;s Privacy Policy, allowing End Users to learn more and object to TopSrc services.</p>
                            <p>TopSrc reserves the right to audit Publisher&rsquo;s consent collection mechanisms to ensure compliance with the applicable data protection regulations. Such audits will involve a review of relevant consent forms and documentation, with proper advance notice, no more than once a year, unless significant compliance concerns arise. Publisher agrees to provide necessary cooperation and access.&nbsp; Furthermore, Publishers must also provide TopSrc, when applicable, with proof of consent upon request so that TopSrc can rely on it at any time.</p>
                            <p>15.6. Each party warrants to the other that, during the term of this Agreement, and the DPA, it shall comply with all applicable rules and regulations (including but not limited to laws governing privacy, and data protection).</p>

                            <p><b>SIXTEEN.- INDEMNIFICATION. LIMITATION OF LIABILITY.</b></p>
                            <p>16.1. Indemnification. You agree to indemnify, defend and hold TopSrc and its officers, directors, shareholders, successors, affiliates, employees, agents and representatives harmless from and against any and all costs, claims, demands, liabilities, expenses, losses, damages and attorney fees arising from any claims and lawsuits or proceeding for libel, slander, copyright, and trademark violation as well as all other claims resulting from (i) the participation on the TopSrc Network, (ii) operation of the Publisher&rsquo;s Website(s) submitted to TopSrc for participation on the TopSrc Network or (iii) otherwise arising from a relationship with TopSrc. You also agree to indemnify TopSrc for any legal fees incurred by TopSrc, acting reasonably, in investigating or enforcing its rights under this Agreement.</p>
                            <p>16.2. Limitation of Liability. UNDER NO CIRCUMSTANCES WILL TopSrc BE LIABLE TO PUBLISHER WITH RESPECT TO ANY SUBJECT MATTER OF THESE TERMS AND CONDITIONS UNDER CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHER LEGAL OR EQUITABLE THEORY, WHETHER OR NOT TopSrc HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, FOR INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES ARISING FROM ANY PROVISION OF THESE TERMS, INCLUDING, BUT NOT LIMITED TO, LOSS OF REVENUE OR ANTICIPATED PROFITS OR LOST BUSINESS. THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE. IN NO EVENT SHALL TopSrc&rsquo;S AGGREGATE LIABILITY ARISING OUT OF THESE TERMS AND CONDITIONS EXCEED THE LAST MONTH&rsquo;S PAYMENTS TO THE PUBLISHER.</p>

                            <p><b>SEVENTEEN.- MODIFICATION.</b></p>
                            <p>17.1. TopSrc reserves the right to amend the provisions of the present Agreement that are minor in scope or nature, and to do so without citing any reasons, provided such modifications do not lead to the Agreement as a whole being restructured. TopSrc will communicate, by e-mail or electronic means or through the Administration Panel, the modified conditions at least two weeks prior to the Effective Date. Publishers who do not object in writing to the modification within four weeks after the receipt of the communication will be deemed to have accepted the respective modification. TopSrc will specifically indicate the possibility of objecting to the modification and the consequences of the four-week deadline.</p>
                            <p>17.2. If the Publisher(s) objects to the new (modified) Terms, TopSrc&acute;s request to so modify them will be deemed to have been rejected. The Agreement will then be continued without the proposed modification. The right of the parties to terminate their participation on the TopSrc Network remains unaffected hereby. The possibility of terminating the Agreement will also be indicated specifically.</p>

                            <p><b>EIGHTTEEN.- NON-SOLICITATION.</b></p>
                            <p>During the term of this Agreement, and any renewal thereof, and for one (1) year after its termination for any reason, Advertiser agrees that it will not do business directly or indirectly with any Publisher listed on the TopSrc Network, or directly or indirectly solicit or induce such Publisher to do business directly with the Advertiser. Advertiser understands and agrees that this prohibition is a key consideration and inducement for TopSrc to enter into this Agreement with Advertiser, and to provide the services hereunder.</p>

                            <p><b>NINETEEN.&ndash; DISPUTE RESOLUTION.</b></p>
                            <p>19.1. This Agreement shall be governed by and interpreted in accordance with the laws of Hong Kong.</p>
                            <p>19.2. For any matter related to the interpretation or execution of this Agreement, the parties expressly waive to submit to any courts which might have jurisdiction over the subject matter, and agree to submit to the sole competence and jurisdiction of the Courts of the City of Hong Kong.</p>

                            <p><b>TWENTY.- GENERAL PROVISIONS.</b></p>
                            <p>20.1. Force Majeure. Except for payment obligations, if either party is prevented from performing or is unable to perform any of its obligations under this Agreement due to causes beyond the reasonable control of the party invoking this provision, including but not limited to acts of God, acts of civil or military authorities, riots or civil disobedience, wars, strikes or labor disputes (each, a &ldquo;Force Majeure Event&rdquo;), such party&rsquo;s performance shall be excused and the time for performance shall be extended accordingly provided that the party immediately takes all reasonably necessary steps to resume full performance. If such party remains unable to resume full performance fifteen (15) days after the Force Majeure Event, the other party may terminate this Agreement upon written notice.</p>
                            <p>20.2. Severability. Should any of the provisions of this Agreement be adjudged invalid or unenforceable by the rules and regulations of Hong Kong or a Hong Kongese court, such provisions shall be deemed several from the remainder of this Agreement and not affect the validity or enforceability of the remainder of this Agreement. In that case, such provisions shall be changed and interpreted to achieve the purposes of those provisions as much as possible within the extent of relevant laws or judgment of the court.</p>
                            <p>20.3. Survival. Sections 13, 14, 15, 18 and 19 shall survive termination or expiration of this Agreement for any reason. All other rights and obligations of the parties under this Agreement shall expire upon termination of this Agreement, except that all payment obligations accrued hereunder prior to termination or expiration shall survive such termination.</p>
                            <p>20.4. Assignment. TopSrc is hereby authorized to assign, sublicense, delegate or otherwise transfer any of its rights or obligations under this Agreement without the prior written consent of the other partyprovided that the assignee shall assume all rights and obligations under this Agreement</p>
                            <p>Publisher/Advertiser shall not assign, sublicense, delegate or otherwise transfer any of its rights or obligations. However, Publisher/Advertiser may, without the consent of TopSrc, assign this Agreement to an entity merging with, consolidating with, or purchasing substantially all its assets or stock, provided that the assignee shall assume in writing all rights and obligations under this Agreement.</p>
                            <p>20.5. Notices. All notices and other communications hereunder shall be in writing and shall be deemed to have been duly given when delivered in person (including by internationally recognized commercial delivery service), and on the day the notice is sent when sent by verified facsimile or email with confirmation receipt, if the time of transmission is during recipient&rsquo;s business day, or if not on the next business day thereafter, in each case to the respective parties at the postal or email addresses provided by the them in writing.</p>
                            <p>Either party may change its address by providing the other party with written notice of the change in accordance with this section.</p>
                            <p>20.6. Relationship of Parties. The parties are independent contractors and will have no right to assume or create any obligation or responsibility on behalf of the other party. Neither party shall hold itself out as an agent of the other party. This Agreement will not be construed to create or imply any partnership, agency, joint venture or formal business entity of any kind.</p>
                            <p>20.7. Waiver. No delay or failure by either party to exercise any right or remedy under this Agreement will constitute a waiver of such right or remedy. All waivers must be in writing and signed by an authorized representative of the party waiving its rights. A waiver by any party of any breach or covenant shall not be construed as a waiver of any succeeding breach of any other covenant.</p>
                            <p>20.8. Entire Agreement. This Agreement constitutes the entire agreement between the parties and supersedes all previous agreements, oral or written, with respect to the subject matter of this Agreement. The information and documents provided by Publisher(s) and Advertiser(s) to TopSrc, as requested by the latest in order to enter the Agreement, shall be also considered as part of this Agreement. This Agreement may not be amended without the written consent of the parties.</p>
                            <p>20.9. Headings. The headings of the articles and paragraphs contained in this Agreement are inserted for convenience and are not intended to be part of or to affect the interpretation of this Agreement.</p>
                            <p>20.10. Construction. The parties acknowledge and agree that the Agreement has been jointly prepared and its provisions will not be construed more strictly against either party as a result of its participation in such preparation.</p>
                            <p>20.11. Counterparts. This Agreement may be executed in counterparts or online, which taken together shall form one legal instrument.</p>
                            <p>20.12. No Third Party Beneficiaries. This Agreement shall be binding upon and inure solely to the benefit of the parties hereto and their permitted assigns and nothing herein, express or implied, is intended to or shall confer upon any other person any legal or equitable right, benefit or remedy of any nature whatsoever under or by reason of this Agreement.</p>

                        </div>
                    </div>
                </div>
                <div id={"footerOverlay"}></div>
                <Footer/>
            </DocumentMeta>
        )

    }

}
