import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {Tween} from 'react-gsap';
import {gsap} from "gsap";

const MouseFollowStyled = styled.div`
`;

const Circle = styled.div`
  position: fixed;
  background-color: #adf200;
  width: 72px;
  height: 72px;
  left:-40px;
  top:-40px;
  z-index: 1;
  user-select: none;
  pointer-events: none;
  z-index: 10000; 
  transform: scale(.1875);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
`;

class MouseFollowComponent extends PureComponent {

    state = {
        x: window.innerWidth/2,
        y: window.innerHeight/2,
        scaleCircle: .1875,
        reload: false
    }

    tween;

    constructor(props) {
        super(props);
        this.mouseMove = this.mouseMove.bind(this);
        this.hoverFunc = this.hoverFunc.bind(this);
        this.unhoverFunc = this.unhoverFunc.bind(this);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }

    forceUpdateHandler(){

        this.unhoverFunc();
        this.componentDidMount();

    };

    componentDidMount() {

        window.addEventListener('mousemove', this.mouseMove);

        let hoverables = document.querySelectorAll('a, .hoverable, button, input');
        for (let i=0; i < hoverables.length; i++){
            if(!hoverables[i].classList.contains('unhoverable')){
                hoverables[i].addEventListener('mouseenter', this.hoverFunc);
                hoverables[i].addEventListener('mouseleave', this.unhoverFunc);
            }
        }

    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.mouseMove);
    }

    mouseMove({ clientX: x, clientY: y }) {
        this.setState((state) => {
            return {x, y};
        });
    }

    hoverFunc(event){

        gsap.to('#circleCursor', .5, {scale:0});

    }

    unhoverFunc(event){

        gsap.to('#circleCursor', .5, {scale:.1875});

    }

    render() {
        const { x, y } = this.state;

        return(
            <MouseFollowStyled className="circleCursor">
                <Tween to={{x: x, y: y}} duration={0.1} ref={ref => this.tween = ref}>
                    <Circle id="circleCursor" className={'blue ' + this.props.update ? 'test' : 'test2'}></Circle>
                </Tween>
            </MouseFollowStyled>
        );
    }
}

export default MouseFollowComponent;