const ArrowDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g fill="none" fillRule="evenodd">
        <g stroke="#FFF" strokeWidth="3">
          <g>
            <g>
              <path d="M1 1L26 26M7 26L26 26 26 7" transform="translate(-1352 -726) translate(60 724) translate(1292 2)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ArrowDown;