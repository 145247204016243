const MagnetHearts = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="225" height="258" viewBox="0 0 225 258">
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g id={'MagnetHeart1'} transform="translate(-248 -2063) translate(138 2027) translate(72) translate(45 43) translate(0 19)">
                                    <circle cx="45" cy="45" r="48.5" fill="#FFF" stroke="#000" stroke-opacity=".05" stroke-width="7"/>
                                    <path fill="#F44174" d="M45 28.628c-6.177-6.177-16.19-6.173-22.368.004-6.176 6.177-6.176 16.192 0 22.37L45 73.37 67.368 51c6.176-6.177 6.176-16.192 0-22.369-6.177-6.177-16.192-6.181-22.368-.004z"/>
                                </g>
                                <g id={'MagnetHeart2'} transform="translate(-248 -2063) translate(138 2027) translate(72) translate(45 43) translate(121 19)">
                                    <circle cx="45" cy="45" r="48.5" fill="#FFF" stroke="#000" stroke-opacity=".05" stroke-width="7"/>
                                    <path fill="#F44174" d="M45 28.628c-6.177-6.177-16.19-6.173-22.368.004-6.176 6.177-6.176 16.192 0 22.37L45 73.37 67.368 51c6.176-6.177 6.176-16.192 0-22.369-6.177-6.177-16.192-6.181-22.368-.004z"/>
                                </g>
                                <g id={'MagnetHeart3'} transform="translate(-248 -2063) translate(138 2027) translate(72) translate(45 43) translate(60)">
                                    <circle cx="45" cy="45" r="48.5" fill="#FFF" stroke="#000" stroke-opacity=".05" stroke-width="7"/>
                                    <path fill="#F44174" d="M45 28.628c-6.177-6.177-16.19-6.173-22.368.004-6.176 6.177-6.176 16.192 0 22.37L45 73.37 67.368 51c6.176-6.177 6.176-16.192 0-22.369-6.177-6.177-16.192-6.181-22.368-.004z"/>
                                </g>
                                <g id={'MagnetIcon'} stroke="#90A2AB" stroke-width="5">
                                    <path id={'MagnetP1'} d="M27.5 2.5V41c0 4.748 1.968 9.061 5.128 12.22 3.185 3.186 7.58 5.19 12.347 5.28H46c4.735 0 8.755-1.941 11.654-5.047 2.965-3.177 4.753-7.601 4.846-12.428h0V2.5h25V43c0 11.736-4.757 22.361-12.448 30.052C67.362 80.743 56.736 85.5 45 85.5s-22.361-4.757-30.052-12.448C7.257 65.362 2.5 54.736 2.5 43h0V2.5h25z" transform="translate(-248 -2063) translate(138 2027) translate(72) translate(45 43) translate(60 163)"/>
                                    <path id={'MagnetP2'} d="M2.5 2.5H27.5V27.5H2.5zM62.5 2.5H87.5V27.5H62.5z" transform="translate(-248 -2063) translate(138 2027) translate(72) translate(45 43) translate(60 163)"/>
                                </g>
                                <path id={'MagnetFlash1'} fill="#F70" fill-rule="nonzero" d="M61.071 131.429L65.446 131.429 65.446 143.563 75.071 126.763 70.696 126.763 70.696 115.563z" transform="translate(-248 -2063) translate(138 2027) translate(72) translate(45 43) scale(-1 1) rotate(24 0 -190.688)"/>
                                <path id={'MagnetFlash2'} fill="#F70" fill-rule="nonzero" d="M94 132.438L101.188 132.438 101.188 151.125 117 125.25 109.813 125.25 109.813 108z" transform="translate(-248 -2063) translate(138 2027) translate(72) translate(45 43)"/>
                                <path id={'MagnetFlash3'} fill="#F70" fill-rule="nonzero" d="M131.071 131.429L135.446 131.429 135.446 143.563 145.071 126.763 140.696 126.763 140.696 115.563z" transform="translate(-248 -2063) translate(138 2027) translate(72) translate(45 43) rotate(24 138.071 129.563)"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default MagnetHearts;