export const TECH = [
  {
    title: 'Bidding multiplier',
    text: 'Automatically adjusts bids for specific sources to win impressions.',
  },
  {
    title: 'Exposure time multiplier',
    text: 'Exponentially lowers the bid for each following impression from the same user. the more often a user sees the creative, the less media buyer pays for impressions.',
  },
  {
    title: 'Bidding autopilot',
    text: 'Automates performance optimization kpis with a system of bidding rules to avoid manual management and everyday optimization.',
  },
  {
    title: 'Recency cap',
    text: 'Sets the amount of time between several ads being served to the same user.',
  },
  {
    title: 'Retargeting',
    text: 'Topsrc pixel collects and segments users who interacted with your site.  afterward, these audiences are used for targeting.',
  },
]