import React, {Component} from 'react';
import { NavLink } from 'react-router-dom'
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SmoothScrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";
import DocumentMeta from "react-document-meta";
import Scrollbar from 'react-smooth-scrollbar';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from "react-input-mask";

//Data
import COUNTRIES from '../data/countries';
import COUNTRIES_ISO_1366 from '../data/countries_iso_1366';

//Components
import {Footer} from "../component/Footer";
import Arrow from '../component/Arrow';
import SelectArrow from '../component/SelectArrow';
import Asterisk from '../component/Asterisk';
import MessageSuccess from "../component/MessageSuccess";

export class Registration extends Component {

    constructor(props) {

        super(props);

        this.state = {
            //Focused
            firstNameFocused: false,
            lastNameFocused: false,
            emailFocused: false,
            passwordFocused: false,
            confirmPasswordFocused: false,
            companyFocused: false,
            countryFocused: false,
            addressFocused: false,
            phoneFocused: false,
            skypeFocused: false,
            additionalEmailFocused: false,
            //Filled
            firstNameFilled: false,
            lastNameFilled: false,
            emailFilled: false,
            passwordFilled: false,
            confirmPasswordFilled: false,
            companyFilled: false,
            countryFilled: false,
            addressFilled: false,
            phoneFilled: false,
            skypeFilled: false,
            additionalEmailFilled: false,
            //Inputs
            inputFirstNameValue: '',
            inputLastNameValue: '',
            inputEmailValue: '',
            inputPasswordValue: '',
            inputConfirmPasswordValue: '',
            inputCompanyValue: '',
            inputCountryValue: '',
            inputAddressValue: '',
            inputPhoneValue: '',
            inputSkypeValue: '',
            inputAdditionalEmailValue: '',
            inputTermsAndConditionsValue: false,
            //Errors
            firstNameError: false,
            lastNameError: false,
            emailError: false,
            passwordError: false,
            confirmPasswordError: false,
            companyError: false,
            countryError: false,
            addressError: false,
            phoneError: false,
            skypeError: false,
            additionalEmailError: false,
            termsAndConditionsError: false,
            //Countries
            isCountriesListVisible: false,
            success: false,
            captchaToken: false
        };

        this.countriesRef = React.createRef();

        // reference to the DOM node
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        //Add SmoothScrollbar to dropdown list
        //this.SmoothScrollbarCountriesList = false;
    }

    /**
     * Does user clicked outside ref`s box?
     */
    handleClickOutside(event) {

        if (this.countriesRef && !this.countriesRef.current.contains(event.target)) {

            this.setState({
                isCountriesListVisible: false,
            })

        }

    }

    loadPage(){

        let tl = gsap.timeline();

        tl.fromTo('#registrationPage .formWrapper',{y: 120, opacity: 0}, {y: 0, opacity: 1, duration: 1}, 0);

    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validate = async () => {

        const newErrors = {}

        if (!this.state.inputFirstNameValue) {
            newErrors.firstName = 'Required';
            this.setState({
                firstNameError: true,
            });
        } else {
            this.setState({
                firstNameError: false,
            });
        }

        if (!this.state.inputLastNameValue) {
            newErrors.lastName = 'Required';
            this.setState({
                lastNameError: true,
            });
        } else {
            this.setState({
                lastNameError: false,
            });
        }

        if (!this.state.inputEmailValue) {
            newErrors.email = 'Required';
            this.setState({
                emailError: true,
            });

        } else {

            let validateEmailStatus = this.validateEmail(this.state.inputEmailValue);

            if(validateEmailStatus){

                this.setState({
                    emailError: false,
                });

            } else {

                newErrors.email = 'Required';
                this.setState({
                    emailError: true,
                });

            }

        }

        if (!this.state.inputPasswordValue) {
            newErrors.password = 'Required';
            this.setState({
                passwordError: true,
            });
        } else {
            this.setState({
                passwordError: false,
            });
        }

        if (!this.state.inputConfirmPasswordValue) {

            newErrors.confirmPassword = 'Required';
            this.setState({
                confirmPasswordError: true,
            });

        } else {

            //Comparing Password & Confirmed Password fields
            if(this.state.inputConfirmPasswordValue == this.state.inputPasswordValue){

                this.setState({
                    confirmPasswordError: false,
                });

            } else {

                this.setState({
                    confirmPasswordError: false,
                });

            }

        }

        if (!this.state.inputCompanyValue) {
            newErrors.company = 'Required';
            this.setState({
                companyError: true,
            });
        } else {
            this.setState({
                companyError: false,
            });
        }

        if (!this.state.inputCountryValue) {
            newErrors.country = 'Required';
            this.setState({
                countryError: true,
            });
        } else {
            this.setState({
                countryError: false,
            });
        }

        if (!this.state.inputAddressValue) {
            newErrors.address = 'Required';
            this.setState({
                addressError: true,
            });
        } else {
            this.setState({
                addressError: false,
            });
        }

        if (!this.state.inputPhoneValue) {
            newErrors.phone = 'Required';
            this.setState({
                phoneError: true,
            });
        } else {
            this.setState({
                phoneError: false,
            });
        }

        if (!this.state.inputSkypeValue) {
            newErrors.skype = 'Required';
            this.setState({
                skypeError: true,
            });
        } else {
            this.setState({
                skypeError: false,
            });
        }

        if (!this.state.inputTermsAndConditionsValue) {
            newErrors.termsAndConditions = 'Required';
            this.setState({
                termsAndConditionsError: true,
            });
        } else {
            this.setState({
                termsAndConditionsError: false,
            });
        }

        if (this.state.inputAdditionalEmailValue) {

            let validateAdditionalEmailStatus = this.validateEmail(this.state.inputAdditionalEmailValue);

            if(validateAdditionalEmailStatus){

                this.setState({
                    additionalEmailError: false,
                });

            } else {

                newErrors.additionalEmail = 'Required';
                this.setState({
                    additionalEmailError: true,
                });

            }

        }

        return newErrors;

    }

    handleSubmit = async (e) => {

        e.preventDefault();

        const errors = await this.validate();
        const myThis = this;

        // Success
        if (!Object.keys(errors).length > 0) {

            const firstNameValue = this.state.inputFirstNameValue
            const lastNameValue = this.state.inputLastNameValue
            const emailValue = this.state.inputEmailValue
            const passwordValue = this.state.inputPasswordValue
            const companyValue = this.state.inputCompanyValue
            const countryValue = this.state.inputCountryValue
            const addressValue = this.state.inputAddressValue
            const phoneValue = this.state.inputPhoneValue
            const skypeValue = this.state.inputSkypeValue
            const additionalEmailValue = this.state.inputAdditionalEmailValue
            const countryArr = COUNTRIES_ISO_1366.find(country => country.name === countryValue);

            const postParams = {
                firstName: firstNameValue,
                lastName: lastNameValue,
                email: emailValue,
                password: passwordValue,
                company: companyValue,
                country: countryArr["alpha-3"],
                address: addressValue,
                phone: phoneValue,
                skype: skypeValue,
                recaptchaResponse: this.state.captchaToken
            }

            if(additionalEmailValue !== ''){
                postParams.additionalEmail = additionalEmailValue;
            }

            await axios.post(`https://api.topsrc.com/user/register`, postParams).then(response => {

                console.log('response', response);

                document.removeEventListener('mousedown', this.handleClickOutside);

                myThis.setState({'success' : true});

                let tl = gsap.timeline(),
                    splitMessageTitle = new SplitText('.messageTitle span', {type: "words"}),
                    splitMessageText = new SplitText('.messageText span', {type: "lines"});

                tl.fromTo('.messageIcon svg',{opacity: 0, y: -120},{opacity: 1, y: 0, duration: 0.5}, 0);
                tl.from(splitMessageTitle.words,{duration: .5, y: 60, opacity: 0, stagger: 0.1}, 0);
                tl.from(splitMessageText.lines,{duration: .5, y: 120, opacity: 0, stagger: 0.2}, .5);

            }).catch(function (error) {

                if (error.response) {

                    const responseData = error.response.data.errors;

                    if(responseData.isArray){

                        myThis.setState({
                            error: 'Please, check corrects of all yours input fields.'
                        })

                        console.log('errors', responseData);

                    } else {

                        if(error.RECAPTCHA){

                            myThis.setState({
                                error: 'Captcha is wrong.'
                            })

                        } else {

                            myThis.setState({
                                error: 'Please, check corrects of your form.'
                            })

                        }

                        console.log('error', responseData);

                    }

                } else if (error.request) {

                    myThis.setState({
                        error: 'The request was made but no response was received'
                    })

                    // The request was made but no response was received
                    console.log(error.request);

                } else {

                    myThis.setState({
                        error: 'Something happened in setting up the request that triggered an Error'
                    })

                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);


                }

                ReCAPTCHA.reset();

                return false;

            });

        // Fail
        } else {

            ReCAPTCHA.reset();

            this.setState({
                error: 'Please, check corrects of your form.'
            })

        }

        // Success
        // if (!Object.keys(errors).length > 0) {
        //
        //     const firstNameValue = this.state.inputFirstNameValue
        //     const lastNameValue = this.state.inputLastNameValue
        //     const emailValue = this.state.inputEmailValue
        //     const passwordValue = this.state.inputPasswordValue
        //     const companyValue = this.state.inputCompanyValue
        //     const countryValue = this.state.inputCountryValue
        //     const addressValue = this.state.inputAddressValue
        //     const phoneValue = this.state.inputPhoneValue
        //     const skypeValue = this.state.inputSkypeValue
        //
        //     const data = new FormData();
        //     data.append('firstname', firstNameValue);
        //     data.append('lastname', lastNameValue);
        //     data.append('email', emailValue);
        //     data.append('password', passwordValue);
        //     data.append('company', companyValue);
        //     data.append('country', countryValue);
        //     data.append('address', addressValue);
        //     data.append('phone', phoneValue);
        //     data.append('skype', skypeValue);
        //
        //     await axios.post(`https://topsrc.com/submit.php`, data).then((response) => {
        //
        //         document.removeEventListener('mousedown', this.handleClickOutside);
        //
        //         this.setState({'success' : true});
        //
        //         let tl = gsap.timeline(),
        //             splitMessageTitle = new SplitText('.messageTitle span', {type: "words"}),
        //             splitMessageText = new SplitText('.messageText span', {type: "lines"});
        //
        //         tl.fromTo('.messageIcon svg',{opacity: 0, y: -120},{opacity: 1, y: 0, duration: 0.5}, 0);
        //         tl.from(splitMessageTitle.words,{duration: .5, y: 60, opacity: 0, stagger: 0.1}, 0);
        //         tl.from(splitMessageText.lines,{duration: .5, y: 120, opacity: 0, stagger: 0.2}, .5);
        //
        //         console.log('reg response', response);
        //
        //     });
        //
        // } else {
        //
        //     this.setState({
        //         error: 'Please, check your form!',
        //         success : false
        //     });
        //
        // }

    }

    componentDidMount() {

        document.addEventListener('mousedown', this.handleClickOutside);

        gsap.registerPlugin(ScrollTrigger);

        const myThis = this;

        if(!localStorage.getItem('wasLoaded')) {

            gsap.to('#registrationPage .formWrapper', {opacity: 0});

            let startInterval = setInterval(function (){

                if(!myThis.props.loading){

                    gsap.to('#registrationPage .formWrapper', {opacity: 1});

                    myThis.loadPage();

                    clearInterval(startInterval);

                }

            }, 100)

        } else {

            myThis.loadPage();

        }

        if(myThis.props.isMobile === false){

            // Scrollbar
            SmoothScrollbar.use(OverscrollPlugin);

            let damping = 0.05;

            let scrollPositionX = 0,
                scrollPositionY = 0,
                vLogo = document.querySelector('#vLogo'),
                footer = document.querySelector('footer'),
                scrollContainer = document.querySelector('.fadePage div'),
                ScrollbarOptions = {
                    damping: damping,
                    thumbMinSize: 10,
                    renderByPixels: true,
                    alwaysShowTracks: false,
                    continuousScrolling: true,
                    plugins: {
                        overscroll: {
                            effect: 'glow',
                            glowColor: '#ffffff'
                        },
                    },
                };

            let scrollBarRoot = SmoothScrollbar.init(scrollContainer, ScrollbarOptions);

            scrollBarRoot.addListener(({ offset }) => {
                scrollPositionX = offset.x;
                scrollPositionY = offset.y;
                vLogo.style.transform = 'translateY(-' + scrollPositionY + 'px)';
                footer.style.transform = myThis.props.isMobile ? 'unset' : 'translateY(' + scrollPositionY + 'px)';
            });

            scrollBarRoot.setPosition(0,0);
            scrollBarRoot.track.xAxis.element.remove();

            ScrollTrigger.scrollerProxy(scrollContainer, {
                scrollTop(value) {
                    if (arguments.length) {
                        scrollBarRoot.scrollTop = value;
                    }
                    return scrollBarRoot.scrollTop;
                }
            });

            scrollBarRoot.addListener(ScrollTrigger.update);

            scrollBarRoot.scrollTo(0, 0);

            //EOF. Smooth Scrollbar

            //PRAVI

            let deltaY,
                currentScroll = 0,
                menuOpened = false;

            scrollBarRoot.addListener((s) => {

                deltaY = -1

                if(currentScroll < s.offset.y){
                    deltaY = 1
                }

                currentScroll = s.offset.y;

                menuOpened = document.body.classList.contains('menuOpened') ? true : false;

                if(!menuOpened){

                    if(currentScroll > 0){

                        document.querySelector('header').classList.add('smalled');

                        if (deltaY < 0){
                            document.querySelector('header').classList.remove('collapsed');
                        } else {
                            document.querySelector('header').classList.add('collapsed');
                        }

                    } else {
                        document.querySelector('header').classList.remove('collapsed', 'smalled');
                    }

                }

            });

            //EOF. PRAVI

        }

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render() {

        const meta = {
            title: 'Registration',//data.content.title,
            description: '',
            canonical: '',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ''
                }
            }
        };

        /* Countries */

        const handleCountrySelect = (countryName) => {

            this.setState({
                inputCountryValue: countryName,
                isCountriesListVisible: false,
                countryFilled: true
            })

        }

        const filteredCountries = this.state.inputCountryValue ? COUNTRIES.filter(country => country.name.toLowerCase().includes(this.state.inputCountryValue.toLowerCase())) : COUNTRIES;

        /* EOF. Countries */

        const focusInput = (e) => {

            switch (e.target.name) {

                case 'first_name':

                    this.setState({
                        firstNameFocused: true,
                    })

                    break;

                case 'last_name':

                    this.setState({
                        lastNameFocused: true,
                    })

                    break;

                case 'email':

                    this.setState({
                        emailFocused: true,
                    })

                    break;

                case 'password':

                    this.setState({
                        passwordFocused: true,
                    })

                    break;

                case 'confirm_password':

                    this.setState({
                        confirmPasswordFocused: true,
                    })

                    break;

                case 'company':

                    this.setState({
                        companyFocused: true,
                    })

                    break;

                case 'country':

                    let myThis = this;

                    this.setState({
                        countryFocused: true,
                        isCountriesListVisible: true,
                    })

                    // setTimeout(function (){
                    //
                    //     myThis.SmoothScrollbarCountriesList = SmoothScrollbar.init(document.querySelector('.countriesList'));
                    //
                    // }, 0)

                    break;

                case 'address':

                    this.setState({
                        addressFocused: true,
                    })

                    break;

                case 'phone':

                    this.setState({
                        phoneFocused: true,
                    })

                    break;

                case 'skype':

                    this.setState({
                        skypeFocused: true,
                    })

                    break;

                case 'additional_email':

                    this.setState({
                        additionalEmailFocused: true,
                    })

                    break;

                default:

                    break;

            }

        };

        const unFocusInput = (e) => {

            switch (e.target.name){

                case 'first_name':

                    this.setState({
                        firstNameFocused: false,
                        firstNameFilled: this.state.inputFirstNameValue != '' ? true : false,
                    })

                    break;

                case 'last_name':

                    this.setState({
                        lastNameFocused: false,
                        lastNameFilled: this.state.inputLastNameValue != '' ? true : false,
                    })

                    break;

                case 'email':

                    this.setState({
                        emailFocused: false,
                        emailFilled: this.state.inputEmailValue != '' ? true : false,
                    })

                    break;

                case 'password':

                    this.setState({
                        passwordFocused: false,
                        passwordFilled: this.state.inputPasswordValue != '' ? true : false,
                    })

                    break;

                case 'confirm_password':

                    this.setState({
                        confirmPasswordFocused: false,
                        confirmPasswordFilled: this.state.inputConfirmPasswordValue != '' ? true : false,
                    })

                    break;

                case 'company':

                    this.setState({
                        companyFocused: false,
                        companyFilled: this.state.inputCompanyValue != '' ? true : false,
                    })

                    break;

                case 'country':

                    this.setState({
                        countryFocused: false,
                        countryFilled: this.state.inputCountryValue != '' ? true : false,
                        //isCountriesListVisible: true,
                    })

                    break;

                case 'address':

                    this.setState({
                        addressFocused: false,
                        addressFilled: this.state.inputAddressValue != '' ? true : false,
                    })

                    break;

                case 'phone':

                    this.setState({
                        phoneFocused: false,
                        phoneFilled: this.state.inputPhoneValue != '' ? true : false,
                    })

                    break;

                case 'skype':

                    this.setState({
                        skypeFocused: false,
                        skypeFilled: this.state.inputSkypeValue != '' ? true : false,
                    })

                    break;

                case 'additional_email':

                    this.setState({
                        additionalEmailFocused: false,
                        additionalEmailFilled: this.state.inputAdditionalEmailValue != '' ? true : false,
                    })

                    break;

                default:

                    break;

            }

        };

        const
            formFirstNameFilledClass = this.state.firstNameFocused || this.state.firstNameFilled ? ' formFilled' : '',
            formFirstNameRequiredClass = this.state.firstNameError ? ' required' : '',
            formLastNameFilledClass = this.state.lastNameFocused || this.state.lastNameFilled ? ' formFilled' : '',
            formLastNameRequiredClass = this.state.lastNameError ? ' required' : '',
            formEmailFilledClass = this.state.emailFocused || this.state.emailFilled ? ' formFilled' : '',
            formEmailRequiredClass = this.state.emailError ? ' required' : '',
            formPasswordFilledClass = this.state.passwordFocused || this.state.passwordFilled ? ' formFilled' : '',
            formPasswordRequiredClass = this.state.passwordError ? ' required' : '',
            formConfirmPasswordFilledClass = this.state.confirmPasswordFocused || this.state.confirmPasswordFilled ? ' formFilled' : '',
            formConfirmPasswordRequiredClass = this.state.confirmPasswordError ? ' required' : '',
            formCompanyFilledClass = this.state.companyFocused || this.state.companyFilled ? ' formFilled' : '',
            formCompanyRequiredClass = this.state.companyError ? ' required' : '',
            formCountryFilledClass = this.state.countryFocused || this.state.countryFilled ? ' formFilled' : '',
            formCountryRequiredClass = this.state.countryError ? ' required' : '',
            formAddressFilledClass = this.state.addressFocused || this.state.addressFilled ? ' formFilled' : '',
            formAddressRequiredClass = this.state.addressError ? ' required' : '',
            formPhoneFilledClass = this.state.phoneFocused || this.state.phoneFilled ? ' formFilled' : '',
            formPhoneRequiredClass = this.state.phoneError ? ' required' : '',
            formSkypeFilledClass = this.state.skypeFocused || this.state.skypeFilled ? ' formFilled' : '',
            formSkypeRequiredClass = this.state.skypeError ? ' required' : '',
            formAdditionalEmailFilledClass = this.state.additionalEmailFocused || this.state.additionalEmailFilled ? ' formFilled' : '',
            formAdditionalEmailRequiredClass = this.state.additionalEmailError ? ' required' : '',
            formTermAndConditionsRequiredClass = this.state.termsAndConditionsError ? ' required' : '',
            formPasswordMatchesClass = this.state.inputPasswordValue != this.state.inputConfirmPasswordValue ? ' mismatch' : '',
            pageSuccessClass = this.state.success ? ' success fullPage' : '';

        return(
            <DocumentMeta {...meta}>
                <div id={'registrationPage'} className={'page formPage' + pageSuccessClass}>
                    {!this.state.success ? (
                    <div className={'formWrapper'}>
                        <form id={'registrationForm'} className={'form'} onSubmit={this.handleSubmit} method={'POST'}>
                            <h1>Registration</h1>
                            <div className={'doubleForm'}>
                                <div className={'formControl' + formFirstNameFilledClass + formFirstNameRequiredClass}>
                                    <label className={'formLabel'}><span>First Name</span></label>
                                    <input name={'first_name'}
                                           type={'text'}
                                           value={this.state.inputFirstNameValue}
                                           onChange={(e) => (this.setState({'inputFirstNameValue' : e.target.value, 'firstNameError' : false}))}
                                           onFocus={focusInput}
                                           onBlur={unFocusInput} />
                                    <Asterisk/>
                                    <div className={'errorCorner'}></div>
                                </div>
                                <div className={'formControl' + formLastNameFilledClass + formLastNameRequiredClass}>
                                    <label className={'formLabel'}><span>Last Name</span></label>
                                    <input name={'last_name'}
                                           type={'text'}
                                           value={this.state.inputLastNameValue}
                                           onChange={(e) => (this.setState({'inputLastNameValue' : e.target.value, 'lastNameError' : false}))}
                                           onFocus={focusInput}
                                           onBlur={unFocusInput} />
                                    <Asterisk/>
                                    <div className={'errorCorner'}></div>
                                </div>
                            </div>
                            <div className={'formControl' + formEmailFilledClass + formEmailRequiredClass}>
                                <label className={'formLabel'}><span>Email</span></label>
                                <input name={'email'}
                                       type={'email'}
                                       value={this.state.inputEmailValue}
                                       onChange={(e) => (this.setState({'inputEmailValue' : e.target.value, 'emailError' : false}))}
                                       onFocus={focusInput}
                                       onBlur={unFocusInput} />
                                <Asterisk/>
                                <div className={'errorCorner'}></div>
                            </div>
                            <div className={'doubleForm'}>
                                <div className={'formControl' + formPasswordFilledClass + formPasswordRequiredClass}>
                                    <label className={'formLabel'}><span>Password</span></label>
                                    <input name={'password'}
                                           type="password"
                                           value={this.state.inputPasswordValue}
                                           autoComplete={'false'}
                                           onChange={(e) => (this.setState({'inputPasswordValue' : e.target.value, 'passwordError' : false}))}
                                           onFocus={focusInput}
                                           onBlur={unFocusInput} />
                                    <Asterisk/>
                                    <div className={'errorCorner'}></div>
                                </div>
                                <div className={'formControl' + formConfirmPasswordFilledClass + formConfirmPasswordRequiredClass + formPasswordMatchesClass}>
                                    <label className={'formLabel'}><span>Confirm Password</span></label>
                                    <input name={'confirm_password'}
                                           type="password"
                                           value={this.state.inputConfirmPasswordValue}
                                           autoComplete={'false'}
                                           onChange={(e) => (this.setState({'inputConfirmPasswordValue' : e.target.value, 'confirmPasswordError' : false}))}
                                           onFocus={focusInput}
                                           onBlur={unFocusInput} />
                                    <Asterisk/>
                                    <div className={'errorCorner'}></div>
                                </div>
                            </div>
                            <div className={'formControl' + formCompanyFilledClass + formCompanyRequiredClass}>
                                <label className={'formLabel'}><span>Company</span></label>
                                <input name={'company'}
                                       type={'text'}
                                       value={this.state.inputCompanyValue}
                                       onChange={(e) => (this.setState({'inputCompanyValue' : e.target.value, 'companyError' : false}))}
                                       onFocus={focusInput}
                                       onBlur={unFocusInput} />
                                <Asterisk/>
                                <div className={'errorCorner'}></div>
                            </div>
                            <div className={'formControl' + formCountryFilledClass + formCountryRequiredClass} ref={this.countriesRef}>
                                <label className={'formLabel'}><span>Country</span></label>
                                <input name={'country'}
                                       type={'text'}
                                       value={this.state.inputCountryValue}
                                       onChange={(e) => (this.setState({'inputCountryValue' : e.target.value, 'countryError' : false}))}
                                       onFocus={focusInput}
                                       onBlur={unFocusInput} />
                                <Asterisk/>
                                <SelectArrow/>
                                <div className={'errorCorner'}></div>
                                {this.state.isCountriesListVisible && (
                                    <Scrollbar id={'countriesList'}>
                                        <ul>
                                            {filteredCountries.map(country => (
                                                <li key={country.name} onClick={() => handleCountrySelect(country.name)}>
                                                    {`${country.flag} ${country.name}`}
                                                </li>
                                            ))}
                                        </ul>
                                    </Scrollbar>
                                )}
                            </div>
                            <div className={'formControl' + formAddressFilledClass + formAddressRequiredClass}>
                                <label className={'formLabel'}><span>Address</span></label>
                                <input name={'address'}
                                       type={'text'}
                                       value={this.state.inputAddressValue}
                                       onChange={(e) => (this.setState({'inputAddressValue' : e.target.value, 'addressError' : false}))}
                                       onFocus={focusInput}
                                       onBlur={unFocusInput} />
                                <Asterisk/>
                                <div className={'errorCorner'}></div>
                            </div>
                            <div className={'doubleForm'}>
                                <div className={'formControl' + formPhoneFilledClass + formPhoneRequiredClass}>
                                    <label className={'formLabel'}><span>Phone</span></label>
                                    <InputMask name={'phone'}
                                               mask="+999999999999"
                                               type={'text'}
                                               value={this.state.inputPhoneValue}
                                               onChange={(e) => (this.setState({'inputPhoneValue' : e.target.value, 'phoneError' : false}))}
                                               onFocus={focusInput}
                                               onBlur={unFocusInput} />
                                    <Asterisk/>
                                    <div className={'errorCorner'}></div>
                                </div>
                                <div className={'formControl' + formSkypeFilledClass + formSkypeRequiredClass}>
                                    <label className={'formLabel'}><span>Skype</span></label>
                                    <input name={'skype'}
                                           type={'text'}
                                           value={this.state.inputSkypeValue}
                                           onChange={(e) => (this.setState({'inputSkypeValue' : e.target.value, 'skypeError' : false}))}
                                           onFocus={focusInput}
                                           onBlur={unFocusInput} />
                                    <Asterisk/>
                                    <div className={'errorCorner'}></div>
                                </div>
                            </div>
                            <div className={'formControl' + formAdditionalEmailFilledClass + formAdditionalEmailRequiredClass}>
                                <label className={'formLabel'}><span>Additional Email</span></label>
                                <input name={'additional_email'}
                                       type={'text'}
                                       value={this.state.inputAdditionalEmailValue}
                                       onChange={(e) => (this.setState({'inputAdditionalEmailValue' : e.target.value, 'additionalEmailError' : false}))}
                                       onFocus={focusInput}
                                       onBlur={unFocusInput} />
                                <div className={'errorCorner'}></div>
                            </div>
                            <div className={'formControl' + formTermAndConditionsRequiredClass}>
                                <label className='checkboxControl hoverable' htmlFor={'termsAndConditions'}>
                                    <span className="checkMark">
                                        <input name={'termsAndConditions'}
                                               id={'termsAndConditions'}
                                               type="checkbox"
                                               value={this.state.inputTermsAndConditionsValue}
                                               checked={this.state.inputTermsAndConditionsValue}
                                               onChange={() => (this.setState({'inputTermsAndConditionsValue' : !this.state.inputTermsAndConditionsValue, 'termsAndConditionsError' : false}))}
                                        />
                                        <span className={'checkStatus'}></span>
                                    </span>
                                    <span className={'checkText'}>I agree to the <NavLink to={'/terms'}>Terms and conditions</NavLink></span>
                                </label>
                            </div>
                            <div id="reCaptcha">
                                <ReCAPTCHA
                                    sitekey="6LfJGUkUAAAAAMtXfYe_TtE0P0QEZN8eUJRl-Qkl"
                                    onChange={val => {
                                        this.setState({'captchaToken': val});
                                    }}
                                />
                            </div>

                            {this.state.error && <div className="error">{this.state.error}</div>}

                            <div className={'doubleForm'}>
                                <div className={'formControl'}>
                                    <NavLink className={'btn btnGray btnBorder'} to="/login">I already have account <Arrow/></NavLink>
                                </div>
                                <div className={'formControl'}>
                                    <button className={'btn btnGray'} type={'submit'}>Sign up <Arrow/></button>
                                </div>
                            </div>
                        </form>
                    </div>
                    ) : (
                        <div className={'message'}>
                            <div className={'messageIcon'}><MessageSuccess/></div>
                            <p className={'messageTitle'}><span>Registration Successful</span></p>
                            <p className={'messageText'}><span>Thank you for registering. Please check your email for a confirmation.</span></p>
                        </div>
                    )}
                </div>
                <div id={"footerOverlay"}></div>
                <Footer/>
            </DocumentMeta>
        )

    }

}
