import React, {Component} from "react";
import Dsps from "./cards/dsps";
import ArrowPink from "../images/arrow-02-red.svg";
import ArrowGreen from "../images/arrow-01-green.svg";
import ArrowFatGreen from "../images/fat-arrrow-green.svg";
import TopSrc from "../images/topsrc.svg";
import Ssps from "./cards/ssps";
import MagnetHearts from "./cards/magnetHearts";
import Target from "./cards/target";
import Invest from "./cards/invest";
import Slide1 from "../images/slides/nigel_williams.jpg";
import ReactPlayer from "react-player";
import Siema from 'siema';
import {gsap} from "gsap";
import {NavLink} from "react-router-dom";
import ArrowDark from "./ArrowDark";
import Arrow from "./Arrow";

export class MobileDSP extends Component  {

    constructor(props) {

        super(props);
        // reference to the DOM node

        this.siema = null;

        this.state = {
            currentSlideNum: 0,
            playing1: false,
            playing2: false,
            playing3: false,
        }

    }

    handlePlayPause = (e) => {

        e.preventDefault();

        const id = e.target.id;

        switch (id){

            case 'playVideo1':

                this.setState({ playing1: !this.state.playing1 })

                break;

            case 'playVideo2':

                this.setState({ playing2: !this.state.playing2 })

                break;

            case 'playVideo3':

                this.setState({ playing3: !this.state.playing3 })

                break;

            default:

                return false;

                break;
        }

        const tl = gsap.timeline();

        if(this.state.playing1 || this.state.playing2 || this.state.playing3){
            tl.to('.playName .playVideo .playBtn', {width: 128, height: 128, duration: 0}, 0)
                .to('.playName .playVideo', {width: 'auto', height: 'auto', duration: 0}, 0)
                .to('.playName', {height: 'auto', duration: 0}, 0)
                .to('.personaInfo', {display: 'block', duration: 0}, 0)
                .to('.playName', {opacity: 1, duration: .5}, 0.01)
        } else {
            tl.to('.playName', {opacity: 0, duration: .5}, 0)
                .to('.personaInfo', {display: 'none', duration: 0.01}, .5)
                .to('.playName', {height: '100%', duration: 0.01}, .5)
                .to('.playName .playVideo', {width: '100%', height: '100%', duration: 0.01}, .5)
                .to('.playName .playVideo .playBtn', {width: '100%', height: '100%', duration: 0.01}, .5)
        }

    }

    goto = (e) => {
        const num = e.target.getAttribute('num');
        this.siema.goTo(num);
    };

    prev = () => {
        this.siema.prev()
    };

    next = () => {
        this.siema.next()
    };

    componentDidMount() {

        const myThis = this;

        /* Siema */

        function printSlideIndex() {

            myThis.setState({currentSlideNum : this.currentSlide})

            myThis.setState({ playing1: false, playing2: false, playing3: false })

        }

        setTimeout(function (){

            myThis.siema = new Siema({
                selector: '#clientsCarousel',
                easing: 'ease-out',
                perPage: 1,
                startIndex: 0,
                draggable: true,
                loop: true,
                onChange: printSlideIndex,
                onInit: printSlideIndex,
            });

        }, 0);

    }

    render() {

        const { playing1, playing2, playing3 } = this.state

        return (
            <div id={'dspPage'} className={'page'}>
                <div className="infoBlocks">

                    <div id={'infoBlock1'} className={'infoBlock'}>
                        <div className={'infoColumn'}>
                            <div className={'infoContainer'}>
                                <div className={'infoDescription'}>
                                    <h2>topsrc is not just a performance dsp</h2>
                                    <p>it’s an ad exchange supplied by media buyers who want to generate extra revenue from their funnels. before sending the click to their cpa offers, they put it up for an auction on ssp.topsrc.com</p>
                                </div>
                            </div>
                        </div>
                        <div className={'infoColumn primaryBlue'}>
                            <div className={'infoContainer centerContainer'}>
                                <div id={'cardsWrapper1'} className={'cardsWrapper'}>
                                    <div id={'cardsRow11'} className={'cardsRow'}>
                                        <div className={'whiteCard'}>
                                            <div className={'cardIcon'}><Dsps /></div>
                                            <div className={'cardTitle'}><span>DSPs</span></div>
                                            <div className={'pinkArrow'}>
                                                <img src={ArrowPink} alt="Top" />
                                            </div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow12'} className={'cardsRow'}>
                                        <div className={'cardsLine lineGreen lineBottom'}>
                                            <img src={ArrowGreen} alt="Bottom" />
                                            <div className={'arrowTip'}>
                                                <span className={'arrowTipPoint'}></span>
                                                <span className={'arrowTipLine'}></span>
                                                <span className={'arrowTipWrapper'}>
                                                    <span className={'arrowTipText'}>Bid</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className={'cardsLine linePink lineTop'}>
                                            <img src={ArrowGreen} alt="Top" />
                                            <div className={'arrowTip'}>
                                                <span className={'arrowTipPoint'}></span>
                                                <span className={'arrowTipLine'}></span>
                                                <span className={'arrowTipWrapper'}>
                                                    <span className={'arrowTipText'}>Bid Request</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow13'} className={'cardsRow'}>
                                        <div className={'blackCard cardBlack'}>
                                            <div className={'pinkLineTop'}></div>
                                            <div className={'cardIcon'}>
                                                <img src={TopSrc} alt="TopSrc" />
                                            </div>
                                            <div className={'pinkLineBottom'}>
                                                <img src={ArrowPink} alt="Top" />
                                            </div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow14'} className={'cardsRow'}>
                                        <div className={'cardsLine lineGreen lineBottom'}>
                                            <img src={ArrowGreen} alt="Bottom" />
                                            <div className={'arrowTip'}>
                                                <span className={'arrowTipPoint'}></span>
                                                <span className={'arrowTipLine'}></span>
                                                <span className={'arrowTipWrapper'}>
                                                    <span className={'arrowTipText'}>AD Delivery</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className={'cardsLine linePink lineTop'}>
                                            <img src={ArrowGreen} alt="Top" />
                                            <div className={'arrowTip'}>
                                                <span className={'arrowTipPoint'}></span>
                                                <span className={'arrowTipLine'}></span>
                                                <span className={'arrowTipWrapper'}>
                                                    <span className={'arrowTipText'}>AD Request</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow15'} className={'cardsRow'}>
                                        <div className={'whiteCard'}>
                                            <div className={'pinkLine'}></div>
                                            <div className={'cardIcon'}><Ssps /></div>
                                            <div className={'cardTitle'}><span>SSPs</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id={'infoBlock2'} className={'infoBlock'}>
                        <div className={'infoColumn'}>
                            <div className={'infoContainer'}>
                                <div className={'infoDescription'}>
                                    <h2>why are we topsrc?</h2>
                                    <p>because we supply direct funnel traffic, warmed and engaged. apart from traditional members area banners and pops, we provide traffic from chats, tabs, push notifications and emails.</p>
                                </div>
                            </div>
                        </div>
                        <div className={'infoColumn primaryBlue'}>
                            <div className={'infoContainer centerContainer'}>
                                <div id={'cardsWrapper2'} className={'cardsWrapper'}>
                                    <div id={'cardsRow21'} className={'cardsRow'}>
                                        <div id={'card211'} className={'whiteCard'}>
                                            <div className={'cardIcon'}><MagnetHearts/></div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow22'} className={'cardsRow'}>
                                        <div className={'cardsLine lineGreen lineBottom'}>
                                            <img src={ArrowGreen} alt="Bottom" />
                                        </div>
                                    </div>
                                    <div id={'cardsRow23'} className={'cardsRow'}>
                                        <div className={'triangleCard'}>
                                            <ul>
                                                <li id={'triangleCardOne'}>
                                                    <span className={'triangleCardText'}>Awarness</span>
                                                </li>
                                                <li id={'triangleCardTwo'}>
                                                    <span className={'triangleArrow'}></span>
                                                    <span className={'triangleCardText'}>Interest</span>
                                                </li>
                                                <li id={'triangleCardThree'}>
                                                    <span className={'triangleArrow'}></span>
                                                    <span className={'triangleCardText'}>Decision</span>
                                                </li>
                                                <li id={'triangleCardFour'}>
                                                    <span className={'triangleArrow'}></span>
                                                    <span className={'triangleCardText'}>Action</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div id={'cardsRow24'} className={'cardsRow'}>
                                        <div className={'cardsLine lineGreen lineBottom'}>
                                            <img src={ArrowGreen} alt="Bottom" />
                                        </div>
                                    </div>
                                    <div id={'cardsRow25'} className={'cardsRow'}>
                                        <div className={'whiteCard'}>
                                            <div className={'cardIcon'}>
                                                <span id={'heartCircleOne'} className={'heartCircle'}></span>
                                                <span id={'heartCircleTwo'} className={'heartCircle'}></span>
                                                <Target />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id={'infoBlock3'} className={'infoBlock'}>
                        <div className={'infoColumn'}>
                            <div className={'infoContainer'}>
                                <div className={'infoDescription'}>
                                    <h2>our shit ain’t cheap tho</h2>
                                    <p>we’re only interested in bringing you traffic with the highest roi. such traffic can’t be cheap, can't be bad.</p>
                                </div>
                            </div>
                        </div>
                        <div className={'infoColumn primaryBlue'}>
                            <div className={'infoContainer centerContainer'}>
                                <div id={'cardsWrapper3'} className={'cardsWrapper'}>
                                    <div id={'cardsRow31'} className={'cardsRow'}>
                                        <div className={'blackCard cardBlack'}>
                                            <div className={'cardIcon'}>
                                                <img src={TopSrc} alt="TopSrc" />
                                            </div>
                                            <div className={'pinkLineBottom'}>
                                                <img src={ArrowPink} alt="Top" />
                                            </div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow32'} className={'cardsRow'}>
                                        <div className={'cardsLine lineGreen lineBottom lineFat'}>
                                            <div className='fatArrow'>
                                                <div className='fatLine'>
                                                    <span></span>
                                                </div>
                                                <img src={ArrowFatGreen} alt="Bottom" />
                                            </div>
                                            <div className={'arrowTip'}>
                                                <span className={'arrowTipPoint'}></span>
                                                <span className={'arrowTipLine'}></span>
                                                <span className={'arrowTipWrapper'}>
                                                    <span className={'arrowTipText'}>Roi</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className={'cardsLine linePink lineTop'}>
                                            <img src={ArrowGreen} alt="Top" />
                                            <div className={'arrowTip'}>
                                                <span className={'arrowTipPoint'}></span>
                                                <span className={'arrowTipLine'}></span>
                                                <span className={'arrowTipWrapper'}>
                                                    <span className={'arrowTipText'}>Investment</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow33'} className={'cardsRow'}>
                                        <div className={'whiteCard'}>
                                            <div className={'pinkLine'}></div>
                                            <div className={'cardIcon'}>
                                                <Invest/>
                                                <div className={'cardTitle'}><span>AFFILIATE</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id={'infoBlock4'} className={'infoBlock'}>
                        <div className={'infoColumn carouselColumn'}>
                            <div className={'infoContainer fullHeight'}>
                                <div className={'infoDescription'}>
                                    <h2>time proves quality.</h2>
                                    <p>these people have worked with us for a long time. see what they have to say about us and our traffic</p>
                                </div>
                                <div className={'carouselNavigation'}>
                                    <button id={'carouselNext'} onClick={this.next}></button>
                                    <button id={'carouselPrev'} onClick={this.prev}></button>
                                </div>
                            </div>
                        </div>
                        <div className={'infoColumn primaryBlue carouselWrapper'}>
                            <div className={'infoContainer carouselContainer'}>
                                <div id={'clientsCarousel'} className="siema">
                                    <div className={'carouselSlide hoverable'}>
                                        <img src={Slide1} alt="Nigel Williams"/>
                                        <div className='playFrame'>
                                            {playing1 ? <ReactPlayer
                                                id="video1"
                                                playing={playing1}
                                                playsinline={true}
                                                className="react-player"
                                                url='./video/nigel.mp4'
                                                width='100%'
                                                height='100%'
                                            /> : null}
                                        </div>
                                        <div className={'playName'}>
                                            <div className={'playVideo'}>
                                                <a id="playVideo1" className={'playBtn'} onClick={this.handlePlayPause}></a>
                                            </div>
                                            <div className={'personaInfo'}>
                                                <p className={'personName'}>Nigel Williams</p>
                                                <p className={'personPosition'}>CEO of DigiWorld Partners</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className={'carouselSlide hoverable'}>
                                        <img src={Slide1} alt="Greta Thunberg"/>
                                        <div className='playFrame'>
                                            {playing2 ? <ReactPlayer
                                                id="video2"
                                                playing={playing2}
                                                className={clsx('react-player', !playing2 ? 'hidden' : null)}
                                                url='https://www.youtube.com/watch?v=KAJsdgTPJpU'
                                                width='100%'
                                                height='100%'
                                            /> : null}
                                        </div>
                                        <div className={'playName'}>
                                            <div className={'playVideo'}>
                                                <a id="playVideo2" className={'playBtn'} onClick={this.handlePlayPause}></a>
                                            </div>
                                            <div className={'personaInfo'}>
                                                <p className={'personName'}>Greta Thunberg</p>
                                                <p className={'personPosition'}>Swedish environmental activist</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'carouselSlide hoverable'}>
                                        <img src={Slide1} alt="Greta Thunberg"/>
                                        <div className='playFrame'>
                                            {playing3 ? <ReactPlayer
                                                id="video3"
                                                playing={playing3}
                                                className="react-player"
                                                url="https://www.youtube.com/watch?v=KAJsdgTPJpU"
                                                width="100%"
                                                height="100%"
                                            /> : null}
                                        </div>
                                        <div className="playName">
                                            <div className="playVideo">
                                                <a id="playVideo3" className="playBtn" onClick={this.handlePlayPause}></a>
                                            </div>
                                            <div className="personaInfo">
                                                <p className="personName">Greta Thunberg</p>
                                                <p className="personPosition">Swedish environmental activist</p>
                                            </div>
                                        </div>
                                    </div>*/}
                                </div>
                                <nav id={'carouselNavigation'}>
                                    <li><button num={0} className={this.state.currentSlideNum === 0 ? 'active' : null} onClick={this.goto}></button></li>
                                    {/*<li><button num={1} className={this.state.currentSlideNum === 1 ? 'active' : null} onClick={this.goto}></button></li>
                                    <li><button num={2} className={this.state.currentSlideNum === 2 ? 'active' : null} onClick={this.goto}></button></li>*/}
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div id={'infoBlock5'} className={'infoBlock pushToAction'}>
                        <div className={'infoColumn primaryBlue'}>
                            <div className={'infoContainer'}>
                                <div className={'pushToActionTitle'}>
                                    <span id="talkIsCheap">Talk is cheap.</span>
                                    <span id="runTheTest"> let`s run a test</span>
                                </div>
                                <div className={'pushToActionButton'}>
                                    <div className="btnWrap">
                                        <NavLink className="btn btnGray" to="/registration">Register <Arrow/></NavLink>
                                        <NavLink className="btn btnGreen" to="/login">Login <ArrowDark/></NavLink>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )

    }
}