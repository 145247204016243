import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SmoothScrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";
import {Footer} from "../component/Footer";
import {NavLink} from "react-router-dom";
import Arrow from "../component/Arrow";

export class Home extends Component {

    constructor(props) {

        super(props);
        // reference to the DOM node

        this.loadPage = this.loadPage.bind(this);

    }

    loadPage(){

        let tl = gsap.timeline(),
            splitHomeTitleTalk = new SplitText('#homePage h1 span#talkIsCheap', {type: "chars"}),
            splitHomeTitleRun = new SplitText('#homePage h1 span#runTheTest', {type: "chars"});

        tl.from(splitHomeTitleTalk.chars,{duration: 1, y: 120, opacity: 0, stagger: 0.03}, 0);
        tl.from(splitHomeTitleRun.chars,{duration: 1, y: -120, opacity: 0, stagger: 0.03}, 0);
        tl.fromTo('#buyAds ',{y: 120, scale: 0, opacity: 0}, {duration: .5, y: 0, scale: 1, opacity: 1}, 0.1);
        tl.fromTo('#sellAds ',{y: 120, scale: 0, opacity: 0}, {duration: .5, y: 0, scale: 1, opacity: 1}, 0.2);

    }

    componentDidMount() {

        gsap.registerPlugin(ScrollTrigger);

        const myThis = this;

        if(!localStorage.getItem('wasLoaded')){

            gsap.to('#homePage h1', {opacity: 0});
            gsap.to('#buyAds ',{opacity: 0});
            gsap.to('#sellAds ',{opacity: 0});

            let startInterval = setInterval(function (){

                if(!myThis.props.loading){

                    gsap.to('#homePage h1', {opacity: 1});

                    myThis.loadPage();

                    clearInterval(startInterval);

                }

            }, 100)

        } else {

            myThis.loadPage();

        }

        if(myThis.props.isMobile === false){

            // Scrollbar
            SmoothScrollbar.use(OverscrollPlugin);

            let damping = 0.05;

            let scrollPositionX = 0,
                scrollPositionY = 0,
                vLogo = document.querySelector('#vLogo'),
                footer = document.querySelector('footer'),
                scrollContainer = document.querySelector('.fadePage div'),
                ScrollbarOptions = {
                    damping: damping,
                    thumbMinSize: 10,
                    renderByPixels: true,
                    alwaysShowTracks: false,
                    continuousScrolling: true,
                    plugins: {
                        overscroll: {
                            effect: 'glow',
                            glowColor: '#ffffff'
                        },
                    },
                };

            let scrollBarRoot = SmoothScrollbar.init(scrollContainer, ScrollbarOptions);

            scrollBarRoot.addListener(({ offset }) => {
                scrollPositionX = offset.x;
                scrollPositionY = offset.y;
                vLogo.style.transform = 'translateY(-' + scrollPositionY + 'px)';
                footer.style.transform = myThis.props.isMobile ? 'unset' : 'translateY(' + scrollPositionY + 'px)';
            });

            scrollBarRoot.setPosition(0,0);
            scrollBarRoot.track.xAxis.element.remove();

            ScrollTrigger.scrollerProxy(scrollContainer, {
                scrollTop(value) {
                    if (arguments.length) {
                        scrollBarRoot.scrollTop = value;
                    }
                    return scrollBarRoot.scrollTop;
                }
            });

            scrollBarRoot.addListener(ScrollTrigger.update);

            scrollBarRoot.scrollTo(0, 0);

            //EOF. Smooth Scrollbar

            //PRAVI

            let deltaY,
                currentScroll = 0,
                menuOpened = false;

            scrollBarRoot.addListener((s) => {

                deltaY = -1

                if(currentScroll < s.offset.y){
                    deltaY = 1
                }

                currentScroll = s.offset.y;

                menuOpened = document.body.classList.contains('menuOpened') ? true : false;

                if(!menuOpened){

                    if(currentScroll > 0){

                        document.querySelector('header').classList.add('smalled');

                        if (deltaY < 0){
                            document.querySelector('header').classList.remove('collapsed');
                        } else {
                            document.querySelector('header').classList.add('collapsed');
                        }

                    } else {
                        document.querySelector('header').classList.remove('collapsed', 'smalled');
                    }

                }

            });

            //EOF. PRAVI

        } else {



        }

    }

    render() {

        const meta = {
            title: 'TOPSRC',//data.content.title,
            description: '',
            canonical: '',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ''
                }
            }
        };

        return(
            <DocumentMeta {...meta}>
                <div id="homePage" className="page fullHeight">
                    <h1>
                        <span id="talkIsCheap">The first <br className="hidden visible-xs"/> m.a. traffic</span>
                        <span id="runTheTest"> rtb platform</span>
                    </h1>
                    <div id="homeBtns">
                        <div id="buyAds" className="btnWrap">
                            <NavLink className="btn btnGray" to="/dsp">buy ads <Arrow/></NavLink>
                        </div>
                        <div id="sellAds" className="btnWrap">
                            <NavLink className="btn btnGray btnBorder" to="/ssp">sell ads <Arrow/></NavLink>
                        </div>
                    </div>
                </div>
                <div id={"footerOverlay"}></div>
                <Footer/>
            </DocumentMeta>
        )

    }

}
