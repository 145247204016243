import React, {Component} from "react";
import LeftFist from "../images/left-fist.svg";
import RightFist from "../images/right-fist.svg";
import Heart from "../images/heart.svg";
import TopSrc from "../images/topsrc.svg";
import ArrowGreen from "../images/arrow-01-green.svg";
import Noob from "./cards/noob";
import Dsps from "./cards/dsps";
import CplOffers from "./cards/cpl-offers";
import Bid3 from "./cards/bid3";
import Bid1 from "./cards/bid1";
import Bid2 from "./cards/bid2";
import {NavLink} from "react-router-dom";
import ArrowDark from "./ArrowDark";
import Arrow from "./Arrow";

export class MobileSSP extends Component  {

    constructor(props) {

        super(props);
        // reference to the DOM node

    }

    render() {

        return (
            <div id={'sspPage'} className={'page'}>
                <div className="infoBlocks">

                    <div id={'infoBlock5'} className={'infoBlock'}>
                        <div className={'infoColumn'}>
                            <div className={'infoContainer'}>
                                <div className={'infoDescription'}>
                                    <h2>topsrc is a special ssp</h2>
                                    <p>only just because our advertisers are looking for that special traffic: they want members area banners, funnel clicks, tab clicks, chat, email and push clicks and all that other traffic that you just can’t sell elsewhere. </p>
                                </div>
                            </div>
                        </div>
                        <div className={'infoColumn primaryBlue'}>
                            <div className={'infoContainer centerContainer'}>
                                <div id={'cardsWrapper5'} className={'cardsWrapper'}>
                                    <div id={'cardsRow51'} className={'cardsRow'}>
                                        <div className={'bid3 whiteCard'}>
                                            <div className={'cardIcon'}><Bid3 /></div>
                                            <div className={'cardTitle'}><span>Members Area</span></div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow52'} className={'cardsRow'}>
                                        <div className={'bid2 whiteCard'}>
                                            <div className={'cardIcon'}><Bid2 /></div>
                                            <div className={'cardTitle'}><span>Back Button</span></div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow53'} className={'cardsRow'}>
                                        <div className={'bid1 whiteCard'}>
                                            <div className={'cardIcon'}><Bid1 /></div>
                                            <div className={'cardTitle'}><span>Funnel Clicks</span></div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow54'} className={'cardsRow'}>
                                        <div id="firstLine" className={'cardsLine lineGreen lineBottom'}>
                                            <div className={'lineGreen'}></div>
                                            <img src={ArrowGreen} alt="Bottom" />
                                        </div>
                                        <div id="secondLine" className={'cardsLine lineGreen lineBottom'}>
                                            <div className={'lineGreen'}></div>
                                            <img src={ArrowGreen} alt="Bottom" />
                                        </div>
                                        <div id="thirdLine" className={'cardsLine lineGreen lineBottom'}>
                                            <img src={ArrowGreen} alt="Bottom" />
                                        </div>
                                    </div>
                                    <div id={'cardsRow55'} className={'cardsRow'}>
                                        <div className={'blackCard cardBlack'}>
                                            <div className={'cardIcon'}>
                                                <img src={TopSrc} alt="TopSrc" />
                                            </div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow56'} className={'cardsRow'}>
                                        <div className={'bid1 whiteCard'}>
                                            <div id="firstLine" className={'cardsLine lineGreen lineBottom'}>
                                                <img src={ArrowGreen} alt="Bottom" />
                                            </div>
                                            <div className={'cardIcon'}><Bid1 /></div>
                                            <div className={'cardTitle'}><span>Buyer 1</span></div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow57'} className={'cardsRow'}>
                                        <div className={'bid2 whiteCard'}>
                                            <div id="secondLine" className={'cardsLine lineGreen lineBottom'}>
                                                <div className={'lineGreen'}></div>
                                                <img src={ArrowGreen} alt="Bottom" />
                                            </div>
                                            <div className={'cardIcon'}><Bid2 /></div>
                                            <div className={'cardTitle'}><span>Buyer 2</span></div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow58'} className={'cardsRow'}>
                                        <div className={'whiteCard'}>
                                            <div id="thirdLine" className={'cardsLine lineGreen lineBottom'}>
                                                <div className={'lineGreen'}></div>
                                                <img src={ArrowGreen} alt="Bottom" />
                                            </div>
                                            <div className={'bid3 cardIcon'}><Bid3 /></div>
                                            <div className={'cardTitle'}><span>Buyer 3</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={'infoBlock6'} className={'infoBlock'}>
                        <div className={'infoColumn'}>
                            <div className={'infoContainer'}>
                                <div className={'infoDescription'}>
                                    <h2>topsrc is win-win</h2>
                                    <p>before sending your traffic to the smartlink, you can put it on an auction. put a floor cpm to make sure you will not sell it cheaper than your performance campaigns make.</p>
                                </div>
                            </div>
                        </div>
                        <div className={'infoColumn primaryBlue'}>
                            <div className={'infoContainer centerContainer'}>
                                <div id={'cardsWrapper6'} className={'cardsWrapper'}>
                                    <div id={'cardsRow61'} className={'cardsRow'}>
                                        <div className={'blackCard cardBlack'}>
                                            <div className={'cardIcon'}>
                                                <img src={TopSrc} alt="TopSrc" />
                                            </div>
                                            <div className={'lineGreen arrowTop'}></div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow62'} className={'cardsRow'}>
                                        <div className={'cardsLine lineGreen lineBottom'}>
                                            <img src={ArrowGreen} alt="Bottom" />
                                        </div>
                                    </div>
                                    <div id={'cardsRow63'} className={'cardsRow'}>
                                        <div id={'affiliateCard'} className={'whiteCard'}>
                                            <div className={'lineGreen lineTop'}></div>
                                            <div className={'cardIcon'}><Dsps /></div>
                                            <div className={'cardTitle'}><span>Affiliate</span></div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow64'} className={'cardsRow'}>
                                        <div className={'cardsLine lineGreen lineBottom'}>
                                            <img src={ArrowGreen} alt="Bottom" />
                                        </div>
                                    </div>
                                    <div id={'cardsRow65'} className={'cardsRow'}>
                                        <div id={'cplCard'} className={'whiteCard'}>
                                            <div className={'cardIcon'}><CplOffers /></div>
                                            <div className={'cardTitle'}><span>CPL Offers</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={'infoBlock7'} className={'infoBlock'}>
                        <div className={'infoColumn'}>
                            <div className={'infoContainer'}>
                                <div className={'infoDescription'}>
                                    <h2>topsrc is a reliable partner</h2>
                                    <p>because we know that acquiring a publisher is anywhere from 5 to 25 times more expensive than retaining an existing one. we are ready to be flexible and do things to make you stay with us longer.</p>
                                </div>
                            </div>
                        </div>
                        <div className={'infoColumn primaryBlue'}>
                            <div className={'infoContainer centerContainer fullContainer'}>
                                <div id={'cardsWrapper7'} className={'cardsWrapper'}>
                                    <div id={'cardsRow71'} className={'cardsRow'}>
                                        <img src={LeftFist} alt="Left Fist" />
                                    </div>
                                    <div id={'cardsRow72'} className={'cardsRow'}>
                                        <img src={RightFist} alt="Right Fist" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={'infoBlock8'} className={'infoBlock'}>
                        <div className={'infoColumn'}>
                            <div className={'infoContainer'}>
                                <div className={'infoDescription'}>
                                    <h2>technology</h2>
                                    <p className={'text-left'}><b>we are technical enough to maintain two objectives:</b></p>
                                    <ul className={'numList text-left'}>
                                        <li><span className={'listNum'}>1</span> <span className={'listText'}>let you afford yourself not to be technical and still leverage the rtb auction.</span></li>
                                        <li><span className={'listNum'}>2</span> <span className={'listText'}>work with you whole nine if you have an ssp and can integrate our endpoints.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={'infoColumn primaryBlue'}>
                            <div className={'infoContainer centerContainer'}>
                                <div id={'cardsWrapper8'} className={'cardsWrapper'}>
                                    <div id={'cardsRow81'} className={'cardsRow'}>
                                        <img src={Heart} alt="Heart" />
                                        <img src={Heart} alt="Heart" />
                                        <img src={Heart} alt="Heart" />
                                    </div>
                                    <div id={'cardsRow82'} className={'cardsRow'}>
                                        <img src={Heart} alt="Heart" />
                                        <img src={Heart} alt="Heart" />
                                    </div>
                                    <div id={'cardsRow83'} className={'cardsRow'}>
                                        <img src={Heart} alt="Heart" />
                                    </div>
                                    <div id={'cardsRow84'} className={'cardsRow'}>
                                        <div className={'blackCard cardBlack'}>
                                            <div className={'cardIcon'}>
                                                <img src={TopSrc} alt="TopSrc" />
                                            </div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow85'} className={'cardsRow'}>
                                        <div className={'cardsLine lineGreen lineBottom'}>
                                            <img src={ArrowGreen} alt="Bottom" />
                                        </div>
                                    </div>
                                    <div id={'cardsRow86'} className={'cardsRow'}>
                                        <div className={'whiteCard'}>
                                            <div className={'cardIcon'}><Noob /></div>
                                            <div className={'cardTitle'}><span>Noob</span></div>
                                            <div className={'cardNum'}><span>1</span></div>
                                        </div>
                                    </div>
                                    <div id={'cardsRow87'} className={'cardsRow'}>
                                        <div className={'longLine cardsLine lineGreen lineBottom'}>
                                            <span className='greenLine'></span>
                                            <img src={ArrowGreen} alt="Bottom" />
                                        </div>
                                    </div>
                                    <div id={'cardsRow88'} className={'cardsRow'}>
                                        <div className={'whiteCard'}>
                                            <div className={'cardIcon'}><Dsps /></div>
                                            <div className={'cardTitle'}><span>Expert</span></div>
                                            <div className={'cardNum'}><span>2</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={'infoBlock9'} className={'infoBlock pushToAction'}>
                        <div className={'infoColumn'}>
                            <div className={'infoContainer'}>
                                <div className={'pushToActionTitle'}>
                                    <span id="talkIsCheap">Talk is cheap.</span>
                                    <span id="runTheTest"> let`s run a test</span>
                                </div>
                                <div className={'pushToActionButton'}>
                                    <div className="btnWrap">
                                        <NavLink className="btn btnGray" to="/registration">Register <Arrow/></NavLink>
                                        <NavLink className="btn btnGreen" to="/login">Login <ArrowDark/></NavLink>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )

    }
}