import React, {useState} from 'react';
import ArrowDown from "../component/ArrowDown";

//Data
import { TECH } from '../data/tech';

const Accordion = () => {

  const [clicked, setClicked] = useState(false);

  const toggle = index => {

      if (clicked === index) {
          //if clicked question is already active, then close it
          return setClicked(null);
      }

      setClicked(index);
  };

  return (
      <div id={'techAccordion'}>
      {TECH.map((item, index) => (
          <div className={clicked === index ? 'expandedText expandedText__isOpen' : 'expandedText'}>
              <div
                  className={'expandedText_title'}
                  onClick={() => toggle(index)} key={index}>
                  <span className={'expandedText_titleSpan'}>{item.title}</span>
                  <div className={'expandedText_arrowWrapper'} >
                      <ArrowDown />
                  </div>
              </div>
              <div className={clicked === index ? 'expandedText_expanded expandedText_expanded__isOpen' : 'expandedText_expanded'}>
                  <div className={'expandedText_text'}>{item.text}</div>
              </div>
          </div>
      ))}
      </div>
  );

}

export default Accordion;