const Arrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path fill="#FFF" fillRule="nonzero" d="M8 2.667L7.057 3.61 10.78 7.333 2.667 7.333 2.667 8.667 10.78 8.667 7.057 12.39 8 13.333 13.333 8z" transform="translate(-852 -607) translate(553 198) translate(0 387) translate(299 22)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Arrow;