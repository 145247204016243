import React, {Component} from 'react';
import DocumentMeta from "react-document-meta";
import {gsap, Power4} from "gsap";
import {SplitText} from "gsap/SplitText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SmoothScrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";

//Components
import PageTitle from '../component/PageTitle';
import {Footer} from "../component/Footer";
import {DesktopSSP} from "../component/DesktopSSP";
import {MobileSSP} from "../component/MobileSSP";

export class SSP extends Component {

    constructor(props) {

        super(props);
        // reference to the DOM node

        this.loadPage = this.loadPage.bind(this);

    }

    loadPage(){

        let tl = gsap.timeline(),
            splitTitle = new SplitText('#pageTitle h1 span', {type: "chars"});

        tl.from(splitTitle.chars,{duration: 1, y: 120, opacity: 0, stagger: 0.2}, 0);

    }

    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     *
     * @returns {String}
     */
    getMobileOperatingSystem() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    componentDidMount() {

        gsap.registerPlugin(ScrollTrigger);

        const myThis = this;

        if(!localStorage.getItem('wasLoaded')){

            gsap.to('#pageTitle h1', {opacity: 0});

            let startInterval = setInterval(function (){

                if(!myThis.props.loading){

                    gsap.to('#pageTitle h1', {opacity: 1});

                    myThis.loadPage();

                    clearInterval(startInterval);

                }

            }, 100)

        } else {

            myThis.loadPage();

        }

        //EOF. PRAVI

        if(myThis.props.isMobile === false) {

            // Scrollbar
            SmoothScrollbar.use(OverscrollPlugin);

            let damping = .05;

            let scrollPositionX = 0,
                scrollPositionY = 0,
                vLogo = document.querySelector('#vLogo'),
                footer = document.querySelector('footer'),
                scrollContainer = document.querySelector('.fadePage div'),
                ScrollbarOptions = {
                    damping: damping,
                    thumbMinSize: 10,
                    renderByPixels: true,
                    alwaysShowTracks: false,
                    continuousScrolling: true,
                    plugins: {
                        overscroll: {
                            effect: 'glow',
                            glowColor: '#ffffff'
                        },
                    },
                };

            let scrollBarRoot = SmoothScrollbar.init(scrollContainer, ScrollbarOptions);

            scrollBarRoot.addListener(({ offset }) => {
                scrollPositionX = offset.x;
                scrollPositionY = offset.y;
                vLogo.style.transform = 'translateY(-' + scrollPositionY + 'px)';
                footer.style.transform = myThis.props.isMobile ? 'unset' : 'translateY(' + scrollPositionY + 'px)';
            });

            scrollBarRoot.setPosition(0,0);
            scrollBarRoot.track.xAxis.element.remove();

            ScrollTrigger.scrollerProxy(scrollContainer, {
                scrollTop(value) {
                    if (arguments.length) {
                        scrollBarRoot.scrollTop = value;
                    }
                    return scrollBarRoot.scrollTop;
                }
            });

            scrollBarRoot.addListener(ScrollTrigger.update);

            scrollBarRoot.scrollTo(0, 0);

            //EOF. Smooth Scrollbar

            //PRAVI

            let deltaY,
                currentScroll = 0,
                menuOpened = false;

            scrollBarRoot.addListener((s) => {

                deltaY = -1

                if(currentScroll < s.offset.y){
                    deltaY = 1
                }

                currentScroll = s.offset.y;

                menuOpened = document.body.classList.contains('menuOpened') ? true : false;

                if(!menuOpened){

                    if(currentScroll > 0){

                        document.querySelector('header').classList.add('smalled');

                        if (deltaY < 0){
                            document.querySelector('header').classList.remove('collapsed');
                        } else {
                            document.querySelector('header').classList.add('collapsed');
                        }

                    } else {
                        document.querySelector('header').classList.remove('collapsed', 'smalled');
                    }

                }

            });

            // Scroll Title
            ScrollTrigger.create({
                trigger: "#titleOverlay",
                start: "0% 0%",
                end: 400,
                pin: '#pageTitle',
                scroller: scrollContainer,
            });

            gsap.timeline({
                scrollTrigger: {
                    trigger: "#sspPage",
                    start: "0% 0%",
                    end: 400,
                    scroller: scrollContainer,
                    scrub: true
                }
            })
                .fromTo("footer", {opacity: 0}, {opacity: 1}, 0)
                .fromTo("#footerOverlay", {opacity: 0}, {opacity: 1}, 0)

            gsap.timeline({
                scrollTrigger: {
                    trigger: "#infoBlock5",
                    start: "0 399",
                    end: 1400,
                    scrub: true,
                    pin: '#infoBlock5 .infoDescription',
                    scroller: scrollContainer,
                    //markers: true
                }
            })
                .fromTo("#cardsRow51 .whiteCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, 0)
                .fromTo("#cardsRow51 .bid3 svg #bid3R1", {strokeDasharray: '0px 300px'}, {
                    strokeDasharray: '300px 300px',
                    duration: 1
                }, .5)
                .fromTo("#cardsRow51 .bid3 svg #bid3P1", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, .5)
                .fromTo("#cardsRow51 .bid3 svg #bid3P2", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, .5)
                .fromTo("#cardsRow51 .bid3 svg #bid3P3", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, .5)
                .fromTo("#cardsRow51 .bid3 svg #bid3P4", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, .5)
                .fromTo("#cardsRow51 .bid3 svg #bid3P5", {strokeDasharray: '0px 200px'}, {
                    strokeDasharray: '200px 200px',
                    duration: 1
                }, .5)
                .fromTo("#cardsRow51 .whiteCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, .75)

                .fromTo("#cardsRow52 .bid1", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, .5)
                .fromTo("#cardsRow52 .bid1 svg #bid1R1", {strokeDasharray: '0px 300px'}, {
                    strokeDasharray: '300px 300px',
                    duration: 1
                }, 1)
                .fromTo("#cardsRow52 .bid1 svg #bid1P1", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 1)
                .fromTo("#cardsRow52 .bid1 svg #bid1P2", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 1)
                .fromTo("#cardsRow52 .bid1 svg #bid1P3", {strokeDasharray: '0px 200px'}, {
                    strokeDasharray: '200px 200px',
                    duration: 1
                }, 1)
                .fromTo("#cardsRow52 .bid1 .cardTitle span", {y: 60}, {y: 0, duration: .5}, 1.25)

                .fromTo("#cardsRow52 .bid2", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, .5)
                .fromTo("#cardsRow52 .bid2 svg #bid2R1", {strokeDasharray: '0px 300px'}, {
                    strokeDasharray: '300px 300px',
                    duration: 1
                }, 1)
                .fromTo("#cardsRow52 .bid2 svg #bid2P1", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 1)
                .fromTo("#cardsRow52 .bid2 svg #bid2P2", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 1)
                .fromTo("#cardsRow52 .bid2 svg #bid2P3", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 1)
                .fromTo("#cardsRow52 .bid2 svg #bid2P4", {strokeDasharray: '0px 200px'}, {
                    strokeDasharray: '200px 200px',
                    duration: 1
                }, 1)
                .fromTo("#cardsRow52 .bid2 .cardTitle span", {y: 60}, {y: 0, duration: .5}, 1.25)

                .fromTo("#cardsRow52 .cardsLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, .5)
                .fromTo("#cardsRow52 .cardsLine", {y: -150}, {y: 0, duration: 1}, .5)
                .fromTo("#cardsRow52 .cardsLine .topLine", {height: 0}, {height: 304, duration: 1}, .5)

                .fromTo("#cardsRow53 .cardsLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, .75)
                .fromTo("#cardsRow53 .cardsLine", {y: -150}, {y: 0, duration: .5}, .75)

                .fromTo("#cardsRow54 .blackCard", {scale: 0}, {scale: 1, duration: 1}, 1)

                .fromTo("#cardsRow55 .cardsLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, 2.5)
                .fromTo("#cardsRow55 .cardsLine", {y: -150}, {y: 0, duration: 1}, 2.5)

                .fromTo("#cardsRow56 .bid1", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, 3)
                .fromTo("#cardsRow56 .bid1 svg #bid1R1", {strokeDasharray: '0px 300px'}, {
                    strokeDasharray: '300px 300px',
                    duration: 1
                }, 3.5)
                .fromTo("#cardsRow56 .bid1 svg #bid1P1", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 3.5)
                .fromTo("#cardsRow56 .bid1 svg #bid1P2", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 3.5)
                .fromTo("#cardsRow56 .bid1 svg #bid1P3", {strokeDasharray: '0px 200px'}, {
                    strokeDasharray: '200px 200px',
                    duration: 1
                }, 3.5)
                .fromTo("#cardsRow56 .bid1 .cardTitle span", {y: 60}, {y: 0, duration: .5}, 4)

                .fromTo("#cardsRow56 .bid2", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, 3)
                .fromTo("#cardsRow56 .bid2 svg #bid2R1", {strokeDasharray: '0px 300px'}, {
                    strokeDasharray: '300px 300px',
                    duration: 1
                }, 3.5)
                .fromTo("#cardsRow56 .bid2 svg #bid2P1", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 3.5)
                .fromTo("#cardsRow56 .bid2 svg #bid2P2", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 3.5)
                .fromTo("#cardsRow56 .bid2 svg #bid2P3", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 3.5)
                .fromTo("#cardsRow56 .bid2 svg #bid2P4", {strokeDasharray: '0px 200px'}, {
                    strokeDasharray: '200px 200px',
                    duration: 1
                }, 3.5)
                .fromTo("#cardsRow56 .bid2 .cardTitle span", {y: 60}, {y: 0, duration: .5}, 4)

                .fromTo("#cardsRow56 .cardsLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, 2.75)
                .fromTo("#cardsRow56 .cardsLine", {y: -150}, {y: 0, duration: 2}, 2.75)
                .fromTo("#cardsRow56 .cardsLine .topLine", {height: 0}, {height: 304, duration: 2}, 2.75)

                .fromTo("#cardsRow57 .whiteCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, 4)
                .fromTo("#cardsRow57 .bid3 svg #bid3R1", {strokeDasharray: '0px 300px'}, {
                    strokeDasharray: '300px 300px',
                    duration: 1
                }, 4.5)
                .fromTo("#cardsRow57 .bid3 svg #bid3P1", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 4.5)
                .fromTo("#cardsRow57 .bid3 svg #bid3P2", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 4.5)
                .fromTo("#cardsRow57 .bid3 svg #bid3P3", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 4.5)
                .fromTo("#cardsRow57 .bid3 svg #bid3P4", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 4.5)
                .fromTo("#cardsRow57 .bid3 svg #bid3P5", {strokeDasharray: '0px 200px'}, {
                    strokeDasharray: '200px 200px',
                    duration: 1
                }, 4.5)
                .fromTo("#cardsRow57 .whiteCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, 4.75);


            gsap.timeline({
                scrollTrigger: {
                    trigger: "#infoBlock6",
                    start: '0 399',
                    end: 2500,
                    scrub: true,
                    pin: '#infoBlock6 .infoDescription',
                    scroller: scrollContainer,
                    //markers: true
                }
            })
                .fromTo("#cardsRow61 .blackCard", {scale: 0}, {scale: 1, duration: .25}, 0)
                //Arrows
                .fromTo("#cardsRow61 .arrowTop, #cardsRow62 .lineBottom, #cardsRow63 .lineTop", {autoAlpha: 0}, {
                    autoAlpha: 1,
                    duration: .01
                }, .35)
                .fromTo("#cardsRow61 .arrowTop, #cardsRow63 .lineTop", {y: 120}, {y: 0, duration: .5}, .35)
                .fromTo("#cardsRow62 .lineBottom", {y: -120}, {y: 0, duration: .5}, .35)
                //1st Card
                .fromTo("#cardsRow63 #affiliateCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .25}, .25)
                .fromTo("#cardsRow63 #affiliateCard svg #dspsP1", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, .75)
                .fromTo("#cardsRow63 #affiliateCard svg #dspsC1", {strokeDasharray: '0px 64px'}, {
                    strokeDasharray: '64px 64px',
                    duration: 1
                }, .75)
                .fromTo("#cardsRow63 #affiliateCard svg #dspsP2", {strokeDasharray: '0px 120px'}, {
                    strokeDasharray: '120px 120px',
                    duration: 1
                }, .75)
                .fromTo("#cardsRow63 #affiliateCard svg #dspsP3", {strokeDasharray: '0px 120px'}, {
                    strokeDasharray: '120px 120px',
                    duration: 1
                }, .75)
                .fromTo("#cardsRow63 #affiliateCard svg #dspsP4", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, .75)
                .fromTo("#cardsRow63 #affiliateCard svg #dspsP5", {strokeDasharray: '0px 300px'}, {
                    strokeDasharray: '300px 300px',
                    duration: 1
                }, .75)
                .fromTo("#cardsRow63 #affiliateCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, 1)
                //Arrow Bottom Hook
                .fromTo("#cardsRow64 .arrowHookBottom .arrowHookShadow", {width: 0}, {width: 120, duration: .25}, 1.25)
                .fromTo("#cardsRow64 .arrowHookBottom .arrowHookLine", {width: 0}, {width: 220, duration: .25}, 1.5)
                .fromTo("#cardsRow63 .arrowTopHook .arrowHookCorner", {width: 0}, {width: 220, duration: .25}, 1.75)
                //2nd Card
                .fromTo("#cardsRow63 #cplCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .25}, 1.5)
                .fromTo("#cardsRow63 #cplCard svg #cplP1", {strokeDasharray: '0px 200px'}, {
                    strokeDasharray: '200px 200px',
                    duration: .5
                }, 1.75)
                .fromTo("#cardsRow63 #cplCard svg #cplP2", {strokeDasharray: '0px 200px'}, {
                    strokeDasharray: '200px 200px',
                    duration: .5
                }, 1.75)
                .fromTo("#cardsRow63 #cplCard svg #cplE1", {strokeDasharray: '0px 200px'}, {
                    strokeDasharray: '200px 200px',
                    duration: .5
                }, 1.75)
                .fromTo("#cardsRow63 #cplCard svg #cplR1", {strokeDasharray: '0px 200px'}, {
                    strokeDasharray: '200px 200px',
                    duration: .5
                }, 1.75)
                .fromTo("#cardsRow63 #cplCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, 1.75)

            const handsTimeline = gsap.timeline({repeat: -1, paused: true})
                .to("#cardsRow71 img", {x: 0, y: 0, rotate: 0, duration: .5}, 0)
                .to("#cardsRow72 img", {x: 0, y: 0, rotate: 0, duration: .5}, 0)
                .to("#cardsRow71 img", {y: -50, rotate: -5, duration: .25}, .5)
                .to("#cardsRow72 img", {y: 50, rotate: -5, duration: .25}, .5)
                .to("#cardsRow71 img", {y: 30, rotate: 5, duration: .5, ease: Power4.easeIn}, ">")
                .to("#cardsRow72 img", {y: -30, rotate: 5, duration: .5, ease: Power4.easeIn}, "<")
                .to("#cardsRow71 img", {x: -150, duration: .5}, ">")
                .to("#cardsRow72 img", {x: 150, duration: .5}, "<")
                .to("#cardsRow71 img", {y: 300, rotate: 0, duration: .5, ease: Power4.easeIn}, ">")
                .to("#cardsRow72 img", {y: -300, rotate: 0, duration: .5, ease: Power4.easeIn}, "<")
                .to("#cardsRow71 img", {x: -25, y: 300, rotate: 5, duration: .5}, ">")
                .to("#cardsRow72 img", {x: 25, y: -300, rotate: 5, duration: .5}, "<")
                .to("#cardsRow71 img", {y: 220, duration: .5, ease: Power4.easeIn}, ">")
                .to("#cardsRow72 img", {y: -220, duration: .5, ease: Power4.easeIn}, "<")
                .to("#cardsRow71 img", {x: -150, duration: .5}, ">")
                .to("#cardsRow72 img", {x: 150, duration: .5}, "<")
                .to("#cardsRow71 img", {x: -150, y: 0, rotate: -5, duration: .5}, ">")
                .to("#cardsRow72 img", {x: 150, y: 0, rotate: -5, duration: .5}, "<")
                .to("#cardsRow71 img", {x: 0, rotate: 0, duration: .5}, ">")
                .to("#cardsRow72 img", {x: 0, rotate: 0, duration: .5}, "<");

            ScrollTrigger.create({
                trigger: "#infoBlock7",
                start: "0 399",
                end: 3800,
                pin: '#infoBlock7 .infoDescription',
                scroller: scrollContainer,
                //markers: true,
                onEnter: () => handsTimeline.play()
            });

            gsap.timeline({
                scrollTrigger: {
                    trigger: "#infoBlock8",
                    start: '0 399',
                    end: 5200,
                    scrub: true,
                    pin: '#infoBlock8 .infoDescription',
                    scroller: scrollContainer,
                }
            })
                .fromTo("#cardsRow81 img:nth-child(1)", {scale: 0.1, autoAlpha: 0}, {
                    scale: 1,
                    autoAlpha: 1,
                    duration: 1
                }, 0)
                .fromTo("#cardsRow81 img:nth-child(2)", {scale: 0.1, autoAlpha: 0}, {
                    scale: 1,
                    autoAlpha: 1,
                    duration: 1
                }, 0.25)
                .fromTo("#cardsRow81 img:nth-child(3)", {scale: 0.1, autoAlpha: 0}, {
                    scale: 1,
                    autoAlpha: 1,
                    duration: 1
                }, 0.5)
                .fromTo("#cardsRow82 img:nth-child(1)", {scale: 0.1, autoAlpha: 0}, {
                    scale: 1,
                    autoAlpha: 1,
                    duration: 1
                }, 0.75)
                .fromTo("#cardsRow82 img:nth-child(2)", {scale: 0.1, autoAlpha: 0}, {
                    scale: 1,
                    autoAlpha: 1,
                    duration: 1
                }, 1)
                .fromTo("#cardsRow83 img", {scale: 0.1, autoAlpha: 0}, {scale: 1, autoAlpha: 1, duration: 1}, 1.5)
                .fromTo("#cardsRow84 .blackCard", {scale: 0}, {scale: 1, duration: .25}, 1.75)
                .fromTo("#cardsRow85 .cardsLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, 2)
                .fromTo("#cardsRow85 .cardsLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, 2)
                .fromTo("#cardsRow85 .cardsLine", {y: -175}, {y: 0, duration: .5}, 2)
                .fromTo("#cardsRow85 .cardsLine", {y: -175}, {y: 0, duration: .5}, 2)
                //Noob
                .fromTo("#cardsRow86 #card861", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, 2)
                .fromTo("#cardsRow86 #card861 svg #noobP1", {strokeDasharray: '0px 300px'}, {
                    strokeDasharray: '300px 300px',
                    duration: 1
                }, 2.75)
                .fromTo("#cardsRow86 #card861 svg #noobC1", {strokeDasharray: '0px 64px'}, {
                    strokeDasharray: '64px 64px',
                    duration: 1
                }, 2.75)
                .fromTo("#cardsRow86 #card861 svg #noobP2", {strokeDasharray: '0px 200px'}, {
                    strokeDasharray: '200px 200px',
                    duration: 1
                }, 2.75)
                .fromTo("#cardsRow86 #card861 svg #noobP3", {strokeDasharray: '0px 120px'}, {
                    strokeDasharray: '120px 120px',
                    duration: 1
                }, 2.75)
                .fromTo("#cardsRow86 #card861 svg #noobC2", {strokeDasharray: '0px 120px'}, {
                    strokeDasharray: '120px 120px',
                    duration: 1
                }, 2.75)
                .fromTo("#cardsRow86 #card861 svg #noobC3", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 2.75)
                .fromTo("#cardsRow86 #card861 svg #noobP4", {strokeDasharray: '0px 120px'}, {
                    strokeDasharray: '120px 120px',
                    duration: 1
                }, 2.75)
                .fromTo("#cardsRow86 #card861 .cardTitle span", {y: 60}, {y: 0, duration: .5}, 3)
                .fromTo("#cardsRow86 #card861 .cardNum", {scale: 0, rotate: -180}, {
                    scale: 1,
                    rotate: 0,
                    duration: .5
                }, 3)
                //Expert
                .fromTo("#cardsRow86 #card862", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, 2)
                .fromTo("#cardsRow86 #card862 svg #dspsP1", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 2.75)
                .fromTo("#cardsRow86 #card862 svg #dspsC1", {strokeDasharray: '0px 64px'}, {
                    strokeDasharray: '64px 64px',
                    duration: 1
                }, 2.75)
                .fromTo("#cardsRow86 #card862 svg #dspsP2", {strokeDasharray: '0px 120px'}, {
                    strokeDasharray: '120px 120px',
                    duration: 1
                }, 2.75)
                .fromTo("#cardsRow86 #card862 svg #dspsP3", {strokeDasharray: '0px 120px'}, {
                    strokeDasharray: '120px 120px',
                    duration: 1
                }, 2.75)
                .fromTo("#cardsRow86 #card862 svg #dspsP4", {strokeDasharray: '0px 100px'}, {
                    strokeDasharray: '100px 100px',
                    duration: 1
                }, 2.75)
                .fromTo("#cardsRow86 #card862 svg #dspsP5", {strokeDasharray: '0px 300px'}, {
                    strokeDasharray: '300px 300px',
                    duration: 1
                }, 2.75)
                .fromTo("#cardsRow86 #card862 .cardTitle span", {y: 60}, {y: 0, duration: .5}, 3)
                .fromTo("#cardsRow86 #card862 .cardNum", {scale: 0, rotate: -180}, {
                    scale: 1,
                    rotate: 0,
                    duration: .5
                }, 3)

        } else {

            // Scroll Title
            ScrollTrigger.create({
                trigger: "#titleOverlay",
                start: "0% 0%",
                end: 400,
                pin: '#pageTitle',
            });

            gsap.timeline({
                scrollTrigger: {
                    trigger: "#sspPage",
                    start: "0% 0%",
                    end: 400,
                    scrub: true
                }
            })
                .fromTo("footer", {opacity: 0}, {opacity: 1}, 0)
                .fromTo("#footerOverlay", {opacity: 0}, {opacity: 1}, 0)

            const mobSys = this.getMobileOperatingSystem();

            //if(mobSys === 'iOS'){

                gsap.timeline({
                    scrollTrigger: {
                        trigger: "#infoBlock5 .primaryBlue",
                        start: "0 399",
                        end: 3400,
                        scrub: true,
                        //markers: true
                    }
                })
                    .fromTo("#cardsRow51 .whiteCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, 0)
                    .fromTo("#cardsRow51 .bid3 svg #bid3R1", {strokeDasharray: '0px 300px'}, {
                        strokeDasharray: '300px 300px',
                        duration: 1
                    }, .5)
                    .fromTo("#cardsRow51 .bid3 svg #bid3P1", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, .5)
                    .fromTo("#cardsRow51 .bid3 svg #bid3P2", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, .5)
                    .fromTo("#cardsRow51 .bid3 svg #bid3P3", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, .5)
                    .fromTo("#cardsRow51 .bid3 svg #bid3P4", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, .5)
                    .fromTo("#cardsRow51 .bid3 svg #bid3P5", {strokeDasharray: '0px 200px'}, {
                        strokeDasharray: '200px 200px',
                        duration: 1
                    }, .5)
                    .fromTo("#cardsRow51 .whiteCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, .75)

                    .fromTo("#cardsRow52 .bid2", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, .5)
                    .fromTo("#cardsRow52 .bid2 svg #bid2R1", {strokeDasharray: '0px 300px'}, {
                        strokeDasharray: '300px 300px',
                        duration: 1
                    }, 1)
                    .fromTo("#cardsRow52 .bid2 svg #bid2P1", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 1)
                    .fromTo("#cardsRow52 .bid2 svg #bid2P2", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 1)
                    .fromTo("#cardsRow52 .bid2 svg #bid2P3", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 1)
                    .fromTo("#cardsRow52 .bid2 svg #bid2P4", {strokeDasharray: '0px 200px'}, {
                        strokeDasharray: '200px 200px',
                        duration: 1
                    }, 1)
                    .fromTo("#cardsRow52 .bid2 .cardTitle span", {y: 60}, {y: 0, duration: .5}, 1.25)

                    .fromTo("#cardsRow53 .bid1", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, 1)
                    .fromTo("#cardsRow53 .bid1 svg #bid1R1", {strokeDasharray: '0px 300px'}, {
                        strokeDasharray: '300px 300px',
                        duration: 1
                    }, 1.5)
                    .fromTo("#cardsRow53 .bid1 svg #bid1P1", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 1.5)
                    .fromTo("#cardsRow53 .bid1 svg #bid1P2", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 1.5)
                    .fromTo("#cardsRow53 .bid1 svg #bid1P3", {strokeDasharray: '0px 200px'}, {
                        strokeDasharray: '200px 200px',
                        duration: 1
                    }, 1.5)
                    .fromTo("#cardsRow53 .bid1 .cardTitle span", {y: 60}, {y: 0, duration: .5}, 1.75)

                    .fromTo("#cardsRow54 #firstLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, .5)
                    .fromTo("#cardsRow54 #firstLine img", {y: -980}, {y: 0, duration: 2}, .6)
                    .fromTo("#cardsRow54 #firstLine .lineGreen", {height: 0}, {height: 820, duration: 2}, .6)

                    .fromTo("#cardsRow54 #secondLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, 1.25)
                    .fromTo("#cardsRow54 #secondLine img", {y: -580}, {y: 0, duration: 1.5}, 1.25)
                    .fromTo("#cardsRow54 #secondLine .lineGreen", {height: 0}, {height: 420, duration: 1.5}, 1.25)

                    .fromTo("#cardsRow54 #thirdLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, 2)
                    .fromTo("#cardsRow54 #thirdLine img", {y: -180}, {y: 0, duration: 1}, 2.1)

                    .fromTo("#cardsRow55 .blackCard", {scale: 0}, {scale: 1, duration: 1}, 2)

                    .fromTo("#cardsRow56 .bid1 svg #bid1R1", {strokeDasharray: '0px 300px'}, {
                        strokeDasharray: '300px 300px',
                        duration: 1
                    }, 3.5)
                    .fromTo("#cardsRow56 .bid1 svg #bid1P1", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 3.5)
                    .fromTo("#cardsRow56 .bid1 svg #bid1P2", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 3.5)
                    .fromTo("#cardsRow56 .bid1 svg #bid1P3", {strokeDasharray: '0px 200px'}, {
                        strokeDasharray: '200px 200px',
                        duration: 1
                    }, 3.5)
                    .fromTo("#cardsRow56 .bid1 .cardTitle span", {y: 60}, {y: 0, duration: .5}, 4)

                    //First line
                    .fromTo("#cardsRow56 #firstLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, 3)
                    .fromTo("#cardsRow56 #firstLine img", {y: -180}, {y: 0, duration: 1}, 3.1)

                    .fromTo("#cardsRow57 .bid2 svg #bid2R1", {strokeDasharray: '0px 300px'}, {
                        strokeDasharray: '300px 300px',
                        duration: 1
                    }, 4.5)
                    .fromTo("#cardsRow57 .bid2 svg #bid2P1", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 4.5)
                    .fromTo("#cardsRow57 .bid2 svg #bid2P2", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 4.5)
                    .fromTo("#cardsRow57 .bid2 svg #bid2P3", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 4.5)
                    .fromTo("#cardsRow57 .bid2 svg #bid2P4", {strokeDasharray: '0px 200px'}, {
                        strokeDasharray: '200px 200px',
                        duration: 1
                    }, 4.5)
                    .fromTo("#cardsRow57 .bid2 .cardTitle span", {y: 60}, {y: 0, duration: .5}, 5)

                    .fromTo("#cardsRow57 #secondLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, 3.5)
                    .fromTo("#cardsRow57 #secondLine img", {y: -580}, {y: 0, duration: 1.5}, 3.5)
                    .fromTo("#cardsRow57 #secondLine .lineGreen", {height: 0}, {height: 420, duration: 1.5}, 3.5)

                    .fromTo("#cardsRow58 .bid3 svg #bid3R1", {strokeDasharray: '0px 300px'}, {
                        strokeDasharray: '300px 300px',
                        duration: 1
                    }, 5.5)
                    .fromTo("#cardsRow58 .bid3 svg #bid3P1", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 5.5)
                    .fromTo("#cardsRow58 .bid3 svg #bid3P2", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 5.5)
                    .fromTo("#cardsRow58 .bid3 svg #bid3P3", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 5.5)
                    .fromTo("#cardsRow58 .bid3 svg #bid3P4", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 5.5)
                    .fromTo("#cardsRow58 .bid3 svg #bid3P5", {strokeDasharray: '0px 200px'}, {
                        strokeDasharray: '200px 200px',
                        duration: 1
                    }, 5.5)
                    .fromTo("#cardsRow58 .whiteCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, 6)

                    .fromTo("#cardsRow58 #thirdLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, 4)
                    .fromTo("#cardsRow58 #thirdLine img", {y: -980}, {y: 0, duration: 2}, 4)
                    .fromTo("#cardsRow58 #thirdLine .lineGreen", {height: 0}, {height: 820, duration: 2}, 4)


                gsap.timeline({
                    scrollTrigger: {
                        trigger: "#infoBlock6 .primaryBlue",
                        start: '0 399',
                        end: 4800,
                        scrub: true,
                        //markers: true
                    }
                })
                    .fromTo("#cardsRow61 .blackCard", {scale: 0}, {scale: 1, duration: .25}, 0)
                    //Arrows
                    .fromTo("#cardsRow61 .arrowTop, #cardsRow62 .lineBottom, #cardsRow63 .lineTop", {autoAlpha: 0}, {
                        autoAlpha: 1,
                        duration: .01
                    }, .4)
                    .fromTo("#cardsRow61 .arrowTop, #cardsRow63 .lineTop", {y: 90}, {y: 0, duration: .5}, .4)
                    .fromTo("#cardsRow62 .lineBottom", {y: -120}, {y: 0, duration: .5}, .4)
                    //1st Card
                    .fromTo("#cardsRow63 #affiliateCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .25}, .2)
                    .fromTo("#cardsRow63 #affiliateCard svg #dspsP1", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, .75)
                    .fromTo("#cardsRow63 #affiliateCard svg #dspsC1", {strokeDasharray: '0px 64px'}, {
                        strokeDasharray: '64px 64px',
                        duration: 1
                    }, .75)
                    .fromTo("#cardsRow63 #affiliateCard svg #dspsP2", {strokeDasharray: '0px 120px'}, {
                        strokeDasharray: '120px 120px',
                        duration: 1
                    }, .75)
                    .fromTo("#cardsRow63 #affiliateCard svg #dspsP3", {strokeDasharray: '0px 120px'}, {
                        strokeDasharray: '120px 120px',
                        duration: 1
                    }, .75)
                    .fromTo("#cardsRow63 #affiliateCard svg #dspsP4", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, .75)
                    .fromTo("#cardsRow63 #affiliateCard svg #dspsP5", {strokeDasharray: '0px 300px'}, {
                        strokeDasharray: '300px 300px',
                        duration: 1
                    }, .75)
                    .fromTo("#cardsRow63 #affiliateCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, 1)
                    //Arrow Bottom
                    .fromTo("#cardsRow64 .cardsLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, 1.25)
                    .fromTo("#cardsRow64 .cardsLine", {y: -140}, {y: 0, duration: .5}, 1.25)
                    //2nd Card
                    .fromTo("#cardsRow65 #cplCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .25}, 1.5)
                    .fromTo("#cardsRow65 #cplCard svg #cplP1", {strokeDasharray: '0px 200px'}, {
                        strokeDasharray: '200px 200px',
                        duration: .5
                    }, 1.75)
                    .fromTo("#cardsRow65 #cplCard svg #cplP2", {strokeDasharray: '0px 200px'}, {
                        strokeDasharray: '200px 200px',
                        duration: .5
                    }, 1.75)
                    .fromTo("#cardsRow65 #cplCard svg #cplE1", {strokeDasharray: '0px 200px'}, {
                        strokeDasharray: '200px 200px',
                        duration: .5
                    }, 1.75)
                    .fromTo("#cardsRow65 #cplCard svg #cplR1", {strokeDasharray: '0px 200px'}, {
                        strokeDasharray: '200px 200px',
                        duration: .5
                    }, 1.75)
                    .fromTo("#cardsRow65 #cplCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, 1.75)

                const handsTimeline = gsap.timeline({repeat: -1, paused: true})
                    .to("#cardsRow71 img", {x: 0, y: 0, rotate: 0, duration: .5}, 0)
                    .to("#cardsRow72 img", {x: 0, y: 0, rotate: 0, duration: .5}, 0)
                    .to("#cardsRow71 img", {y: -50, rotate: -5, duration: .25}, .5)
                    .to("#cardsRow72 img", {y: 50, rotate: -5, duration: .25}, .5)
                    .to("#cardsRow71 img", {y: 30, rotate: 5, duration: .5, ease: Power4.easeIn}, ">")
                    .to("#cardsRow72 img", {y: -30, rotate: 5, duration: .5, ease: Power4.easeIn}, "<")
                    .to("#cardsRow71 img", {x: -150, duration: .5}, ">")
                    .to("#cardsRow72 img", {x: 150, duration: .5}, "<")
                    .to("#cardsRow71 img", {y: 300, rotate: 0, duration: .5, ease: Power4.easeIn}, ">")
                    .to("#cardsRow72 img", {y: -300, rotate: 0, duration: .5, ease: Power4.easeIn}, "<")
                    .to("#cardsRow71 img", {x: -25, y: 300, rotate: 5, duration: .5}, ">")
                    .to("#cardsRow72 img", {x: 25, y: -300, rotate: 5, duration: .5}, "<")
                    .to("#cardsRow71 img", {y: 150, duration: .5, ease: Power4.easeIn}, ">")
                    .to("#cardsRow72 img", {y: -150, duration: .5, ease: Power4.easeIn}, "<")
                    .to("#cardsRow71 img", {x: -150, duration: .5}, ">")
                    .to("#cardsRow72 img", {x: 150, duration: .5}, "<")
                    .to("#cardsRow71 img", {x: -150, y: 0, rotate: -5, duration: .5}, ">")
                    .to("#cardsRow72 img", {x: 150, y: 0, rotate: -5, duration: .5}, "<")
                    .to("#cardsRow71 img", {x: 0, rotate: 0, duration: .5}, ">")
                    .to("#cardsRow72 img", {x: 0, rotate: 0, duration: .5}, "<");

                ScrollTrigger.create({
                    trigger: "#infoBlock7 .primaryBlue",
                    start: "0 399",
                    end: 5800,
                    //markers: true,
                    onEnter: () => handsTimeline.play()
                });

                gsap.timeline({
                    scrollTrigger: {
                        trigger: "#infoBlock8 .primaryBlue",
                        start: '0 399',
                        end: 8400,
                        scrub: true,
                    }
                })
                    .fromTo("#cardsRow81 img:nth-child(1)", {scale: 0.1, autoAlpha: 0}, {
                        scale: 1,
                        autoAlpha: 1,
                        duration: 1
                    }, 0)
                    .fromTo("#cardsRow81 img:nth-child(2)", {scale: 0.1, autoAlpha: 0}, {
                        scale: 1,
                        autoAlpha: 1,
                        duration: 1
                    }, 0.25)
                    .fromTo("#cardsRow81 img:nth-child(3)", {scale: 0.1, autoAlpha: 0}, {
                        scale: 1,
                        autoAlpha: 1,
                        duration: 1
                    }, 0.5)
                    .fromTo("#cardsRow82 img:nth-child(1)", {scale: 0.1, autoAlpha: 0}, {
                        scale: 1,
                        autoAlpha: 1,
                        duration: 1
                    }, 0.75)
                    .fromTo("#cardsRow82 img:nth-child(2)", {scale: 0.1, autoAlpha: 0}, {
                        scale: 1,
                        autoAlpha: 1,
                        duration: 1
                    }, 1)
                    .fromTo("#cardsRow83 img", {scale: 0.1, autoAlpha: 0}, {scale: 1, autoAlpha: 1, duration: 1}, 1.5)
                    .fromTo("#cardsRow84 .blackCard", {scale: 0}, {scale: 1, duration: .25}, 1.75)

                    .fromTo("#cardsRow85 .cardsLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, 2.25)
                    .fromTo("#cardsRow85 .cardsLine", {y: -140}, {y: 0, duration: .5}, 2.25)

                    .fromTo("#cardsRow87 .longLine", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.1}, 3)
                    .fromTo("#cardsRow87 .longLine .greenLine", {height: 0}, {height: 350, duration: 1}, 3)
                    .fromTo("#cardsRow87 .longLine img", {y: -420}, {y: 0, duration: 1}, 3)

                    //Noob
                    .fromTo("#cardsRow86 .whiteCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, 2)
                    .fromTo("#cardsRow86 .whiteCard svg #noobP1", {strokeDasharray: '0px 300px'}, {
                        strokeDasharray: '300px 300px',
                        duration: 1
                    }, 2.75)
                    .fromTo("#cardsRow86 .whiteCard svg #noobC1", {strokeDasharray: '0px 64px'}, {
                        strokeDasharray: '64px 64px',
                        duration: 1
                    }, 2.75)
                    .fromTo("#cardsRow86 .whiteCard svg #noobP2", {strokeDasharray: '0px 200px'}, {
                        strokeDasharray: '200px 200px',
                        duration: 1
                    }, 2.75)
                    .fromTo("#cardsRow86 .whiteCard svg #noobP3", {strokeDasharray: '0px 120px'}, {
                        strokeDasharray: '120px 120px',
                        duration: 1
                    }, 2.75)
                    .fromTo("#cardsRow86 .whiteCard svg #noobC2", {strokeDasharray: '0px 120px'}, {
                        strokeDasharray: '120px 120px',
                        duration: 1
                    }, 2.75)
                    .fromTo("#cardsRow86 .whiteCard svg #noobC3", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 2.75)
                    .fromTo("#cardsRow86 .whiteCard svg #noobP4", {strokeDasharray: '0px 120px'}, {
                        strokeDasharray: '120px 120px',
                        duration: 1
                    }, 2.75)
                    .fromTo("#cardsRow86 .whiteCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, 3)
                    .fromTo("#cardsRow86 .whiteCard .cardNum", {scale: 0, rotate: -180}, {
                        scale: 1,
                        rotate: 0,
                        duration: .5
                    }, 3)

                    //Expert
                    .fromTo("#cardsRow88 .whiteCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, 4)
                    .fromTo("#cardsRow88 .whiteCard svg #dspsP1", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 4.25)
                    .fromTo("#cardsRow88 .whiteCard svg #dspsC1", {strokeDasharray: '0px 64px'}, {
                        strokeDasharray: '64px 64px',
                        duration: 1
                    }, 4.25)
                    .fromTo("#cardsRow88 .whiteCard svg #dspsP2", {strokeDasharray: '0px 120px'}, {
                        strokeDasharray: '120px 120px',
                        duration: 1
                    }, 4.25)
                    .fromTo("#cardsRow88 .whiteCard svg #dspsP3", {strokeDasharray: '0px 120px'}, {
                        strokeDasharray: '120px 120px',
                        duration: 1
                    }, 4.25)
                    .fromTo("#cardsRow88 .whiteCard svg #dspsP4", {strokeDasharray: '0px 100px'}, {
                        strokeDasharray: '100px 100px',
                        duration: 1
                    }, 4.25)
                    .fromTo("#cardsRow88 .whiteCard svg #dspsP5", {strokeDasharray: '0px 300px'}, {
                        strokeDasharray: '300px 300px',
                        duration: 1
                    }, 4.25)
                    .fromTo("#cardsRow88 .whiteCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, 4.5)
                    .fromTo("#cardsRow88 .whiteCard .cardNum", {scale: 0, rotate: -180}, {
                        scale: 1,
                        rotate: 0,
                        duration: .5
                    }, 4.5)

            //}

        }

    }

    render() {

        const meta = {
            title: 'SSP',//data.content.title,
            description: '',
            canonical: '',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ''
                }
            }
        };

        return(
            <DocumentMeta {...meta}>
                <div id={'titleOverlay'}></div>
                <PageTitle title="SSP" />
                {this.props.isMobile ? <MobileSSP/> : <DesktopSSP/>}
                <div id={"footerOverlay"}></div>
                <Footer/>
            </DocumentMeta>
        )

    }

}
