const PageTitle = ({ title, type = null }) => {
    return (
        <div id={'pageTitle'}>
            <div className={'container'}>
                <h1 className={type ? type : ''}><span>{title}</span></h1>
            </div>
        </div>
    )
}

export default PageTitle;