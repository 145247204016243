import React, {Component} from 'react';
import DocumentMeta from "react-document-meta";
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SmoothScrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";

//Images & Icons
import SkypeIcon from '../images/icons/skype-color.svg';
import TelegramIcon from '../images/icons/telegram-color.svg';
import EmailIcon from '../images/icons/email-color.svg';

//Data
import { EMAIL, SKYPE, TELEGRAM } from '../data/contacts';

//Components
import PageTitle from '../component/PageTitle';
import {Footer} from "../component/Footer";

const FOOTER_CONTACTS = [
    {
        name: 'Email',
        icon: EmailIcon,
        link: EMAIL,
    },
    {
        name: 'Skype',
        icon: SkypeIcon,
        link: SKYPE,
    },
    {
        name: 'Telegram',
        icon: TelegramIcon,
        link: TELEGRAM,
    },
];

export class MeetUs extends Component {

    constructor(props) {

        super(props);
        // reference to the DOM node

        this.loadPage = this.loadPage.bind(this);

    }

    loadPage(){

        let tl = gsap.timeline(),
            splitTitle = new SplitText('#pageTitle h1 span', {type: "chars"});

        tl.from(splitTitle.chars,{duration: 1, y: 120, opacity: 0, stagger: 0.1}, 0);

    }

    componentDidMount() {

        gsap.registerPlugin(ScrollTrigger);

        const myThis = this;

        if(!localStorage.getItem('wasLoaded')){

            gsap.to('#pageTitle h1', {opacity: 0});

            let startInterval = setInterval(function (){

                if(!myThis.props.loading){

                    gsap.to('#pageTitle h1', {opacity: 1});

                    myThis.loadPage();

                    clearInterval(startInterval);

                }

            }, 100)

        } else {

            myThis.loadPage();

        }

        if(myThis.props.isMobile === false) {

            // Scrollbar
            SmoothScrollbar.use(OverscrollPlugin);

            let damping = 0.05;

            let scrollPositionX = 0,
                scrollPositionY = 0,
                vLogo = document.querySelector('#vLogo'),
                footer = document.querySelector('footer'),
                scrollContainer = document.querySelector('.fadePage div'),
                ScrollbarOptions = {
                    damping: damping,
                    thumbMinSize: 10,
                    renderByPixels: true,
                    alwaysShowTracks: false,
                    continuousScrolling: true,
                    plugins: {
                        overscroll: {
                            effect: 'glow',
                            glowColor: '#ffffff'
                        },
                    },
                };

            let scrollBarRoot = SmoothScrollbar.init(scrollContainer, ScrollbarOptions);

            scrollBarRoot.addListener(({offset}) => {
                scrollPositionX = offset.x;
                scrollPositionY = offset.y;
                vLogo.style.transform = 'translateY(-' + scrollPositionY + 'px)';
                footer.style.transform = myThis.props.isMobile ? 'unset' : 'translateY(' + scrollPositionY + 'px)';
            });

            scrollBarRoot.setPosition(0, 0);
            scrollBarRoot.track.xAxis.element.remove();

            ScrollTrigger.scrollerProxy(scrollContainer, {
                scrollTop(value) {
                    if (arguments.length) {
                        scrollBarRoot.scrollTop = value;
                    }
                    return scrollBarRoot.scrollTop;
                }
            });

            scrollBarRoot.addListener(ScrollTrigger.update);

            scrollBarRoot.scrollTo(0, 0);

            //EOF. Smooth Scrollbar

            //PRAVI

            let deltaY,
                currentScroll = 0,
                menuOpened = false;

            scrollBarRoot.addListener((s) => {

                deltaY = -1

                if (currentScroll < s.offset.y) {
                    deltaY = 1
                }

                currentScroll = s.offset.y;

                menuOpened = document.body.classList.contains('menuOpened') ? true : false;

                if (!menuOpened) {

                    if (currentScroll > 0) {

                        document.querySelector('header').classList.add('smalled');

                        if (deltaY < 0) {
                            document.querySelector('header').classList.remove('collapsed');
                        } else {
                            document.querySelector('header').classList.add('collapsed');
                        }

                    } else {
                        document.querySelector('header').classList.remove('collapsed', 'smalled');
                    }

                }

            });

            //EOF. PRAVI

            // Scroll Title
            ScrollTrigger.create({
                trigger: "#titleOverlay",
                start: "0% 0%",
                end: 400,
                pin: '#pageTitle',
                scroller: scrollContainer,
            });

            gsap.timeline({
                scrollTrigger: {
                    trigger: "#meetPage",
                    start: "0% 0%",
                    end: 400,
                    scroller: scrollContainer,
                    scrub: true
                }
            })
                .fromTo("footer", {opacity: 0}, {opacity: 1}, 0)
                .fromTo("#footerOverlay", {opacity: 0}, {opacity: 1}, 0)

        }

    }

    render() {

        const meta = {
            title: 'Meet Us',//data.content.title,
            description: '',
            canonical: '',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ''
                }
            }
        };

        return(
            <DocumentMeta {...meta}>
                <div id={'titleOverlay'}></div>
                <PageTitle title="Meet Us" />
                <div id={'meetPage'} className={'page'}>
                    <div className="contentBlock whiteBg">
                        <div className={'container'}>
                            <h2>We are a traffic source that owns <br className="hidden-xs" /> and operates our websites.</h2>
                            <p>Our platform offers unique opportunity — only buy traffic <br className="hidden-xs" /> from the narrow circle of sources with engaged and <br className="hidden-xs" /> interested audience.</p>
                        </div>
                    </div>
                    <div className="contentBlock blueBg">
                        <div className={'container'}>
                            <p><b style={{ fontWeight: 900 }}>Top Source Media Limited</b></p>
                            <p>Rm 7B One Capital Place 18 <br/> Luard Road Wan Chai 🌏</p>
                            <p>We are young and innovative team that loves being fun <br className="hidden-xs" /> and creative. Talk to us on any of the following <br className="hidden-xs" /> messengers
                                <span className={'meetUsIcons'}>
                                    {FOOTER_CONTACTS.map(contact => (
                                        <a key={contact.link} href={contact.link} target={'_blank'}>
                                            <img
                                                className={'meetUsIcon'}
                                                src={contact.icon}
                                                alt={contact.name}
                                            />
                                        </a>
                                    ))}
                                </span>
                            </p>
                        </div>
                    </div>

                </div>
                <div id={"footerOverlay"}></div>
                <Footer/>
            </DocumentMeta>
        )

    }

}
