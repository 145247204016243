import React, {Component} from 'react';
import DocumentMeta from "react-document-meta";
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SmoothScrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";

//Images & Icons
import SkypeIcon from '../images/icons/skype-color.svg';
import TelegramIcon from '../images/icons/telegram-color.svg';
import EmailIcon from '../images/icons/email-color.svg';

//Data
import { EMAIL, SKYPE, TELEGRAM } from '../data/contacts';

//Components
import PageTitle from '../component/PageTitle';
import {Footer} from "../component/Footer";

const FOOTER_CONTACTS = [
    {
        name: 'Email',
        icon: EmailIcon,
        link: EMAIL,
    },
    {
        name: 'Skype',
        icon: SkypeIcon,
        link: SKYPE,
    },
    {
        name: 'Telegram',
        icon: TelegramIcon,
        link: TELEGRAM,
    },
];

export class Privacy extends Component {

    constructor(props) {

        super(props);
        // reference to the DOM node

        this.loadPage = this.loadPage.bind(this);

    }

    loadPage(){

        let tl = gsap.timeline(),
            splitTitle = new SplitText('#pageTitle h1 span', {type: "chars"});

        tl.from(splitTitle.chars,{duration: 1, y: 120, opacity: 0, stagger: 0.1}, 0);

    }

    componentDidMount() {

        gsap.registerPlugin(ScrollTrigger);

        const myThis = this;

        if(!localStorage.getItem('wasLoaded')){

            gsap.to('#pageTitle h1', {opacity: 0});

            let startInterval = setInterval(function (){

                if(!myThis.props.loading){

                    gsap.to('#pageTitle h1', {opacity: 1});

                    myThis.loadPage();

                    clearInterval(startInterval);

                }

            }, 100)

        } else {

            myThis.loadPage();

        }

        if(myThis.props.isMobile === false) {

            // Scrollbar
            SmoothScrollbar.use(OverscrollPlugin);

            let damping = 0.05;

            let scrollPositionX = 0,
                scrollPositionY = 0,
                vLogo = document.querySelector('#vLogo'),
                footer = document.querySelector('footer'),
                scrollContainer = document.querySelector('.fadePage div'),
                ScrollbarOptions = {
                    damping: damping,
                    thumbMinSize: 10,
                    renderByPixels: true,
                    alwaysShowTracks: false,
                    continuousScrolling: true,
                    plugins: {
                        overscroll: {
                            effect: 'glow',
                            glowColor: '#ffffff'
                        },
                    },
                };

            let scrollBarRoot = SmoothScrollbar.init(scrollContainer, ScrollbarOptions);

            scrollBarRoot.addListener(({offset}) => {
                scrollPositionX = offset.x;
                scrollPositionY = offset.y;
                vLogo.style.transform = 'translateY(-' + scrollPositionY + 'px)';
                footer.style.transform = myThis.props.isMobile ? 'unset' : 'translateY(' + scrollPositionY + 'px)';
            });

            scrollBarRoot.setPosition(0, 0);
            scrollBarRoot.track.xAxis.element.remove();

            ScrollTrigger.scrollerProxy(scrollContainer, {
                scrollTop(value) {
                    if (arguments.length) {
                        scrollBarRoot.scrollTop = value;
                    }
                    return scrollBarRoot.scrollTop;
                }
            });

            scrollBarRoot.addListener(ScrollTrigger.update);

            scrollBarRoot.scrollTo(0, 0);

            //EOF. Smooth Scrollbar

            //PRAVI

            let deltaY,
                currentScroll = 0,
                menuOpened = false;

            scrollBarRoot.addListener((s) => {

                deltaY = -1

                if (currentScroll < s.offset.y) {
                    deltaY = 1
                }

                currentScroll = s.offset.y;

                menuOpened = document.body.classList.contains('menuOpened') ? true : false;

                if (!menuOpened) {

                    if (currentScroll > 0) {

                        document.querySelector('header').classList.add('smalled');

                        if (deltaY < 0) {
                            document.querySelector('header').classList.remove('collapsed');
                        } else {
                            document.querySelector('header').classList.add('collapsed');
                        }

                    } else {
                        document.querySelector('header').classList.remove('collapsed', 'smalled');
                    }

                }

            });

            //EOF. PRAVI

            // Scroll Title
            ScrollTrigger.create({
                trigger: "#titleOverlay",
                start: "0% 0%",
                end: 400,
                pin: '#pageTitle',
                scroller: scrollContainer,
            });

        }

    }

    render() {

        const meta = {
            title: 'Privacy Policy',//data.content.title,
            description: '',
            canonical: '',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ''
                }
            }
        };

        return(
            <DocumentMeta {...meta}>
                <div id={'titleOverlay'}></div>
                <PageTitle title="Privacy" type="smaller" />
                <div id={'termsPage'} className={'page'}>
                    <div className="contentBlock whiteBg">
                        <div className={'container'}>
                            <h1>Privacy Policy</h1>
                            <p>We want to offer the user a safe and reliable browsing experience and service on our website with the domain https://www.topsrc.com, from now on, the “Platform” or the “Website“. We have therefore implemented the present Privacy Policy that complies with the security measures required by the Hong Kong Data Protection Regulation 679/2016, of 27 April, on the protection of natural persons about the processing of personal data and the free movement of such data (hereinafter, “GDPR”), and by the Hong Kongese Organic Law 3/2018, of 5 December, on the Protection of Personal Data and Guarantee of Digital Rights (“LOPD-GDD”), to protect your privacy and be transparent with the use of your personal data.</p>
                            <p>As stated in the Legal Notice, the owner of the Platform and Controller of the processing of your personal data is:</p>
                            <ul>
                                <li>Owner of the Platform & Controller: Top Source Media Limited (henceforth, “TopSrc”)</li>
                                <li>Registered office: Rm 7B One Capital Place 18 Luard Road Wan Chai? Hong Kong</li>
                                <li>E-mail: <a href="mailto:info@topsrc.com">info@topsrc.com</a></li>
                                <li>Data Protection Officer (DPO): <a href="mailto:info@topsrc.com">info@topsrc.com</a></li>
                            </ul>
                            <p>This Privacy Policy only regulates the processing of personal data that takes place by the access, navigation, and use of the Platform. The processing of personal data that derives from the use of other services offered on the Platform shall be fully governed by the specific conditions provided in each case. For instance, the contractual relationship between TopSrc and the Publishers/Advertisers and the related data processing issues are governed by the Terms & Conditions, the Data Processing <b>Agreement (“DPA”)</b>, and any other applicable agreement.</p>
                            <p>However, this Privacy Policy provides a brief explanation of the data processing regarding the contractual relationship between TopSrc and the Publishers/Advertisers.</p>
                            <p>For anything you may need as a user, you can contact us at the aforementioned e-mail or registered office.</p>

                            <h2>1. IAB – TCF APPROVED VENDOR</h2>
                            <p>TopSrc participates in the IAB Europe Transparency & Consent Framework (“<b>TCF</b>”) and complies with its Specifications and Policies. TopSrc’s identification number within the framework is 997. As TopSrc has implemented TCF, we are GDPR-compliant when serving ads. Our clients can rest assured that we are constantly monitoring the evolving guidance and legislation.</p>
                            <p>Under the TCF v2.2, TopSrc can gather data on the legal bases of “Consent” or “Legitimate Interest”, when applicable. To this end, a full explanation of the purposes (“<b>TCF Purposes</b>”) that embrace the legitimate interest and consent can be found in section 3. e of this Privacy Policy.</p>
                            <p>&nbsp;</p>

                            <h2>2. HOW DOES TOPSRC COLLECT YOUR DATA?</h2>
                            <ul style={{ listStyleType: 'none', paddingLeft: 0}}>
                                <li><b><span className="listNum">a.</span> Collection of data from legal entities or professionals</b></li>
                            </ul>
                            <p>If you are a user contacting us but acting on behalf of a legal entity or if you are an independent professional, we will also collect your data for the provision of the requested service and may also process them for commercial purposes when you authorize us to do so, for example, by validating this policy.</p>
                            <p><b>Categories of personal data we collect:</b></p>
                            <ul>
                                <li>Identification data such as your name and email address.</li>
                            </ul>

                            <ul style={{ listStyleType: 'none', paddingLeft: 0}}>
                                <li><b><span className="listNum">b.</span> Through our corporate emails</b></li>
                            </ul>

                            <p>Through the following email addresses the user will be able to write to us and/or request the information they consider necessary to clarify doubts related to our services:<br />&ndash; info@topsrc.com: In order to receive more information about our services.<br />&ndash; info@topsrc.com: In order to resolve any questions about data protection and exercise of rights you can contact our DPO.</p>
                            <p>Categories of personal data we collect:</p>
                            <ul>
                                <li>Identification data such as your name and email address.</li>
                            </ul>

                            <ul style={{ listStyleType: 'none', paddingLeft: 0}}>
                                <li><b><span className="listNum">c.</span> Social Networks</b></li>
                            </ul>
                            <p>We may collect your data through your user profile in the social networks that we use from Facebook, Twitter, LinkedIn, YouTube, or Instagram, which are detailed in section 10 of this policy.</p>

                            <ul style={{ listStyleType: 'none', paddingLeft: 0}}>
                                <li><b><span className="listNum">d.</span> Behavior and Habits on the Website</b></li>
                            </ul>
                            <p>It is also possible that we collect information about your behavior on our Website through cookies, so the user can consult our&nbsp;Cookies Policy&nbsp;if needed.</p>

                            <p><b>Categories of personal data we collect:</b></p>
                            <ul>
                                <li>Identification data such as the IP address.</li>
                            </ul>

                            <ul style={{ listStyleType: 'none', paddingLeft: 0}}>
                                <li><b><span className="listNum">e.</span> Online forms</b></li>
                            </ul>
                            <p>Through our contact form, signup form, CV form, or any other online form on our Website, we will collect the data that are indicated and that are necessary to be able to send us your request or consultation.</p>
                            <p><b>Categories of personal data we collect:</b></p>
                            <ul>
                                <li>Identification data (name and surname, IP address, email address, address, and telephone)</li>
                                <li>Transaction data (bank account number or similar)</li>
                            </ul>

                            <ul style={{ listStyleType: 'none', paddingLeft: 0}}>
                                <li><b><span className="listNum">f.</span> Provision of service: end-user</b></li>
                            </ul>
                            <p>TopSrc helps its Advertisers promote their products and services. To do this, we enable Advertisers to customize their campaigns for the specific audiences they intend to reach, which in turn means that the end user will find ads on Publishers&rsquo; websites.&nbsp;</p>
                            <p>Therefore, we may process the data of the end user obtained through their browsing information archive files, as informed via the cookies policies of the websites of each of the Publishers (websites that show our advertising banners or other ad formats).</p>
                            <p>We regulate these processing under a DPA with our Advertisers and Publishers which complies with the different aspects of the GDPR and applicable data protection law.</p>

                            <div className="table-wrapper">
                                <table>
                                <thead>
                                    <tr>
                                        <th width="50%"><p><b>How do we obtain clients 'end users' data?</b></p></th>
                                        <th width="50%"><p><b>End users data</b></p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p><b>f.1. When an end user browses a Publisher's website using our technologies,</b> ExoClick acts as a joint controller with its Publishers. Thus, the collection of data takes place on their respective websites that we do not control. As such, our DPA also provides that they are responsible for disclosing to end users the presence of our technologies (Cookies used by the Ad Server) on their websites, and for obtaining end users' consent to use their data. In this regard, we take the necessary steps to contractually obtain proof of such consent from the end user, and, where appropriate, we reserve the right to audit our Publishers to ensure that they properly collect such consent.</p>
                                        </td>
                                        <td>
                                            <p><b>Data collected from our Publishers' websites:</b></p>
                                            <ul>
                                                <li>Operating system;</li>
                                                <li>URL of website displaying our ads;</li>
                                                <li>Referral URL;</li>
                                                <li>Browser information;</li>
                                                <li>Country, time zone, and locale settings (country and preferred language); and</li>
                                                <li>IP address</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><b>f.2. Also, when offering our programmatic services to our Advertisers,</b> the ad exchanges ('Partners') will make device-level information available to us in the form of 'Bid Request Data'. It is important to understand that all information processed is solely at the device level and cannot, by itself, be used to identify a natural person in the real world.</p>
                                            <p>Once we receive a Bid Request Data, we will help Advertisers decide how much to bid on displaying an ad based on a variety of factors. Whenever our Advertiser has the highest bid to advertise, it will win the bid request and the end user will see its ad.</p>
                                            <p>Partners often provide ExoClick with device-level data that they have collected about end users. ExoClick uses this information to create models that help the Advertiser determine what sort of end users it should target with ads.</p>
                                        </td>
                                        <td>
                                            <p><b>Data collected from our Partners:</b></p>
                                            <p><b><u>Bid Request Data:</u></b></p>
                                            <ul>
                                                <li>Advertising IDs (IDFA/GAID);</li>
                                                <li>Device characteristics;</li>
                                                <li>Operating system;</li>
                                                <li>Network carrier information;</li>
                                                <li>Browser information;</li>
                                                <li>Country, time zone, and locale settings (country and preferred language);</li>
                                                <li>City- and/or country-level geolocation data; and</li>
                                                <li>IP address</li>
                                            </ul>
                                            <p><b><u>Impression data:</u></b></p>
                                            <ul>
                                                <li>When an Advertiser wins a bid and has its ad displayed to the end user, ExoClick collects information related to this transaction including the Advertising ID (IDFA/GAID) which was shown in the ad.</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                            <p>&nbsp;</p>

                            <h2>3. WHAT IS THE PURPOSE FOR THE PROCESSING OF YOUR PERSONAL DATA?</h2>
                            <p>The purpose of data collection in all the sections mentioned in the previous point is to maintain direct and personalized contact with our users. In this way, we will use your information to manage and respond to requests, and the provision of information and services of the entity.</p>
                            <p>In no case will the user receive information from third parties without having informed and requested their prior consent, thus ensuring compliance with the limits of the law.</p>
                            <p>We will send you information about our company, its actions, events, collaborations with third parties, commercial actions, and/or any other initiative related to TopSrc depending on the type of consent that, in any case, you provide us.</p>
                            <p>The purposes of data processing by the means set out in point 1 will be, in detail, the following:</p>
                            <ul style={{ listStyleType: 'none', paddingLeft: 0}}>
                                <li><span className="listNum">a.</span> Respond to queries or requests for information that the user may make.</li>
                                <li><span className="listNum">b.</span> Send information that is considered to be of interest to the user.</li>
                                <li><span className="listNum">c.</span> Inform of novelties that we may implement in the Platform.</li>
                                <li><span className="listNum">d.</span> Notify promotional agreements that TopSrc has subscribed with other entities or collaborating companies to offer the user of the Platform, if applicable, certain functionalities or services similar to those previously acquired with TopSrc.</li>
                                <li><span className="listNum">e.</span> <b>Provide our services to clients (end users).</b></li>
                            </ul>
                            <p>These are the related purposes and legal basis of the processing for which clients &lsquo;end users&rsquo; data is intended. TopSrc can only drop cookies (Cookies used by the Ad Server) on the end user&rsquo;s device with their prior consent if they are located in the European Economic Area. The end user&rsquo;s consent will be collected by the Publisher who operates the websites or mobile app end users use. Despite the above, some cookies will not require consent where the Publisher responsible for the processing has established a legal basis (i.e. cookies that enable the functioning and provision of the services offered on the website).</p>
                            <p>&nbsp;</p>

                            <h2>4. WHO CAN BE THE RECIPIENTS OF YOUR DATA?</h2>
                            <p>As already established in the previous points, TopSrc will not provide users&rsquo; data to third parties, understood as third parties that are not directly related to the user or that do not carry out a function on behalf of or commissioned by the service provider or data controller, that is, TopSrc.</p>
                            <p>We also inform you that your data may be communicated to those third parties when there is a legal obligation to do so.</p>
                            <p>Then, your data will be communicated to those entities that provide a service to TopSrc when acting as Data Processors. In those cases, TopSrc has subscribed to confidentiality and data processing agreements with them according to the requirements under GDPR and privacy applicable law.</p>
                            <p>In addition to this, and in order to have advertising inventories on which to display ads, TopSrc participates with its Partners in real-time auctions (often called &ldquo;Real-Time Bidding&rdquo;) on marketplaces. To do this, TopSrc may synchronize the identifiers with those used by these marketplaces to participate in the auction by sending a bid and the advertising TopSrc wishes to display. This means that TopSrc must communicate to these marketplaces the identifier used to recognize the end user&rsquo;s browser or device without ever being able to identify them.</p>
                            <p>We inform you that our servers are usually located in the European Union, and we generally hire service providers also located within the European Economic Area or in countries that have been declared with an adequate level of protection.</p>
                            <p>If we need to use external service providers that require the communication of personal data outside the European Union or in countries that have not been declared with an adequate level of protection, we will ensure that we guarantee the security and legitimacy of the processing of your data employing adequacy decisions, standard clauses, binding corporate rules, exceptions or any other instrument approved by the supervisory authority that provides adequate guarantees for the performance of the international transfer of data.</p>
                            <p>&nbsp;</p>

                            <h2>5. GROUNDS FOR LAWFULNESS OF THE PROCESSING</h2>
                            <p>In compliance with the requirements of the GDPR and the LOPD-GDD, and following our internal policies, when you provide us with your express and specific consent in any form or similar, or when you write to our contact email, you expressly agree that we can process the data for the purpose or request that you have indicated.</p>
                            <p>With this/these action(s), you are freely and unequivocally declaring to us that you agree that we may process your data according to the purposes mentioned in the previous sections.</p>
                            <p>The acceptance that your data will be processed for the purposes referred to in this policy is always revocable, without retroactive effect, per the provisions of current legislation; therefore, you have the right to withdraw your consent at any time, without affecting the lawfulness of the treatment that had already been carried out based on your prior consent.</p>
                            <p>We also may process your personal data based on fulfillment of a contract, legitimate interest, and legal obligation.</p>
                            <p>&nbsp;</p>

                            <h2>6. WHAT ARE YOUR DATA PROTECTION RIGHTS?</h2>
                            <p>The GDPR and the LOPD-GDD have implemented legal guarantees that allow the user to exercise rights and actions related to the processing of their data. TopSrc offers this legal guarantee, whereby, at any time and/or when considered appropriate, the user may exercise the following rights:</p>
                            <ul>
                                <li>Access to your personal data.</li>
                                <li>Rectify inaccurate or incomplete personal data.</li>
                                <li>Request the erasure of your personal data when, among other reasons, the data is no longer necessary for the purposes for which they were collected.</li>
                                <li>Obtain from TopSrc the restriction of data processing when any of the conditions set out in data protection law are met.</li>
                                <li>Request the portability of your personal data or transfer your personal data to another Data Controller (when applicable).</li>
                                <li>Object to the processing of your personal data, following the circumstances set out in data protection legislation.</li>
                                <li>File a complaint before the relevant Supervisory Authority (www.aepd.es) when you consider that TopSrc has violated the applicable data protection law.</li>
                                <li>Where a processing operation is based on your consent, you may withdraw your consent at any time. To unsubscribe from marketing communications, you may exercise your right to revoke your consent at any time of such data processing, by clicking the unsubscribe link at the bottom of the communication submitted or by submitting your request for revocation to info@topsrc.com. Nevertheless, the withdrawal of your consent to this processing will not affect the lawfulness of the processing carried out before that moment.</li>
                                <li>Disable TopSrc&rsquo;s services in all ads based on legitimate interest (clients &lsquo;end users&rsquo;).</li>
                                <li>Withdrawal of the consent given concerning TopSrc&rsquo;s services (clients &lsquo;end users&rsquo;).&nbsp;</li>
                            </ul>
                            <p>To exercise your data protection rights, you may contact TopSrc at&nbsp;info@topsrc.com&nbsp;or their registered office: Please note that these rights may be limited and/or nuanced in some circumstances by local law.</p>
                            <p>&nbsp;</p>

                            <h2>7. RESPONSIBILITY OF THE USER</h2>
                            <p>The user:</p>
                            <ul>
                                <li>Warrants that they are over 18 years old when accessing and using the Platform. The registration and use of the Platform is only for data subjects over 18.</li>
                                <li>Warrants that the personal data they provide to TopSrc are true, exact, complete, and updated. In this sense, the user is responsible for the truthfulness of all the data they communicate and will provide updated information, when necessary, in a way that responds to their actual situation.</li>
                                <li>Warrants that they have informed third parties, where applicable, whose data have been provided to TopSrc, of the aspects contained in this Privacy Policy. The user also guarantees that they have obtained their authorization to provide their data to TopSrc.</li>
                                <li>Will be held liable for false or inaccurate information provided through the Platform and for direct or indirect damages that may be caused to TopSrc or third parties in this regard.</li>
                                <li>All personal data requested are mandatory unless expressly stated otherwise so that the refusal to provide them will result in the impossibility of providing the service.</li>
                            </ul>
                            <p>&nbsp;</p>

                            <h2>8. DATA SECURITY</h2>
                            <p>To ensure the security of our Platform, we have integrated a security system that allows us to maintain the confidentiality and integrity of the data of our users that have been sent or collected through the means mentioned in the first point.</p>
                            <p>Thus, those responsible for this Website maintain the security levels of data protection required by the GDPR and the LOPDGDDD and have established all the technical means at their disposal to prevent the loss, misuse, alteration, unauthorized access, and theft of data provided by the user through the Platform.</p>
                            <p>Notwithstanding the foregoing, as a user of our Website, you understand, accept, and understand that security measures on the Internet are not impregnable and that, therefore, you are obliged to adopt the necessary security measures that allow you to trust the veracity of our Website, in which you are entering your data. We will also do our best to always ensure the privacy and security of your personal data, using the utmost diligence and implementing the necessary measures.</p>
                            <p>Therefore, we inform the user that he/she shall be solely responsible for the security measures implemented concerning the protection of his/her data; TopSrc shall not be liable for situations where the user has not implemented the corresponding security measures, nor for the consequences thereof, as well as for causes or damages caused by third parties unrelated to TopSrc, including fortuitous and/or force majeure cases.</p>
                            <p>Following what has been established above, TopSrc cannot guarantee that unauthorized third parties may know the type, conditions, characteristics, and circumstances of the use that users make of the services and functionalities offered on the web page; or that they may access or manipulate the messages and communications of any type that users disseminate or make available to third parties through these services and functionalities. However, as a measure, some conditions of use have been provided in our&nbsp;Legal Notice.</p>
                            <p>Regarding end-user personal data, we would like to emphasize that we have appropriate technical and organizational security measures in place to protect your data against loss, alteration, unauthorized access, or any other unlawful form of processing. In particular, as mentioned above, we have contractual measures in place that enable us to obtain sufficient audit rights and evidence to ensure, where possible, that our Publishers process data appropriately and in compliance with the applicable data protection rules.</p>
                            <p>&nbsp;</p>

                            <h2>9. FOR HOW LONG WILL TopSrc KEEP YOUR DATA?</h2>
                            <p>The personal data provided will be kept for the time necessary to fulfill the purpose for which they are collected and to determine the possible responsibilities that may arise from the purpose. However, to comply with the legally established deadlines, once the service has been completed and for the legal prescription period, they will be kept blocked, adopting for this purpose the appropriate technical and organizational measures to prevent their processing, including their visualization, and being only available to judges, courts, public prosecutors or public administrations, all this to address the legal responsibilities, if any. Once the required legal period has elapsed, these data will be definitively destroyed.</p>
                            <p>Regarding clients ‘end users’ data, we keep data only for the period necessary to fulfill the purposes for which it was collected and for any additional period as required by law. Thus, for all the purposes outlined in section 3. e., we will keep personal data for a maximum of 365 days from the date of collection.</p>
                            <p>&nbsp;</p>

                            <h2>10. SOCIAL NETWORKS</h2>
                            <p>TopSrc has a corporate profile in the social networks of Facebook, Twitter, LinkedIn, YouTube, and Instagram.</p>
                            <p>Thus, under what is provided in the regulations and legislation of application of personal data protection, the company is the &lsquo;Controller&rsquo; of your data on the occasion of the existence of these profiles on social networks and the fact that you follow us, and we can follow you.</p>
                            <p>The above means that, if you decide to join our corporate profile as a follower or give a &lsquo;Like&rsquo; or similar to our content or profile, you accept this policy, where we explain your rights and how we use your data.</p>
                            <p>As Controller, we guarantee confidentiality in the processing and fulfillment of your rights, always under the effects of the applicable legislation and regulations. We also inform you that we will use these social networks to announce relevant news or information related to the services we provide or on topics that we consider to be of interest to you. The user must be aware that, using the functionalities of these social networks, they may receive on their wall or profile news with this type of information, not being responsible for this TopSrc.</p>
                            <p>Now, we also let you know that there is no link between TopSrc and these platforms or social networks, so you will accept their policy of use and conditions once you access them and/or validate their notices, terms, and conditions, in the registration procedure, the entity not being responsible for the use or processing of your data outside the strict relationship and provision of services indicated in this policy.</p>
                            <p>&nbsp;</p>

                            <h2>11. MODIFICATION OF THIS PRIVACY POLICY</h2>
                            <p>TopSrc reserves the right to modify this policy to adapt it to future legislative or jurisprudential developments, as well as to future uses that it plans to make of the personal data of the users of the Platform. If this modification affects you concerning the processing of your data, for example, because additional processing of your data is carried out, and we have not previously informed you, we will proceed to notify you.</p>
                            <p>It is suggested, in any case, that the user proceed to read this policy each time they access it.</p>
                            <p>&nbsp;</p>

                            <h2>12. HOW DOES TOPSRC COLLECT YOUR DATA?</h2>
                            <p>This Privacy Policy is complemented by the Legal Notice and the Cookies Policy, in addition to the rest of the information provided through the Website, including the Terms & Conditions and the DPA that governs the contractual relationship between TopSrc and the Publishers/Advertisers.</p>
                            <p><b>Last updated:</b> June 2024</p>

                        </div>
                    </div>
                </div>
                <div id={"footerOverlay"}></div>
                <Footer/>
            </DocumentMeta>
        )

    }

}
