import React, {Component} from 'react';
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import DocumentMeta from "react-document-meta";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SmoothScrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";

//Components
import Accordion from "../component/Accordion";
import {Footer} from "../component/Footer";

export class Tech extends Component {

    constructor(props) {

        super(props);
        // reference to the DOM node

        console.log(props);

        this.loadPage = this.loadPage.bind(this);
        this.FooterScrollTrigger = null;
        this.endPoint = 0;

    }

    loadPage(){

        let tl = gsap.timeline(),
            splitTitle = new SplitText('.techText', {type: "lines"});

        tl.from(splitTitle.lines,{duration: 1, y: 120, opacity: 0, stagger: 0.1}, 0);
        document.querySelectorAll('#techAccordion .expandedText').forEach(function (item, index){
            tl.from(item,{y: 120, opacity: 0, duration: .5}, .5 + index/10);
        })

    }

    componentDidMount() {

        gsap.registerPlugin(ScrollTrigger);

        const myThis = this;

        if(!localStorage.getItem('wasLoaded')){

            gsap.to('.techText', {opacity: 0});

            let startInterval = setInterval(function (){

                if(!myThis.props.loading){

                    gsap.to('.techText', {opacity: 1});

                    myThis.loadPage();

                    clearInterval(startInterval);

                }

            }, 100)

        } else {

            myThis.loadPage();

        }

        if(myThis.props.isMobile === false) {

            // Scrollbar
            SmoothScrollbar.use(OverscrollPlugin);

            let damping = 0.05;

            let scrollPositionX = 0,
                scrollPositionY = 0,
                vLogo = document.querySelector('#vLogo'),
                footer = document.querySelector('footer'),
                scrollContainer = document.querySelector('.fadePage div'),
                ScrollbarOptions = {
                    damping: damping,
                    thumbMinSize: 10,
                    renderByPixels: true,
                    alwaysShowTracks: false,
                    continuousScrolling: true,
                    plugins: {
                        overscroll: {
                            effect: 'glow',
                            glowColor: '#ffffff'
                        },
                    },
                };

            let scrollBarRoot = SmoothScrollbar.init(scrollContainer, ScrollbarOptions);

            scrollBarRoot.addListener(({offset}) => {
                scrollPositionX = offset.x;
                scrollPositionY = offset.y;
                vLogo.style.transform = 'translateY(-' + scrollPositionY + 'px)';
                footer.style.transform = myThis.props.isMobile ? 'unset' : 'translateY(' + scrollPositionY + 'px)';
            });

            scrollBarRoot.setPosition(0, 0);
            scrollBarRoot.track.xAxis.element.remove();

            ScrollTrigger.scrollerProxy(scrollContainer, {
                scrollTop(value) {
                    if (arguments.length) {
                        scrollBarRoot.scrollTop = value;
                    }
                    return scrollBarRoot.scrollTop;
                }
            });

            scrollBarRoot.addListener(ScrollTrigger.update);

            scrollBarRoot.scrollTo(0, 0);

            //EOF. Smooth Scrollbar

            //PRAVI

            let deltaY,
                currentScroll = 0,
                menuOpened = false;

            scrollBarRoot.addListener((s) => {

                deltaY = -1

                if (currentScroll < s.offset.y) {
                    deltaY = 1
                }

                currentScroll = s.offset.y;

                menuOpened = document.body.classList.contains('menuOpened') ? true : false;

                if (!menuOpened) {

                    if (currentScroll > 0) {

                        document.querySelector('header').classList.add('smalled');

                        if (deltaY < 0) {
                            document.querySelector('header').classList.remove('collapsed');
                        } else {
                            document.querySelector('header').classList.add('collapsed');
                        }

                    } else {
                        document.querySelector('header').classList.remove('collapsed', 'smalled');
                    }

                }

            });

            //EOF. PRAVI

        }

    }

    render() {

        const meta = {
            title: 'Tech',
            description: 'In addition to standard attributes, topsrc offers a bunch of other useful features for cost-effective media buying.',
            canonical: '',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ''
                }
            }
        };

        return(
            <DocumentMeta {...meta}>
                <div id={'techPage'} className={'page'}>
                    <div className={'container'}>
                        <p className={'techText'}>In addition to standard attributes, topsrc offers a bunch of other useful features for cost-effective media buying. let's point out a few:</p>
                        <Accordion />
                    </div>
                </div>
                <div id={"footerOverlay"}></div>
                <Footer/>
            </DocumentMeta>
        )

    }

}
