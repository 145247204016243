import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {gsap} from "gsap";
import isMobile from "is-mobile";

//Styles
import "./styles/normalize.css"
import "./styles/fonts.css"
import "./styles/main.css"

//Components
import {Pravi} from "./component/Pravi";
import {Preloader} from "./component/Preloader";
import Cursor from './component/Cursor';

//Pages & Blocks
import {Home} from "./pages/Home";
import {DSP} from "./pages/DSP";
import {SSP} from "./pages/SSP";
import {Tech} from "./pages/Tech";
import {MeetUs} from "./pages/MeetUs";
import {Login} from "./pages/Login";
import {Registration} from "./pages/Registration";
import {Recovery} from "./pages/Recovery";
import {Terms} from "./pages/Terms";
import {Privacy} from "./pages/Privacy";

class App extends Component {

    constructor() {

        super();

        this.rotateLogo = this.rotateLogo.bind(this);

        this.cursor = React.createRef();

        this.isMobile = isMobile();

        if(window.innerWidth < 1200 || window.innerHeight < 650){
            this.isMobile = true;
        }

        localStorage.setItem('isMobile', this.isMobile);

        const loading = !localStorage.getItem('wasLoaded');
        //const loading = true;

        this.state = {
            loading: loading,
            start: false,
            isMobile: this.isMobile,
        }

        this.resize = this.resize.bind(this);

    }

    completeLoading = status => {

        console.log('status:', status);

        const myThis = this;

        myThis.setState({loading: status});

        if(status === false){

            // Remember state for the next mount
            localStorage.setItem('wasLoaded', true);

        }

    }

    rotateLogo() {

        gsap.fromTo('.logo #logoCircle',  {rotation: '0deg'},{rotation: '360deg', transformOrigin:"50% 50%", duration: .8});

    }

    componentDidUpdate(prevProps) {

        const myThis = this;

        if (this.props.location.pathname !== prevProps.location.pathname) {

            //Updating cursor
            myThis.cursor.current.forceUpdateHandler();

        }

    }

    resize = () => {

        const myThis = this;

        myThis.isMobile = isMobile();

        if(window.innerWidth < 1200 || window.innerHeight < 650){
            myThis.isMobile = true;
        }

        if(myThis.isMobile){

            document.body.classList.add('isMobile');

            if(window.orientation !== 90 || window.orientation !== -90){
                document.body.classList.add('albumOrientation')
            } else {
                document.body.classList.remove('albumOrientation')
            }

        } else {
            document.body.classList.remove('isMobile','albumOrientation');
        }

        // If set to mobile reloading page!!!
        if(localStorage.getItem('isMobile').toString() != myThis.isMobile.toString()){

            //reload
            window.location.reload();

        }

    };

    componentDidMount() {

        const myThis = this;

        if(myThis.isMobile){

            if(window.orientation !== 90 || window.orientation !== -90){

                document.body.classList.add('albumOrientation')

            }

        }

        window.addEventListener('resize', myThis.resize)

        myThis.resize();

    }

    componentWillUnmount() {

        const myThis = this;

        window.removeEventListener('resize', myThis.resize)

    }

    render() {

        const timeout = {
            enter: 600,
            exit: 600,
        }

        return(

            <Route render={({location}) => (

                <main>

                    {this.state.loading ? <Preloader loading={this.completeLoading} /> : null}

                    <Pravi toggleMenu={this.toggleMenu} history={this.props.history} isMobile={this.state.isMobile} />

                    <TransitionGroup component="div" className="fadePage">

                        <CSSTransition
                            key={location.key}
                            timeout={timeout}
                            classNames="fade"
                            onEnter={e => {
                                this.rotateLogo();
                            }}
                            onExit={e => {
                                window.scrollTo(0, 0);
                            }}
                            unmountOnExit
                        >

                            <Switch location={location}>

                                <Route path='/'
                                       exact
                                       render={(props) => (
                                           <Home {...props} loading={this.state.loading} isMobile={this.state.isMobile} />
                                       )}
                                />

                                <Route path='/dsp'
                                       render={(props) => (
                                           <DSP {...props} isMobile={this.state.isMobile} />
                                       )}
                                />

                                <Route path='/ssp'
                                       render={(props) => (
                                           <SSP {...props} isMobile={this.state.isMobile} />
                                       )}
                                />

                                <Route path='/tech'
                                       render={(props) => (
                                           <Tech {...props} isMobile={this.state.isMobile} />
                                       )}
                                />

                                <Route path='/meet_us'
                                       render={(props) => (
                                           <MeetUs {...props} isMobile={this.state.isMobile} />
                                       )}
                                />

                                <Route path='/login'
                                       render={(props) => (
                                           <Login {...props} isMobile={this.state.isMobile} />
                                       )}
                                />

                                <Route path='/registration'
                                       render={(props) => (
                                           <Registration {...props} isMobile={this.state.isMobile} />
                                       )}
                                />

                                <Route path='/recovery'
                                       render={(props) => (
                                           <Recovery {...props} isMobile={this.state.isMobile} />
                                       )}
                                />

                                <Route path='/terms'
                                       render={(props) => (
                                           <Terms {...props} isMobile={this.state.isMobile} />
                                       )}
                                />

                                <Route path='/privacy'
                                       render={(props) => (
                                           <Privacy {...props} isMobile={this.state.isMobile} />
                                       )}
                                />

                                <Redirect to="/" />

                            </Switch>

                        </CSSTransition>

                    </TransitionGroup>

                    <Cursor ref={this.cursor} />

                </main>

            )}/>

        );

    }

}

export default App;
