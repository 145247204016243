export default [
    {
        text: 'Hey sweety 😘',
        class: 'messageLeft',
    },
    {
        text: 'Do you want to buy the hottest traffic on the market?',
        class: 'messageLeft',
    },
    {
        text: 'Hell yeah! 😈',
        class: 'messageRight messageEdited',
    },
    {
        text: 'Be my guest 😉',
        class: 'messageLeft',
    }
]