const Invest = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="145" height="144" viewBox="0 0 145 144">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g transform="translate(-1008 -3961) translate(720 3319) translate(27 162) translate(193.5 445) translate(68 35)">
                                    <path d="M0 0H144V144H0z"/>
                                    <rect id="investR1" width="45" height="26" x="10.071" y="99.414" stroke="#90A2AB" strokeLinejoin="round" strokeWidth="4" rx="2" transform="rotate(45 32.571 112.414)"/>
                                    <path id="investP1" stroke="#90A2AB" strokeWidth="4" d="M53 114h39.373c4.243 0 8.313-1.686 11.313-4.686L132.5 80.5c2.485-2.485 2.485-6.515 0-9-2.626-2.626-6.727-3.063-9.848-1.05L97 87h0"/>
                                    <path id="investP2" stroke="#90A2AB" strokeWidth="4" d="M112.72 77.001c.184-1.635.28-3.305.28-5.001 0-20.435-13.88-37-31-37-17.12 0-31 16.565-31 37 0 1.157.045 2.302.132 3.433"/>
                                    <g stroke="#90A2AB" strokeWidth="4">
                                        <path id="investP3" d="M9 24L9 30M9 0L9 6M18 6H4c-1.105 0-2 .895-2 2v5c0 1.105.895 2 2 2h11c1.105 0 2 .895 2 2v5c0 1.105-.895 2-2 2H0h0" transform="translate(73 44)"/>
                                    </g>
                                    <path id="investP4" stroke="#90A2AB" strokeLinejoin="round" strokeWidth="4" d="M77 25l-8-14.167C73.332 8.944 77.998 8 83 8c5.002 0 9.335.944 13 2.833L88 25"/>
                                    <path id="investP5" stroke="#90A2AB" strokeWidth="4" d="M72 98h17c4.418 0 8-3.582 8-8 0-4.203-3.407-7.61-7.61-7.61-.127 0-.253.003-.38.01l-5.54.276c-3.599.18-7.153-.86-10.088-2.952C68.966 76.574 63.172 75 56 75c-9.333 0-18 4.667-26 14M68 98L64 98M75.556 36C72.487 36 70 33.694 70 30.85c0-2.78 2-4.214 5.347-5.147 3.348-.933 11.653-.94 14.097-.004C92 26.636 95 28.005 95 30.85c0 2.78-2.376 5.046-5.347 5.147l-.209.004"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Invest;