import React, {Component} from "react";
import { NavLink } from 'react-router-dom'
import logo from "../images/logo-text.svg";
import skype from "../images/icons/skype.svg";
import telegram from "../images/icons/telegram.svg";
import email from "../images/icons/email.svg";

export class Footer extends Component{

	constructor() {
		super();
	}

	render() {

		return(
			<footer>
				<div className={"container"}>
					<div id={"footerLogoBlock"}>
						<NavLink to="/">
							<img src={logo} id={"logoFooter"} alt="TopSrc" />
						</NavLink>
						<p className={'name'}><b>Top Source Media Limited</b></p>
						<p className={'contacts'}>Rm 7B One Capital Place 18 <br/> Luard Road Wan Chai</p>
						<ul className={'copySocial'}>
							<li className={'skype'}><a href={'skype:live:igor.korobka?chat'} target={'_blank'}><img src={skype} alt={'Skype'} /></a></li>
							<li className={'telegram'}><a href={'tg://resolve?domain=@topsrc'} target={'_blank'}><img src={telegram} alt={'Telegram'} /></a></li>
							<li className={'email'}><a href={'mailto:sales@topsrc.com'}><img src={email} alt={'Email'} /></a></li>
						</ul>
					</div>
					<div id={"footerMenuBlock"}>
						<nav>
							<li><NavLink to="/dsp">DSP</NavLink></li>
							<li><NavLink to="/ssp">SSP</NavLink></li>
							<li><NavLink to="/tech">Tech</NavLink></li>
							<li><NavLink to="/meet_us">Meet Us</NavLink></li>
							<li><NavLink to="/login">Login</NavLink></li>
							<li><NavLink to="/registration">Register</NavLink></li>
						</nav>
					</div>
					<div id={"footerCopyBlock"}>
						<p className={'copyTitle'}>TOPSRC &copy; 2021 All Rights Reserved.</p>
						<p className={'copyText'}>If you have a project or a cool idea that you need help to bring into existence, then <NavLink to={'/meet_us'}>let us know</NavLink>! We look forward to hearing from you.</p>
						<p className={'copyText'}><NavLink to={'/terms'}>Terms & Conditions</NavLink> | <NavLink to={'/privacy'}>Privacy Policy</NavLink></p>

					</div>
				</div>
			</footer>
		)

	}

}