const Bid1 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 144 144">
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g transform="translate(-816 -898) translate(720 401) translate(27 160) translate(1 302) translate(68 35)">
                                    <path d="M0 0H144V144H0z"/>
                                    <rect id="bid1R1" width="71" height="46.457" x="44" y="13.272" stroke="#90A2AB" stroke-width="4" rx="2"/>
                                    <path id="bid1P1" stroke="#90A2AB" stroke-width="4" d="M80 60L80 84"/>
                                    <g stroke="#90A2AB" stroke-width="4">
                                        <path id="bid1P2" d="M9 24L9 30M9 0L9 6M18 6H4c-1.105 0-2 .895-2 2v5c0 1.105.895 2 2 2h11c1.105 0 2 .895 2 2v5c0 1.105-.895 2-2 2H0h0" transform="translate(71 21)"/>
                                    </g>
                                    <path id="bid1P3" stroke="#90A2AB" stroke-width="4" d="M80 96L80 124M100 115L80 115M100 107L80 107M100 98L89 98M89 89h6c2.761 0 5 2.239 5 5v26c0 2.21-1.79 4-4 4H69l-8.414 8.414c-.375.375-.884.586-1.414.586H43m-4 0h-4m-3 0h-5c-1.105 0-2-.895-2-2v-1.586c0-.265.105-.52.293-.707l45.121-45.121c.375-.375.884-.586 1.414-.586H87c1.105 0 2 .895 2 2v20M50 104L70 124"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Bid1;