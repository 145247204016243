import React, {Component} from 'react';
import DocumentMeta from "react-document-meta";
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SmoothScrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";

//Components
import PageTitle from '../component/PageTitle';
import {Footer} from "../component/Footer";
import {DesktopDSP} from "../component/DesktopDSP";
import {MobileDSP} from "../component/MobileDSP";

export class DSP extends Component {

    constructor(props) {

        super(props);
        // reference to the DOM node

        this.loadPage = this.loadPage.bind(this);

        this.state = {
            currentSlideNum: 0,
            playing1: false,
            playing2: false,
            playing3: false,
        }

    }

    loadPage(){

        let tl = gsap.timeline(),
            splitTitle = new SplitText('#pageTitle h1 span', {type: "chars"});

        tl.from(splitTitle.chars,{duration: 1, y: 120, opacity: 0, stagger: 0.2}, 0);

    }

    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     *
     * @returns {String}
     */
    getMobileOperatingSystem() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    componentDidMount() {

        gsap.registerPlugin(ScrollTrigger);

        const myThis = this;

        if(!localStorage.getItem('wasLoaded')){

            gsap.to('#pageTitle h1', {opacity: 0});

            let startInterval = setInterval(function (){

                if(!myThis.props.loading){

                    gsap.to('#pageTitle h1', {opacity: 1});

                    myThis.loadPage();

                    clearInterval(startInterval);

                }

            }, 100)

        } else {

            myThis.loadPage();

        }

        //EOF. PRAVI

        if(myThis.props.isMobile === false){

            // Scrollbar
            SmoothScrollbar.use(OverscrollPlugin);

            let damping = myThis.props.isMobile ? 0.2 : 0.1;

            let scrollPositionX = 0,
                scrollPositionY = 0,
                vLogo = document.querySelector('#vLogo'),
                footer = document.querySelector('footer'),
                scrollContainer = document.querySelector('.fadePage div'),
                ScrollbarOptions = {
                    damping: damping,
                    thumbMinSize: 10,
                    renderByPixels: true,
                    alwaysShowTracks: false,
                    continuousScrolling: true,
                    plugins: {
                        overscroll: {
                            effect: 'glow',
                            glowColor: '#ffffff'
                        },
                    },
                };

            let scrollBarRoot = SmoothScrollbar.init(scrollContainer, ScrollbarOptions);

            scrollBarRoot.addListener(({ offset }) => {
                scrollPositionX = offset.x;
                scrollPositionY = offset.y;
                vLogo.style.transform = 'translateY(-' + scrollPositionY + 'px)';
                footer.style.transform = myThis.props.isMobile ? 'unset' : 'translateY(' + scrollPositionY + 'px)';
            });

            scrollBarRoot.setPosition(0,0);
            scrollBarRoot.track.xAxis.element.remove();

            ScrollTrigger.scrollerProxy(scrollContainer, {
                scrollTop(value) {
                    if (arguments.length) {
                        scrollBarRoot.scrollTop = value;
                    }
                    return scrollBarRoot.scrollTop;
                }
            });

            scrollBarRoot.addListener(ScrollTrigger.update);

            scrollBarRoot.scrollTo(0, 0);

            //EOF. Smooth Scrollbar

            //PRAVI

            let deltaY,
                currentScroll = 0,
                menuOpened = false;

            scrollBarRoot.addListener((s) => {

                deltaY = -1

                if(currentScroll < s.offset.y){
                    deltaY = 1
                }

                currentScroll = s.offset.y;

                menuOpened = document.body.classList.contains('menuOpened') ? true : false;

                if(!menuOpened){

                    if(currentScroll > 0){

                        document.querySelector('header').classList.add('smalled');

                        if (deltaY < 0){
                            document.querySelector('header').classList.remove('collapsed');
                        } else {
                            document.querySelector('header').classList.add('collapsed');
                        }

                    } else {
                        document.querySelector('header').classList.remove('collapsed', 'smalled');
                    }

                }

            });

            // Scroll Title
            ScrollTrigger.create({
                trigger: "#titleOverlay",
                start: "0% 0%",
                end: 400,
                pin: '#pageTitle',
                scroller: scrollContainer,
            });

            gsap.timeline({
                scrollTrigger: {
                    trigger: "#dspPage",
                    start: "0% 0%",
                    end: 400,
                    scroller: scrollContainer,
                    scrub: true
                }
            })
                .fromTo("footer", {opacity: 0}, {opacity: 1}, 0)
                .fromTo("#footerOverlay", {opacity: 0}, {opacity: 1}, 0)

            gsap.timeline({
                scrollTrigger: {
                    trigger: "#infoBlock1",
                    start: "0% 399px",
                    end: 840,
                    scrub: true,
                    pin: '#infoBlock1 .infoDescription',
                    scroller: scrollContainer,
                    //markers: true
                }
            })
                //Go 1 White Card
                .fromTo("#cardsRow11 .whiteCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .25}, 0)
                .fromTo("#cardsRow11 .cardIcon svg #dspsP5", {strokeDasharray: '0px 300px'}, {strokeDasharray: '300px 300px'}, .1)
                .fromTo("#cardsRow11 .cardIcon svg #dspsC1", {strokeDasharray: '0px 64px'}, {strokeDasharray: '64px 64px'}, .1)
                .fromTo("#cardsRow11 .cardIcon svg #dspsP1", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, .25)
                .fromTo("#cardsRow11 .cardIcon svg #dspsP2", {strokeDasharray: '0px 120px'}, {strokeDasharray: '120px 120px'}, .25)
                .fromTo("#cardsRow11 .cardIcon svg #dspsP3", {strokeDasharray: '0px 120px'}, {strokeDasharray: '120px 120px'}, .25)
                .fromTo("#cardsRow11 .cardIcon svg #dspsP4", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, .25)
                .fromTo("#cardsRow11 .whiteCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, .25)
                //Go 1 Black Card
                .fromTo("#cardsRow13 .blackCard", {scale: 0}, {scale: 1, duration: .25}, .15)
                //Go 1 Lines
                .fromTo("#cardsRow11 .whiteCard .pinkArrow, #cardsRow12 .lineGreen, #cardsRow13 .pinkLineTop", {opacity: 0}, {opacity: 1, duration: .01}, .5)
                .fromTo("#cardsRow11 .whiteCard .pinkArrow", {y: 60}, {y: 0, duration: .5}, .5)
                .fromTo("#cardsRow12 .lineGreen", {y: -120}, {y: 0, duration: .5}, .5)
                .fromTo("#cardsRow13 .pinkLineTop", {y: 60}, {y: 0, duration: .5}, .5)
                //Tips 1
                .fromTo("#cardsRow12 .lineBottom .arrowTip .arrowTipPoint, #cardsRow12 .lineTop .arrowTip .arrowTipPoint", {scale: 0}, {scale: 1, duration: .25}, .75)
                .fromTo("#cardsRow12 .lineBottom .arrowTip .arrowTipLine, #cardsRow12 .lineTop .arrowTip .arrowTipLine", {width: 0}, {width: 100, duration: .15}, 1)
                .fromTo("#cardsRow12 .lineBottom .arrowTip .arrowTipWrapper, #cardsRow12 .lineTop .arrowTip .arrowTipWrapper", {scale: 0}, {scale: 1, duration: .25}, 1.15)
                .fromTo("#cardsRow12 .lineBottom .arrowTip .arrowTipWrapper .arrowTipText, #cardsRow12 .lineTop .arrowTip .arrowTipWrapper .arrowTipText", {opacity: 0}, {opacity: 1, duration: .25}, 1.3)
                //Go 2 White Card
                .fromTo("#cardsRow15 .whiteCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .25}, 1.5)
                .fromTo("#cardsRow15 .cardIcon svg #sspsP5", {strokeDasharray: '0px 300px'}, {strokeDasharray: '300px 300px'}, 1.6)
                .fromTo("#cardsRow15 .cardIcon svg #sspsC1", {strokeDasharray: '0px 64px'}, {strokeDasharray: '64px 64px'}, 1.6)
                .fromTo("#cardsRow15 .cardIcon svg #sspsP1", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, 2)
                .fromTo("#cardsRow15 .cardIcon svg #sspsP2", {strokeDasharray: '0px 120px'}, {strokeDasharray: '120px 120px'}, 2)
                .fromTo("#cardsRow15 .cardIcon svg #sspsP3", {strokeDasharray: '0px 120px'}, {strokeDasharray: '120px 120px'}, 2)
                .fromTo("#cardsRow15 .cardIcon svg #sspsP4", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, 2)
                .fromTo("#cardsRow15 .whiteCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, 2)
                //Go 2 Lines
                .fromTo("#cardsRow14 .lineGreen", {y: -120}, {y: 0, duration: .5}, 1.75)
                .fromTo("#cardsRow13 .blackCard .pinkLineBottom, #cardsRow14 .lineGreen, #cardsRow15 .pinkLine", {opacity: 0}, {opacity: 1, duration: .01}, 1.75)
                .fromTo("#cardsRow13 .blackCard .pinkLineBottom", {y: 60}, {y: 0, duration: .5}, 1.75)
                .fromTo("#cardsRow15 .pinkLine", {y: 60}, {y: 0, duration: .5}, 1.75)
                //Tips 2
                .fromTo("#cardsRow14 .lineBottom .arrowTip .arrowTipPoint, #cardsRow14 .lineTop .arrowTip .arrowTipPoint", {scale: 0}, {scale: 1, duration: .25}, 2)
                .fromTo("#cardsRow14 .lineBottom .arrowTip .arrowTipLine, #cardsRow14 .lineTop .arrowTip .arrowTipLine", {width: 0}, {width: 125, duration: .15}, 2.1)
                .fromTo("#cardsRow14 .lineBottom .arrowTip .arrowTipWrapper, #cardsRow14 .lineTop .arrowTip .arrowTipWrapper", {scale: 0}, {scale: 1, duration: .25}, 2.25)
                .fromTo("#cardsRow14 .lineBottom .arrowTip .arrowTipWrapper .arrowTipText, #cardsRow14 .lineTop .arrowTip .arrowTipWrapper .arrowTipText", {opacity: 0}, {opacity: 1, duration: .25}, 2.4)

            gsap.timeline({
                scrollTrigger: {
                    trigger: "#infoBlock2",
                    start: '0 399',
                    end: 2320,
                    scrub: true,
                    pin: '#infoBlock2 .infoDescription',
                    scroller: scrollContainer,
                    //markers: true
                }
            })
                //Magnet
                .fromTo("#cardsRow21 #card211", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, 0)
                .fromTo("#cardsRow21 #card211 svg #MagnetIcon #MagnetP1", {strokeDasharray: '0px 500px'}, {strokeDasharray: '500px 500px', duration: .25}, 0.25)
                .fromTo("#cardsRow21 #card211 svg #MagnetIcon #MagnetP2", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px', duration: .5}, 0.25)

                .fromTo("#cardsRow21 #card211 svg #MagnetFlash1", {scale: 0, x: 10, y: 20, rotate: -50}, {scale: 1, x: 10, y: 20, rotate: -50, transformOrigin: '50% 50%', duration: 1}, 0.5)
                .fromTo("#cardsRow21 #card211 svg #MagnetFlash2", {scale: 0, x: 15, y: 30}, {scale: 1, x: 15, y: 30, transformOrigin: '50% 50%', duration: 1}, 0.5)
                .fromTo("#cardsRow21 #card211 svg #MagnetFlash3", {scale: 0, x: 20, y: 20}, {scale: 1, x: 20, y: 20, transformOrigin: '50% 50%', duration: 1}, 0.5)

                .fromTo("#cardsRow21 #card211 svg #MagnetHeart1", {scale: 0, x: 65, y: 90}, {scale: .7, x: 65, y: 90, transformOrigin: '50% 50%', duration: 1}, 1)
                .fromTo("#cardsRow21 #card211 svg #MagnetHeart2", {scale: 0, x: 115, y: 40}, {scale: .7, x: 115, y: 40, transformOrigin: '50% 50%', duration: 1}, 1)
                .fromTo("#cardsRow21 #card211 svg #MagnetHeart3", {scale: 0, x: 165, y: 90}, {scale: .7, x: 165, y: 90, transformOrigin: '50% 50%', duration: 1}, 1)

                .fromTo("#cardsRow22 .lineGreen", {opacity: 0}, {opacity: 1, duration: .01}, 1)
                .fromTo("#cardsRow22 .lineGreen", {y: -150}, {y: 0, duration: .5}, 1)

                .fromTo("#cardsRow23 .triangleCard", {scale: 0}, {scale: 1, duration: .5}, 1.1)
                .fromTo("#cardsRow23 .triangleCard ul li#triangleCardOne", {width: 0}, {width: 354, duration: .25}, 1.25)
                .fromTo("#cardsRow23 .triangleCard ul li#triangleCardOne .triangleCardText", {y: -70}, {y: 0, duration: .25}, 1.5)

                .fromTo("#cardsRow23 .triangleCard ul li#triangleCardTwo", {width: 0}, {width: 286, duration: .25}, 1.5)
                .fromTo("#cardsRow23 .triangleCard ul li#triangleCardTwo .triangleCardText", {y: -50}, {y: 0, duration: .25}, 1.75)
                .fromTo("#cardsRow23 .triangleCard ul li#triangleCardTwo .triangleArrow", {y: -25}, {y: 0, duration: .25}, 1.9)

                .fromTo("#cardsRow23 .triangleCard ul li#triangleCardThree", {width: 0}, {width: 216, duration: .25}, 1.9)
                .fromTo("#cardsRow23 .triangleCard ul li#triangleCardThree .triangleCardText", {y: -50}, {y: 0, duration: .25}, 2.15)
                .fromTo("#cardsRow23 .triangleCard ul li#triangleCardThree .triangleArrow", {y: -25}, {y: 0, duration: .25}, 2.25)

                .fromTo("#cardsRow23 .triangleCard ul li#triangleCardFour", {width: 0}, {width: 150, duration: .25}, 2.25)
                .fromTo("#cardsRow23 .triangleCard ul li#triangleCardFour .triangleCardText", {y: -50}, {y: 0, duration: .25}, 2.5)
                .fromTo("#cardsRow23 .triangleCard ul li#triangleCardFour .triangleArrow", {y: -25}, {y: 0, duration: .25}, 2.6)

                .fromTo("#cardsRow24 .lineGreen", {opacity: 0}, {opacity: 1, duration: .01}, 2.6)
                .fromTo("#cardsRow24 .lineGreen", {y: -150}, {y: 0, duration: .5}, 2.6)

                //Go 2 White Card
                .fromTo("#cardsRow25 .whiteCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .25}, 2.8)
                .fromTo("#cardsRow25 .cardIcon svg", {opacity: 0}, {opacity: 1, duration: 0.1}, 3)
                .fromTo("#cardsRow25 .cardIcon svg #aimP1", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 3)
                .fromTo("#cardsRow25 .cardIcon svg #aimP2", {strokeDasharray: '0px 64px'}, {strokeDasharray: '64px 64px'}, 3)
                .fromTo("#cardsRow25 .cardIcon svg #aimP3", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, 3)
                .fromTo("#cardsRow25 .cardIcon svg #aimP4", {strokeDasharray: '0px 120px'}, {strokeDasharray: '120px 120px'}, 3)
                .fromTo("#cardsRow25 .cardIcon svg #aimP5", {strokeDasharray: '0px 120px'}, {strokeDasharray: '120px 120px'}, 3)
                .fromTo("#cardsRow25 .cardIcon svg #aimP6", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, 3)
                .fromTo("#cardsRow25 .cardIcon svg #aimP7", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, 3)
                .fromTo("#cardsRow25 .cardIcon svg #aimC1", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 3)
                .fromTo("#cardsRow25 .cardIcon svg #aimC2", {strokeDasharray: '0px 320px'}, {strokeDasharray: '320px 320px'}, 3)
                .fromTo("#cardsRow25 .whiteCard #heartCircleOne", {left: -100, bottom: -100, scale: 0}, {left: -45, bottom: -45, scale: 1, duration: .25}, 3.5)
                .fromTo("#cardsRow25 .whiteCard #heartCircleTwo", {right: -100, top: -100, scale: 0}, {right: -45, top: -45, scale: 1, duration: .25}, 3.5)

            gsap.timeline({
                scrollTrigger: {
                    trigger: "#infoBlock3",
                    start: '0 399',
                    end: 3320,
                    scrub: true,
                    pin: '#infoBlock3 .infoDescription',
                    scroller: scrollContainer,
                    //markers: true
                }
            })
                .fromTo("#cardsRow31 .blackCard", {scale: 0}, {scale: 1, duration: .5}, 0)
                .fromTo("#cardsRow33 .whiteCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, .5)
                .fromTo("#cardsRow33 .whiteCard svg #investR1", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 1.5)
                .fromTo("#cardsRow33 .whiteCard svg #investP1", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 1.5)
                .fromTo("#cardsRow33 .whiteCard svg #investP2", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 1.5)
                .fromTo("#cardsRow33 .whiteCard svg #investP3", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 1.5)
                .fromTo("#cardsRow33 .whiteCard svg #investP4", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 1.5)
                .fromTo("#cardsRow33 .whiteCard svg #investP5", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 1.5)
                .fromTo("#cardsRow33 .whiteCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, 1.75)
                //Go 2 Lines
                .fromTo("#cardsRow32 .arrowHookLeft .arrowHookLine, #cardsRow32 .arrowHookRight .arrowHookLine", {height: 0}, {height: 290, duration: .25}, 1.75)
                .fromTo("#cardsRow32 .arrowHookLeft .arrowHookShadow, #cardsRow32 .arrowHookRight .arrowHookShadow", {height: 0}, {height: 42, duration: .25}, 2)
                .fromTo("#cardsRow32 .arrowHookLeft .arrowHookCorner, #cardsRow32 .arrowHookRight .arrowHookCorner", {width: 0}, {width: 99, duration: .25}, 2.25)
                .fromTo("#cardsRow32 .arrowHookLeft .arrowHookArrow, #cardsRow32 .arrowHookRight .arrowHookArrow", {width: 0}, {width: 40, duration: .25}, 2.5)
                .fromTo("#cardsRow31 .pinkLineBottom, #cardsRow33 .pinkLine", {opacity: 0}, {opacity: 1, duration: .1}, 2.75)
                .fromTo("#cardsRow31 .pinkLineBottom", {y: 68}, {y: 0, duration: .25}, 2.75)
                .fromTo("#cardsRow33 .pinkLine", {y: 68}, {y: 0, duration: .25}, 2.75)
                //Tips 2
                .fromTo("#cardsRow32 .arrowHookLeft .arrowTip .arrowTipPoint, #cardsRow32 .arrowHookRight .arrowTip .arrowTipPoint", {scale: 0}, {scale: 1, duration: .25}, 2)
                .fromTo("#cardsRow32 .arrowHookLeft .arrowTip .arrowTipLine, #cardsRow32 .arrowHookRight .arrowTip .arrowTipLine", {width: 0}, {width: 72, duration: .15}, 2.1)
                .fromTo("#cardsRow32 .arrowHookLeft .arrowTip .arrowTipWrapper, #cardsRow32 .arrowHookRight .arrowTip .arrowTipWrapper", {scale: 0}, {scale: 1, duration: .25}, 2.25)
                .fromTo("#cardsRow32 .arrowHookLeft .arrowTip .arrowTipWrapper .cardsRow32, #cardsRow14 .arrowHookRight .arrowTip .arrowTipWrapper .arrowTipText", {opacity: 0}, {opacity: 1, duration: .25}, 2.4)
                .fromTo("#cardsRow31 .pinkLineBottom .arrowTip", {scale: 0}, {scale: 1, duration: .25}, 3)
                .fromTo("#cardsRow31 .pinkLineBottom .arrowTip .arrowTipText", {opacity: 0}, {opacity: 1, duration: .25}, 3)

        } else {

            // Scroll Title
            ScrollTrigger.create({
                trigger: "#titleOverlay",
                start: "0% 0%",
                end: 400,
                pin: '#pageTitle',
            });

            gsap.timeline({
                scrollTrigger: {
                    trigger: "#sspPage",
                    start: "0% 0%",
                    end: 400,
                    scrub: true
                }
            })
                .fromTo("footer", {opacity: 0}, {opacity: 1}, 0)
                .fromTo("#footerOverlay", {opacity: 0}, {opacity: 1}, 0)

            const mobSys = this.getMobileOperatingSystem();

            //if(mobSys === 'iOS') {

                gsap.timeline({
                    scrollTrigger: {
                        trigger: "#infoBlock1 .primaryBlue",
                        start: "0% 399px",
                        end: 1600,
                        scrub: true,
                        //markers: true
                    }
                })
                    //Go 1 White Card
                    .fromTo("#cardsRow11 .whiteCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .25}, 0)
                    .fromTo("#cardsRow11 .cardIcon svg #dspsP5", {strokeDasharray: '0px 300px'}, {strokeDasharray: '300px 300px'}, .1)
                    .fromTo("#cardsRow11 .cardIcon svg #dspsC1", {strokeDasharray: '0px 64px'}, {strokeDasharray: '64px 64px'}, .1)
                    .fromTo("#cardsRow11 .cardIcon svg #dspsP1", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, .25)
                    .fromTo("#cardsRow11 .cardIcon svg #dspsP2", {strokeDasharray: '0px 120px'}, {strokeDasharray: '120px 120px'}, .25)
                    .fromTo("#cardsRow11 .cardIcon svg #dspsP3", {strokeDasharray: '0px 120px'}, {strokeDasharray: '120px 120px'}, .25)
                    .fromTo("#cardsRow11 .cardIcon svg #dspsP4", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, .25)
                    .fromTo("#cardsRow11 .whiteCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, .25)
                    //Go 1 Black Card
                    .fromTo("#cardsRow13 .blackCard", {scale: 0}, {scale: 1, duration: .25}, .5)
                    //Go 1 Lines
                    .fromTo("#cardsRow11 .whiteCard .pinkArrow, #cardsRow12 .lineGreen, #cardsRow13 .pinkLineTop", {opacity: 0}, {opacity: 1, duration: .01}, .75)
                    .fromTo("#cardsRow11 .whiteCard .pinkArrow", {y: 60}, {y: 0, duration: .5}, .75)
                    .fromTo("#cardsRow12 .lineGreen", {y: -120}, {y: 0, duration: .5}, .75)
                    .fromTo("#cardsRow13 .pinkLineTop", {y: 60}, {y: 0, duration: .5}, .75)
                    //Tips 1
                    .fromTo("#cardsRow12 .lineBottom .arrowTip .arrowTipPoint, #cardsRow12 .lineTop .arrowTip .arrowTipPoint", {scale: 0}, {scale: 1, duration: .25}, .95)
                    .fromTo("#cardsRow12 .lineBottom .arrowTip .arrowTipLine, #cardsRow12 .lineTop .arrowTip .arrowTipLine", {width: 0}, {width: 80, duration: .15}, 1)
                    .fromTo("#cardsRow12 .lineBottom .arrowTip .arrowTipWrapper", {scale: 0, rotate: -90}, {scale: 1, rotate: -90, duration: .25}, 1.15)
                    .fromTo("#cardsRow12 .lineTop .arrowTip .arrowTipWrapper", {scale: 0, rotate: 90}, {scale: 1, rotate: 90, duration: .25}, 1.15)
                    .fromTo("#cardsRow12 .lineBottom .arrowTip .arrowTipWrapper .arrowTipText, #cardsRow12 .lineTop .arrowTip .arrowTipWrapper .arrowTipText", {opacity: 0}, {opacity: 1, duration: .25}, 1.3)
                    //Go 2 White Card
                    .fromTo("#cardsRow15 .whiteCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .25}, 1.25)
                    .fromTo("#cardsRow15 .cardIcon svg #sspsP5", {strokeDasharray: '0px 300px'}, {strokeDasharray: '300px 300px'}, 1.26)
                    .fromTo("#cardsRow15 .cardIcon svg #sspsC1", {strokeDasharray: '0px 64px'}, {strokeDasharray: '64px 64px'}, 1.26)
                    .fromTo("#cardsRow15 .cardIcon svg #sspsP1", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, 1.5)
                    .fromTo("#cardsRow15 .cardIcon svg #sspsP2", {strokeDasharray: '0px 120px'}, {strokeDasharray: '120px 120px'}, 1.5)
                    .fromTo("#cardsRow15 .cardIcon svg #sspsP3", {strokeDasharray: '0px 120px'}, {strokeDasharray: '120px 120px'}, 1.5)
                    .fromTo("#cardsRow15 .cardIcon svg #sspsP4", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, 1.5)
                    .fromTo("#cardsRow15 .whiteCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, 1.5)
                    //Go 2 Lines
                    .fromTo("#cardsRow14 .lineGreen", {y: -120}, {y: 0, duration: .5}, 1.5)
                    .fromTo("#cardsRow13 .blackCard .pinkLineBottom, #cardsRow14 .lineGreen, #cardsRow15 .pinkLine", {opacity: 0}, {opacity: 1, duration: .01}, 1.5)
                    .fromTo("#cardsRow13 .blackCard .pinkLineBottom", {y: 60}, {y: 0, duration: .5}, 1.5)
                    .fromTo("#cardsRow15 .pinkLine", {y: 60}, {y: 0, duration: .5}, 1.5)
                    //Tips 2
                    .fromTo("#cardsRow14 .lineBottom .arrowTip .arrowTipPoint, #cardsRow14 .lineTop .arrowTip .arrowTipPoint", {scale: 0}, {scale: 1, duration: .25}, 1.75)
                    .fromTo("#cardsRow14 .lineBottom .arrowTip .arrowTipLine, #cardsRow14 .lineTop .arrowTip .arrowTipLine", {width: 0}, {width: 80, duration: .15}, 1.8)
                    .fromTo("#cardsRow14 .lineBottom .arrowTip .arrowTipWrapper", {scale: 0, rotate: -90}, {scale: 1, rotate: -90, duration: .25}, 1.95)
                    .fromTo("#cardsRow14 .lineTop .arrowTip .arrowTipWrapper", {scale: 0, rotate: 90}, {scale: 1, rotate: 90, duration: .25}, 1.95)
                    .fromTo("#cardsRow14 .lineBottom .arrowTip .arrowTipWrapper .arrowTipText, #cardsRow14 .lineTop .arrowTip .arrowTipWrapper .arrowTipText", {opacity: 0}, {opacity: 1, duration: .25}, 2.1)


                gsap.timeline({
                    scrollTrigger: {
                        trigger: "#infoBlock2 .primaryBlue",
                        start: '0 399',
                        end: 3400,
                        scrub: true,
                        //markers: true
                    }
                })
                    //Magnet
                    .fromTo("#cardsRow21 #card211", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, 0)
                    .fromTo("#cardsRow21 #card211 svg #MagnetIcon #MagnetP1", {strokeDasharray: '0px 500px'}, {strokeDasharray: '500px 500px', duration: .25}, 0.25)
                    .fromTo("#cardsRow21 #card211 svg #MagnetIcon #MagnetP2", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px', duration: .5}, 0.25)

                    .fromTo("#cardsRow21 #card211 svg #MagnetFlash1", {scale: 0, x: 10, y: 20, rotate: -50}, {scale: 1, x: 10, y: 20, rotate: -50, transformOrigin: '50% 50%', duration: 1}, 0.5)
                    .fromTo("#cardsRow21 #card211 svg #MagnetFlash2", {scale: 0, x: 15, y: 30}, {scale: 1, x: 15, y: 30, transformOrigin: '50% 50%', duration: 1}, 0.5)
                    .fromTo("#cardsRow21 #card211 svg #MagnetFlash3", {scale: 0, x: 20, y: 20}, {scale: 1, x: 20, y: 20, transformOrigin: '50% 50%', duration: 1}, 0.5)

                    .fromTo("#cardsRow21 #card211 svg #MagnetHeart1", {scale: 0, x: 65, y: 90}, {scale: .7, x: 65, y: 90, transformOrigin: '50% 50%', duration: 1}, 1)
                    .fromTo("#cardsRow21 #card211 svg #MagnetHeart2", {scale: 0, x: 115, y: 40}, {scale: .7, x: 115, y: 40, transformOrigin: '50% 50%', duration: 1}, 1)
                    .fromTo("#cardsRow21 #card211 svg #MagnetHeart3", {scale: 0, x: 165, y: 90}, {scale: .7, x: 165, y: 90, transformOrigin: '50% 50%', duration: 1}, 1)

                    .fromTo("#cardsRow22 .lineGreen", {opacity: 0}, {opacity: 1, duration: .01}, 1)
                    .fromTo("#cardsRow22 .lineGreen", {y: -150}, {y: 0, duration: .5}, 1)

                    .fromTo("#cardsRow23 .triangleCard", {scale: 0}, {scale: 1, duration: .5}, 1.1)
                    .fromTo("#cardsRow23 .triangleCard ul li#triangleCardOne", {width: 0}, {width: 354, duration: .25}, 1.25)
                    .fromTo("#cardsRow23 .triangleCard ul li#triangleCardOne .triangleCardText", {y: -70}, {y: 0, duration: .25}, 1.5)

                    .fromTo("#cardsRow23 .triangleCard ul li#triangleCardTwo", {width: 0}, {width: 286, duration: .25}, 1.5)
                    .fromTo("#cardsRow23 .triangleCard ul li#triangleCardTwo .triangleCardText", {y: -50}, {y: 0, duration: .25}, 1.75)
                    .fromTo("#cardsRow23 .triangleCard ul li#triangleCardTwo .triangleArrow", {y: -25}, {y: 0, duration: .25}, 1.9)

                    .fromTo("#cardsRow23 .triangleCard ul li#triangleCardThree", {width: 0}, {width: 216, duration: .25}, 1.9)
                    .fromTo("#cardsRow23 .triangleCard ul li#triangleCardThree .triangleCardText", {y: -50}, {y: 0, duration: .25}, 2.15)
                    .fromTo("#cardsRow23 .triangleCard ul li#triangleCardThree .triangleArrow", {y: -25}, {y: 0, duration: .25}, 2.25)

                    .fromTo("#cardsRow23 .triangleCard ul li#triangleCardFour", {width: 0}, {width: 150, duration: .25}, 2.25)
                    .fromTo("#cardsRow23 .triangleCard ul li#triangleCardFour .triangleCardText", {y: -50}, {y: 0, duration: .25}, 2.5)
                    .fromTo("#cardsRow23 .triangleCard ul li#triangleCardFour .triangleArrow", {y: -25}, {y: 0, duration: .25}, 2.6)

                    .fromTo("#cardsRow24 .lineGreen", {opacity: 0}, {opacity: 1, duration: .01}, 2.6)
                    .fromTo("#cardsRow24 .lineGreen", {y: -150}, {y: 0, duration: .5}, 2.6)

                    //Go 2 White Card
                    .fromTo("#cardsRow25 .whiteCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .25}, 2.8)
                    .fromTo("#cardsRow25 .cardIcon svg", {opacity: 0}, {opacity: 1, duration: 0.1}, 3)
                    .fromTo("#cardsRow25 .cardIcon svg #aimP1", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 3)
                    .fromTo("#cardsRow25 .cardIcon svg #aimP2", {strokeDasharray: '0px 64px'}, {strokeDasharray: '64px 64px'}, 3)
                    .fromTo("#cardsRow25 .cardIcon svg #aimP3", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, 3)
                    .fromTo("#cardsRow25 .cardIcon svg #aimP4", {strokeDasharray: '0px 120px'}, {strokeDasharray: '120px 120px'}, 3)
                    .fromTo("#cardsRow25 .cardIcon svg #aimP5", {strokeDasharray: '0px 120px'}, {strokeDasharray: '120px 120px'}, 3)
                    .fromTo("#cardsRow25 .cardIcon svg #aimP6", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, 3)
                    .fromTo("#cardsRow25 .cardIcon svg #aimP7", {strokeDasharray: '0px 100px'}, {strokeDasharray: '100px 100px'}, 3)
                    .fromTo("#cardsRow25 .cardIcon svg #aimC1", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 3)
                    .fromTo("#cardsRow25 .cardIcon svg #aimC2", {strokeDasharray: '0px 320px'}, {strokeDasharray: '320px 320px'}, 3)
                    .fromTo("#cardsRow25 .whiteCard #heartCircleOne", {left: -100, bottom: -100, scale: 0}, {left: -45, bottom: -45, scale: 1, duration: .25}, 3.5)
                    .fromTo("#cardsRow25 .whiteCard #heartCircleTwo", {right: -100, top: -100, scale: 0}, {right: -45, top: -45, scale: 1, duration: .25}, 3.5)


                gsap.timeline({
                    scrollTrigger: {
                        trigger: "#infoBlock3 .primaryBlue",
                        start: '0 399',
                        end: 4700,
                        scrub: true,
                        //markers: true
                    }
                })
                    .fromTo("#cardsRow31 .blackCard", {scale: 0}, {scale: 1, duration: .5}, 0)
                    .fromTo("#cardsRow33 .whiteCard", {y: -60, scale: 0}, {y: 0, scale: 1, duration: .5}, .5)
                    .fromTo("#cardsRow33 .whiteCard svg #investR1", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 1.5)
                    .fromTo("#cardsRow33 .whiteCard svg #investP1", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 1.5)
                    .fromTo("#cardsRow33 .whiteCard svg #investP2", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 1.5)
                    .fromTo("#cardsRow33 .whiteCard svg #investP3", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 1.5)
                    .fromTo("#cardsRow33 .whiteCard svg #investP4", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 1.5)
                    .fromTo("#cardsRow33 .whiteCard svg #investP5", {strokeDasharray: '0px 200px'}, {strokeDasharray: '200px 200px'}, 1.5)
                    .fromTo("#cardsRow33 .whiteCard .cardTitle span", {y: 60}, {y: 0, duration: .5}, 1.75)
                    //Go 1 Lines
                    .fromTo("#cardsRow31 .pinkLineBottom, #cardsRow32 .lineGreen, #cardsRow33 .pinkLine", {opacity: 0}, {opacity: 1, duration: .01}, 1)
                    .fromTo("#cardsRow31 .pinkLineBottom", {y: 60}, {y: 0, duration: .5}, 1)
                    .fromTo("#cardsRow32 .lineGreen", {y: -120}, {y: 0, duration: .5}, 1)
                    .fromTo("#cardsRow33 .pinkLine", {y: 60}, {y: 0, duration: .5}, 1)
                    //Tips 1
                    .fromTo("#cardsRow32 .lineBottom .arrowTip .arrowTipPoint, #cardsRow32 .lineTop .arrowTip .arrowTipPoint", {scale: 0}, {scale: 1, duration: .25}, 1.25)
                    .fromTo("#cardsRow32 .lineBottom .arrowTip .arrowTipLine", {width: 0}, {width: 80, duration: .15}, 1.5)
                    .fromTo("#cardsRow32 .lineTop .arrowTip .arrowTipLine", {width: 0}, {width: 60, duration: .15}, 1.5)
                    .fromTo("#cardsRow32 .lineBottom .arrowTip .arrowTipWrapper", {scale: 0, rotate: -90}, {scale: 1, rotate: -90, duration: .25}, 1.65)
                    .fromTo("#cardsRow32 .lineTop .arrowTip .arrowTipWrapper", {scale: 0, rotate: 90}, {scale: 1, rotate: 90, duration: .25}, 1.65)
                    .fromTo("#cardsRow32 .lineBottom .arrowTip .arrowTipWrapper .arrowTipText, #cardsRow32 .lineTop .arrowTip .arrowTipWrapper .arrowTipText", {opacity: 0}, {opacity: 1, duration: .25}, 1.9)


            //}

        }

    }

    render() {

        const meta = {
            title: 'DSP',//data.content.title,
            description: '',
            canonical: '',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ''
                }
            }
        };

        return(
            <DocumentMeta {...meta}>
                <div id={'titleOverlay'}></div>
                <PageTitle id="dspTitle" title="DSP" />
                {this.props.isMobile ? <MobileDSP/> : <DesktopDSP/>}
                <div id={"footerOverlay"}></div>
                <Footer/>
            </DocumentMeta>
        )

    }

}
