const Asterisk = () => {
  return (
      <svg className={'asterisk'} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <path d="M0 0L16 0 16 16 0 16z" transform="translate(-315 -4) translate(315 4)"/>
              <path fill="#C6C8CC" fillRule="nonzero" d="M9.044 13.747L8.861 9.688 12.285 11.891 13.333 10.063 9.698 8.207 13.333 6.35 12.285 4.523 8.861 6.726 9.044 2.667 6.956 2.667 7.139 6.726 3.715 4.523 2.667 6.35 6.302 8.207 2.667 10.063 3.715 11.891 7.139 9.688 6.956 13.747z" transform="translate(-315 -4) translate(315 4)"/>
            </g>
          </g>
        </g>
      </svg>
  )
}

export default Asterisk;